import {useEffect} from 'react'
import * as Sentry from '@sentry/browser'
import {useLDClient} from 'launchdarkly-react-client-sdk'
import {isNil} from 'lodash-es'
import LogRocket from 'logrocket'
import {IntlShape} from 'react-intl'

import {
    appointmentsPath,
    inventoryPath,
    invoices,
    physicalPositionPath,
    reportsPath,
    shipmentsPath,
    stockFlowPath,
    tradeInsightsPath,
    tradesPath,
} from '@/pages/routes/paths'
import {PermissionRoute, PermissionState, usePermissions} from '@/providers/PermissionAndNavProvider'
import {configValue} from '@/utils/config'
import {getFullName} from '@/utils/formatting'

import {Nav_data$data} from '@/__generated__/Nav_data.graphql'

import msgs from './messages'
import {NavItemType} from './types'

export function getLinks(intl: IntlShape, perms: PermissionState, flags: Record<string, boolean>): NavItemType[] {
    const {invoicesList, showTradeInsightsReportPage} = flags

    const theLinks = [
        {
            label: intl.formatMessage(msgs.trades),
            hasAccess: perms.hasAccess(PermissionRoute.Trades, true),
            subItems: perms.isPrincipal
                ? [
                      {to: `/${tradesPath}/manager`, label: intl.formatMessage(msgs.tradeManager)},
                      {to: `/${tradesPath}/all`, label: intl.formatMessage(msgs.allTrades)},
                  ]
                : [
                      {to: `/${tradesPath}/manager`, label: intl.formatMessage(msgs.tradeManager)},
                      {to: `/${tradesPath}/list`, label: intl.formatMessage(msgs.openTrades)},
                      {to: `/${tradesPath}/closed`, label: intl.formatMessage(msgs.closedTrades)},
                  ],
        },
        {
            label: intl.formatMessage(msgs.shipments),
            hasAccess: perms.hasAccess(PermissionRoute.Shipments, true),
            subItems: perms.hasAccess(PermissionRoute.Appointments, true)
                ? [
                      {to: `/${shipmentsPath}`, label: intl.formatMessage(msgs.allShipments)},
                      {to: `/${appointmentsPath}`, label: intl.formatMessage(msgs.appointments)},
                  ]
                : [{to: `/${shipmentsPath}`, label: intl.formatMessage(msgs.allShipments)}],
        },
        {
            label: intl.formatMessage(msgs.inventory),
            hasAccess: perms.hasAccess(PermissionRoute.Consignment, true),
            subItems: [
                {to: `/${inventoryPath}/manager?filter=Open`, label: intl.formatMessage(msgs.openAccounts)},
                /* {to: `/${inventoryPath}/search`, label: intl.formatMessage(msgs.searchInventory)} */
            ],
        },
        {
            to: `/${invoices}`,
            label: intl.formatMessage(msgs.invoices),
            hasAccess: invoicesList && perms.hasAccess(PermissionRoute.Trades, true),
        },
        {
            label: intl.formatMessage(msgs.reports),
            hasAccess: perms.isPrincipal && perms.hasAccess(PermissionRoute.PositionReports, true),
            subItems: [
                ...(perms.isPrincipal && perms.hasAccess(PermissionRoute.PositionReports, true)
                    ? [
                          {
                              to: `/${reportsPath}/${stockFlowPath}`,
                              label: intl.formatMessage(msgs.stockFlow),
                          },
                          {
                              to: `/${reportsPath}/${physicalPositionPath}`,
                              label: intl.formatMessage(msgs.physicalPosition),
                          },
                          ...(showTradeInsightsReportPage && perms.hasAccess(PermissionRoute.TradeInsights)
                              ? [
                                    {
                                        to: `/${reportsPath}/${tradeInsightsPath}`,
                                        label: intl.formatMessage(msgs.tradeInsights),
                                    },
                                ]
                              : []),
                      ]
                    : []),
            ],
        },
    ]

    // filter out the items & subItems the user doesn't have access to
    return theLinks
        .filter((l) => l.hasAccess)
        .map((item: NavItemType) => ({
            ...item,
            subItems: item?.subItems?.filter((l) => (isNil(l.hasAccess) ? true : l.hasAccess)),
        }))
}

export function useSentry(data: Nav_data$data) {
    useEffect(() => {
        if (data?.user) {
            const id = data.user?.pk.toString()
            const {email} = data.user
            const orgId = data.user?.organization?.pk

            if (id && email && orgId) {
                Sentry.setUser({id, email, orgId})
            }
        }
    }, [data])
}

export function useLaunchDarkly(data: Nav_data$data) {
    const {setLaunchDarklyFlagsLoaded} = usePermissions()
    const ldClient = useLDClient()

    useEffect(() => {
        if (data?.user) {
            const {user} = data
            const id = data.user?.pk.toString()
            const orgPk = user?.organization?.pk

            if (ldClient) {
                const env: string = process.env.CI_COMMIT_REF_SLUG ?? process.env.NODE_ENV ?? 'local'

                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME
                ldClient
                    .identify({
                        key: id,
                        email: user.email,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        custom: {
                            organization: orgPk,
                            env,
                        },
                    })
                    .then(() => {
                        setLaunchDarklyFlagsLoaded()
                    })
            }
        }
    }, [data, setLaunchDarklyFlagsLoaded, ldClient])
}

const logrocketAppId = configValue('LR_APP_ID')
export function useLogrocket(data: Nav_data$data) {
    useEffect(() => {
        if (data?.user) {
            const name = getFullName(data.user?.firstName, data.user?.lastName) ?? 'unknown'
            const userPk = data.user?.pk.toString()
            const email = data.user?.email
            const orgPk = data.user?.organization?.pk

            if (logrocketAppId) {
                LogRocket.identify(userPk, {
                    name,
                    email,
                    organization: orgPk,
                })
            }
        }
    }, [data])
}
