import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import * as React from 'react'
import {isNil} from 'lodash-es'
import {Headers} from 'react-relay-network-modern'
import wretch, {ConfiguredMiddleware, FetchLike, Wretcher, WretcherOptions} from 'wretch'

import {AuthAnalytics} from '@/analytics/auth'
import {configValue, configValueNumber} from '@/utils/config'

import Loading from './Loading'

export interface AuthState {
    loggedIn: boolean
    rootURL: string
    api: Wretcher
    apiNoAuth: Wretcher
    effectiveOrganization?: number
    clearEffectiveOrganization: () => void
    setEffectiveOrganization: (n: number) => void
    login: (email: string, password: string) => Promise<void>
    //This logs the current user out. By default it redirects to '/', use the flag to suppress this behaviour
    logout: (redirect?: boolean) => void
    reset: () => void
    getHeaders: () => Headers
    getTokens: () => {access: string; refresh: string}
    updateTokens: (access: string, refresh: string) => void
    refreshToken: () => Promise<string>
    setNewPassword: (token: string, uid: string, passwordOne: string, passwordTwo: string) => Promise<void>
    requestResetPassword: (email: string) => Promise<void>
    changePassword: (oldPassword: string, newPasswordOne: string, newPasswordTwo: string) => Promise<void>
    setPwResetToken: (token: string) => void
}

const apiUrl = configValue('API_URL')
const apiTimeout = configValueNumber('API_TIMEOUT', 300000)
const timeoutMiddleware = (next: FetchLike) => async (url: string, opts: WretcherOptions) => {
    const controller = new AbortController()

    // start a timer that will abort the request if it takes too long
    const id = setTimeout(() => {
        controller.abort()
    }, apiTimeout)

    // wait for the request
    const res = await next(url, {...opts, signal: controller.signal})

    // the request resolved, stop the timeout
    clearTimeout(id)

    // return the results
    return res
}

export const LOCAL_STORAGE = {
    ACCESS: 'access',
    REFRESH: 'refresh',
    EFFECTIVE_ORG: 'effectiveOrganization',
    PASSWORD_RESET_TOKEN: 'pwResetToken',
}

const AuthContext = React.createContext<AuthState | null>(null)

export type AuthProps = {
    rootURL?: string
    // eslint-disable-next-line react/no-unused-prop-types -- FIXME
    timeout?: number
    children: React.ReactNode
    assumeLoggedIn?: boolean
    /** used only for testing: */
    effectiveOrgPk?: number
}

export function getCSRFCookie() {
    const csrftokenKey = configValue('CSRF_COOKIE_NAME') ?? 'csrftoken'
    return document.cookie
        .split('; ')
        .find((row) => row.startsWith(csrftokenKey + '='))
        ?.split('=')[1]
}

export const AuthProvider = ({rootURL: _rootURL = apiUrl, children, assumeLoggedIn, effectiveOrgPk}: AuthProps) => {
    const rootURL = _rootURL ?? ''
    const access = useRef<string | null>(localStorage.getItem(LOCAL_STORAGE.ACCESS))
    const refresh = useRef<string | null>(localStorage.getItem(LOCAL_STORAGE.REFRESH))
    const effectiveOrg = localStorage.getItem(LOCAL_STORAGE.EFFECTIVE_ORG) ?? effectiveOrgPk
    // eslint-disable-next-line no-implicit-coercion -- FIXME
    const [effectiveOrganization, _setEffectiveOrganization] = useState<number>(effectiveOrg ? +effectiveOrg : 0)
    const [loggedIn, setLoggedIn] = useState<boolean>(assumeLoggedIn ? true : false)
    const [loading, setLoading] = useState<boolean>(assumeLoggedIn ? false : true)

    useEffect(() => {
        const handleLocalStorageUpdate = (event: StorageEvent) => {
            const newValue = event.newValue !== null ? event.newValue : undefined
            if (event.key === LOCAL_STORAGE.ACCESS) {
                if (newValue && event.newValue !== access.current) {
                    access.current = newValue
                }
            } else if (event.key === LOCAL_STORAGE.REFRESH) {
                if (newValue && newValue !== refresh.current) {
                    refresh.current = newValue
                }
            }
        }

        window.addEventListener('storage', handleLocalStorageUpdate)

        return () => {
            window.removeEventListener('storage', handleLocalStorageUpdate)
        }
    }, [])

    const setAccess = (token: string): void => {
        access.current = token
        localStorage.setItem(LOCAL_STORAGE.ACCESS, token)
    }

    const clearAccess = (): void => {
        access.current = null
        localStorage.removeItem(LOCAL_STORAGE.ACCESS)
    }

    const setRefresh = (token: string): void => {
        refresh.current = token
        localStorage.setItem(LOCAL_STORAGE.REFRESH, token)
    }

    const clearRefresh = (): void => {
        refresh.current = null
        localStorage.removeItem(LOCAL_STORAGE.REFRESH)
    }

    const setEffectiveOrganization = (id: number | null) => {
        _setEffectiveOrganization(id ? id : 0)
        localStorage.setItem(LOCAL_STORAGE.EFFECTIVE_ORG, isNil(id) ? '' : `${id}`)
    }

    const clearEffectiveOrganization = useCallback((): void => {
        setEffectiveOrganization(null)
        localStorage.removeItem(LOCAL_STORAGE.EFFECTIVE_ORG)
    }, [])

    const setPwResetToken = (pwResetToken: string) => {
        localStorage.setItem(LOCAL_STORAGE.PASSWORD_RESET_TOKEN, pwResetToken)
    }

    const clearPwResetToken = () => {
        localStorage.removeItem(LOCAL_STORAGE.PASSWORD_RESET_TOKEN)
    }

    const reset = useCallback(() => {
        clearAccess()
        clearRefresh()
        clearEffectiveOrganization()
        setLoggedIn(false)
        clearPwResetToken()
    }, [clearEffectiveOrganization])

    const csrftoken = getCSRFCookie()

    // This is the basic factory to get an API instance without auth
    const apiNoAuth: Wretcher = useMemo(() => {
        let apiNoAuth = wretch()
            .url(rootURL)
            .options({credentials: 'include', mode: 'cors'})
            .catcher(403, () => {
                reset()
                window.location.href = '/'
            })
            .middlewares([timeoutMiddleware])

        if (csrftoken) {
            apiNoAuth = apiNoAuth.headers({'X-CSRFToken': csrftoken})
        }
        return apiNoAuth
    }, [csrftoken, reset, rootURL])

    const refreshToken = useCallback(
        (): Promise<string> =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME
            apiNoAuth
                .url('/api/v1/token/refresh/')
                .post({refresh: refresh.current})
                .json((resp) => {
                    setAccess(resp.access)
                    if (resp.access) {
                        setRefresh(resp.refresh)
                    }
                    setLoggedIn(true)
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME
                    return resp.access
                })
                .catch(() => null),
        [apiNoAuth],
    )

    // Update the refresh token
    // This method will make sure that there is only a single inflight request to
    // update the refresh token.  All other requests will be queued ("blocked") until the inflight
    // request is completed.  On completion the access token will updated in local storage and
    // all queued requests will be resolved.
    const refreshBarrierLocked = useRef(false)
    type PromiseCB = {
        resolve: () => void
        reject: () => void
    }
    const refreshBarrierCallbacks = useRef<PromiseCB[]>([])
    const refreshTokenSingleton = useCallback(
        (): Promise<void> =>
            new Promise((resolve, reject) => {
                if (refreshBarrierLocked.current) {
                    refreshBarrierCallbacks.current.push({resolve, reject})
                } else {
                    refreshBarrierLocked.current = true
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME
                    refreshToken().then((success) => {
                        refreshBarrierLocked.current = false
                        // eslint-disable-next-line prefer-promise-reject-errors -- FIXME
                        success ? resolve() : reject()
                        while (refreshBarrierCallbacks.current.length) {
                            const tcb = refreshBarrierCallbacks.current.pop() as PromiseCB
                            success ? tcb.resolve() : tcb.reject()
                        }
                    })
                }
            }),
        [refreshToken],
    )

    // Automatically update auth token using refresh token on authentication failures.
    // A 401 response could mean the access token is too old, is corrupt, or is rejected by the server.
    // We will try to refresh it using the refresh token if we can.
    const reAuthMiddleware = useCallback(
        (): ConfiguredMiddleware => (next) => (url, opts) =>
            next(url, opts).then((resp) => {
                if (resp.status !== 401) {
                    return resp
                    // eslint-disable-next-line no-else-return -- FIXME
                } else {
                    if (!refresh.current || !access.current) {
                        clearAccess()
                        clearRefresh()
                        return resp
                    }

                    return refreshTokenSingleton()
                        .then(() => {
                            if (!access.current) {
                                return resp
                            }

                            const headers = opts.headers as Record<string, string>

                            // eslint-disable-next-line dot-notation -- FIXME
                            headers['Authorization'] = `Bearer ${access.current}`
                            opts.headers = headers
                            return next(url, opts)
                        })
                        .catch(() => {
                            reset()
                            return resp
                        })
                }
            }),
        [refreshTokenSingleton, reset],
    )

    const api = useMemo(() => {
        let api = apiNoAuth
        if (access.current) {
            api = api.auth(`Bearer ${access.current}`)
        }

        api = api.middlewares([reAuthMiddleware()])
        if (effectiveOrganization) {
            api = api.headers({'X-CMDTY-ORG-ID': `${effectiveOrganization}`})
        }
        return api
    }, [apiNoAuth, effectiveOrganization, reAuthMiddleware])

    const logout = useCallback(
        async (redirect: boolean = true) => {
            reset()
            try {
                await api.url('/api/v1/auth/logout/').post().res().catch()
                // eslint-disable-next-line no-empty -- FIXME
            } catch (e) {}

            AuthAnalytics.logout()

            // eslint-disable-next-line curly -- FIXME
            if (!redirect) return

            window.location.href = '/'
        },
        [api, reset],
    )

    useEffect(() => {
        if (loggedIn) {
            return
        }

        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME
        ;(async () => {
            await api
                .url('/api/v1/users/current/')
                .get()
                .json(() => {
                    setLoggedIn(true)
                })
                .catch(() => {
                    setLoggedIn(false)
                })
            setLoading(false)
        })()
    }, [api, loggedIn])

    const login = (email: string, password: string): Promise<void> => {
        reset()
        return apiNoAuth
            .url('/api/v1/token/')
            .post({email, password})
            .unauthorized((error, _req) => {
                throw error
            })
            .json((resp) => {
                if ('access' in resp) {
                    setAccess(resp.access)
                    setRefresh(resp.refresh)
                    setLoggedIn(true)
                } else {
                    throw new Error('Login failed')
                }
            })
    }

    const updateTokens = (access: string, refresh: string): void => {
        setAccess(access)
        setRefresh(refresh)
    }

    const getTokens = () => ({access: access.current ?? '', refresh: refresh.current ?? ''})

    const getHeaders = () => {
        const headers: Headers = {}
        const pwResetToken = localStorage.getItem(LOCAL_STORAGE.PASSWORD_RESET_TOKEN)
        if (effectiveOrganization) {
            // eslint-disable-next-line no-implicit-coercion -- FIXME
            headers['X-CMDTY-ORG-ID'] = '' + effectiveOrganization
        }
        if (pwResetToken) {
            headers['X-CMDTY-RESET-PW-TOKEN'] = pwResetToken
        }
        if (csrftoken) {
            headers['X-CSRFToken'] = csrftoken
        }
        return headers
    }

    function requestResetPassword(email: string): Promise<void> {
        return apiNoAuth.url('/api/v1/auth/password/reset/').post({email}).res()
    }

    function setNewPassword(token: string, uid: string, passwordOne: string, passwordTwo: string): Promise<void> {
        return apiNoAuth
            .url('/api/v1/auth/password/reset/new-password/')
            .post({
                uid,
                token,
                new_password1: passwordOne,
                new_password2: passwordTwo,
            })
            .json((resp) => {
                if ('access' in resp) {
                    return resp
                }
                throw new Error('Failed to reset password. Please contact support.')
            })
            .then((resp) => {
                reset()
                setAccess(resp.access)
                setRefresh(resp.refresh)
            })
            .catch((err) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME
                throw JSON.parse(err.text)
            })
    }

    function changePassword(oldPassword: string, newPasswordOne: string, newPasswordTwo: string): Promise<void> {
        return api
            .url('/api/v1/auth/password/change/')
            .post({
                old_password: oldPassword,
                new_password1: newPasswordOne,
                new_password2: newPasswordTwo,
            })
            .json(() => {})
            .catch((e) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME
                throw JSON.parse(e.text)
            })
    }

    return (
        <AuthContext.Provider
            value={{
                loggedIn,
                rootURL,
                effectiveOrganization,
                setEffectiveOrganization,
                clearEffectiveOrganization,
                login,
                logout,
                reset,
                refreshToken,
                updateTokens,
                getTokens,
                getHeaders,
                api,
                apiNoAuth,
                requestResetPassword,
                setNewPassword,
                changePassword,
                setPwResetToken,
            }}>
            {loading ? <Loading /> : children}
        </AuthContext.Provider>
    )
}

export function useAuth(): AuthState {
    const auth = React.useContext(AuthContext) as AuthState
    if (auth === undefined) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return auth
}

export function useAPI(customRootURL?: string): Wretcher {
    const auth = React.useContext(AuthContext) as AuthState
    if (auth === null) {
        throw new Error('useAPI must be used within a AuthProvider')
    }

    if (!customRootURL) {
        return auth.api
    }

    auth.api._url = customRootURL

    return auth.api
}

export const ProtectedRoute = ({login, children}: {login: React.ReactElement; children: React.ReactElement}) => {
    const auth = useAuth()

    if (!auth.loggedIn) {
        return login
    }

    return children
}

export default AuthProvider
