import {applyFlags as applyAnalyticsFlags, assignActiveUser, resetAnalytics} from './main'

export const AuthAnalytics = {
    login(params: {
        pk: number
        fullName: string
        email: string
        organizationName: string
        orgId: string
        counterparties: string[]
        roles: string[]
    }) {
        assignActiveUser(params)
    },
    logout() {
        resetAnalytics()
    },
    globalFlagDefinition(flags: {[index: string]: boolean}) {
        applyAnalyticsFlags(flags)
    },
}
