import SnackbarProvider from '@waybridge/wui/SnackbarProvider'

import {initializeEventAnalytics} from '@/analytics/main'
import {SnackbarContainer} from '@/components/Snackbar'
import {useUserFlagsSync} from '@/components/UserFlagsPanel'
import {AuthProvider} from '@/providers/AuthProvider'

import ErrorBoundary from './ErrorBoundary'
import {startLogRocket} from './logrocket'
import {startPosthog} from './postHog'

export const BaseWrapper = ({children}: {children?: React.ReactNode}) => {
    useUserFlagsSync()
    initializeEventAnalytics()

    startLogRocket()
    startPosthog()

    return (
        <AuthProvider>
            <ErrorBoundary>
                <SnackbarContainer />
                <SnackbarProvider>{children}</SnackbarProvider>
            </ErrorBoundary>
        </AuthProvider>
    )
}
