import {useEffect, useMemo} from 'react'
import {useAtomValue, useSetAtom} from 'jotai'
// eslint-disable-next-line waybridge/use-flags
import {useFlags as useLaunchDarklyFlags, useLDClient} from 'launchdarkly-react-client-sdk'
import {suspend} from 'suspend-react'

import {AuthAnalytics} from '@/analytics/auth'
import {flagsAtom, updateUsedFlagsAtom} from '@/components/UserFlagsPanel/atoms'

export const FLAGS_SCOPE = Symbol()

/**
 * Hook for reading flags from LaunchDarkly and local storage
 * and allow for override.
 *
 * By default, this will not suspend until flags are loaded.
 *
 * @param suspense - whether to suspend rendering until flags are loaded
 */
const useFlags = (suspense = false): Record<string, boolean> => {
    const launchDarklyFlags = useLaunchDarklyFlags()
    const ldClient = useLDClient()
    const overwrittenFlags = useAtomValue(flagsAtom, FLAGS_SCOPE)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const computedFlags = useMemo(
        () => ({...launchDarklyFlags, ...overwrittenFlags}),
        [launchDarklyFlags, overwrittenFlags],
    )

    const setUsedFlags = useSetAtom(updateUsedFlagsAtom, FLAGS_SCOPE)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
    const flagsProxy = useMemo(() => {
        const handler: ProxyHandler<any> = {
            get(...args) {
                const [, name] = args
                setUsedFlags(name.toString())
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME
                return Reflect.get(...args)
            },
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME
        return new Proxy(computedFlags, handler)
    }, [computedFlags, setUsedFlags])

    useEffect(() => {
        AuthAnalytics.globalFlagDefinition(flagsProxy)
    }, [flagsProxy])

    // if there's no ldClient, don't try and suspend
    if (suspense && ldClient) {
        suspend(() => ldClient.waitUntilReady(), [ldClient])
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME
    return flagsProxy
}

export default useFlags
