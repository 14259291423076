export enum ActionType {
  Amendment = 'AMENDMENT',
  Booking = 'BOOKING',
  Declaration = 'DECLARATION'
}

export enum ActivitiesInventoryStatus {
  Arrived = 'ARRIVED',
  Booked = 'BOOKED',
  Incomplete = 'INCOMPLETE',
  InProcess = 'IN_PROCESS',
  InTransit = 'IN_TRANSIT',
  ReadyForRelease = 'READY_FOR_RELEASE',
  Received = 'RECEIVED',
  Rejected = 'REJECTED',
  Released = 'RELEASED'
}

export enum AllProductsOrderingField {
  AttributeValues = 'ATTRIBUTE_VALUES',
  CommodityId = 'COMMODITY_ID',
  Hidden = 'HIDDEN',
  InternalProductOrgId = 'INTERNAL_PRODUCT_ORG_ID',
  InternalReference = 'INTERNAL_REFERENCE',
  Pk = 'PK',
  ProductName = 'PRODUCT_NAME'
}

export enum AllocationOptionsOrderingField {
  BuyerName = 'BUYER_NAME',
  BuyerPhoto = 'BUYER_PHOTO',
  BuyerPk = 'BUYER_PK',
  BuyerReferenceNumber = 'BUYER_REFERENCE_NUMBER',
  FutureMonthSortKey = 'FUTURE_MONTH_SORT_KEY',
  MaterialName = 'MATERIAL_NAME',
  OrderPk = 'ORDER_PK',
  OrderReferenceNumber = 'ORDER_REFERENCE_NUMBER',
  OrderReferenceNumbers = 'ORDER_REFERENCE_NUMBERS',
  PastMonthSortKey = 'PAST_MONTH_SORT_KEY',
  Pk = 'PK',
  SellerPk = 'SELLER_PK',
  SellerReferenceNumber = 'SELLER_REFERENCE_NUMBER',
  ShipperName = 'SHIPPER_NAME',
  ShipperPhoto = 'SHIPPER_PHOTO',
  ShipperPk = 'SHIPPER_PK',
  StartDate = 'START_DATE',
  StartMonthInPast = 'START_MONTH_IN_PAST',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradePk = 'TRADE_PK'
}

export enum AllowedWeightDiscrepancyType {
  Absolute = 'ABSOLUTE',
  Percent = 'PERCENT'
}

export enum ArrivalTimeType {
  Actual = 'ACTUAL',
  Appointment = 'APPOINTMENT',
  EstimatedBasedOnTransit = 'ESTIMATED_BASED_ON_TRANSIT',
  Expected = 'EXPECTED',
  Tracking = 'TRACKING'
}

export enum BalanceExportOrderInput {
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountPk = 'ACCOUNT_PK',
  ArrivalDate = 'ARRIVAL_DATE',
  BalanceQuantity = 'BALANCE_QUANTITY',
  BalanceQuantityLb = 'BALANCE_QUANTITY_LB',
  BalanceQuantityMt = 'BALANCE_QUANTITY_MT',
  BolLineItemPk = 'BOL_LINE_ITEM_PK',
  BolNumber = 'BOL_NUMBER',
  Brands = 'BRANDS',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  CanReverseSto = 'CAN_REVERSE_STO',
  Conveyance = 'CONVEYANCE',
  GoodsIssueErpErrorMessage = 'GOODS_ISSUE_ERP_ERROR_MESSAGE',
  GoodsIssueReversalErpErrorMessage = 'GOODS_ISSUE_REVERSAL_ERP_ERROR_MESSAGE',
  GoodsIssueReversalStatus = 'GOODS_ISSUE_REVERSAL_STATUS',
  GoodsIssueStatus = 'GOODS_ISSUE_STATUS',
  HasBalanceToTransfer = 'HAS_BALANCE_TO_TRANSFER',
  InboundBatchId = 'INBOUND_BATCH_ID',
  InventoryIsFromShipment = 'INVENTORY_IS_FROM_SHIPMENT',
  InventoryStatus = 'INVENTORY_STATUS',
  LocationOwnerPk = 'LOCATION_OWNER_PK',
  MaterialName = 'MATERIAL_NAME',
  OriginalQuantity = 'ORIGINAL_QUANTITY',
  OriginalQuantityLb = 'ORIGINAL_QUANTITY_LB',
  OriginalQuantityMt = 'ORIGINAL_QUANTITY_MT',
  OutboundBatchId = 'OUTBOUND_BATCH_ID',
  ProductName = 'PRODUCT_NAME',
  ProductPk = 'PRODUCT_PK',
  QuantityUnit = 'QUANTITY_UNIT',
  ReadyForGoodsIssue = 'READY_FOR_GOODS_ISSUE',
  ReadyForReleaseOn = 'READY_FOR_RELEASE_ON',
  ReadyForSto = 'READY_FOR_STO',
  ReadyQuantityLb = 'READY_QUANTITY_LB',
  ReadyQuantityMt = 'READY_QUANTITY_MT',
  ReceiptDate = 'RECEIPT_DATE',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ShipmentPk = 'SHIPMENT_PK',
  Status = 'STATUS',
  StoDestinationLocationName = 'STO_DESTINATION_LOCATION_NAME',
  StoDestinationLocationPk = 'STO_DESTINATION_LOCATION_PK',
  StoErpErrorMessage = 'STO_ERP_ERROR_MESSAGE',
  StoErpStatus = 'STO_ERP_STATUS',
  StoIsReversed = 'STO_IS_REVERSED',
  StoNumber = 'STO_NUMBER',
  StoPk = 'STO_PK',
  ToPoNumbers = 'TO_PO_NUMBERS',
  TransferCreated = 'TRANSFER_CREATED',
  VehicleInfo = 'VEHICLE_INFO'
}

export enum BalanceInventoryStatus {
  Arrived = 'ARRIVED',
  Booked = 'BOOKED',
  Incomplete = 'INCOMPLETE',
  InProcess = 'IN_PROCESS',
  InTransit = 'IN_TRANSIT',
  ReadyForRelease = 'READY_FOR_RELEASE',
  Received = 'RECEIVED',
  Rejected = 'REJECTED',
  Released = 'RELEASED'
}

export enum BalanceOrderingField {
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountPk = 'ACCOUNT_PK',
  ArrivalDate = 'ARRIVAL_DATE',
  BalanceQuantityLb = 'BALANCE_QUANTITY_LB',
  BalanceQuantityMt = 'BALANCE_QUANTITY_MT',
  BolLineItemPk = 'BOL_LINE_ITEM_PK',
  BolNumber = 'BOL_NUMBER',
  Brands = 'BRANDS',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  CanReverseSto = 'CAN_REVERSE_STO',
  Conveyance = 'CONVEYANCE',
  GoodsIssueErpErrorMessage = 'GOODS_ISSUE_ERP_ERROR_MESSAGE',
  GoodsIssueReversalErpErrorMessage = 'GOODS_ISSUE_REVERSAL_ERP_ERROR_MESSAGE',
  GoodsIssueReversalStatus = 'GOODS_ISSUE_REVERSAL_STATUS',
  GoodsIssueStatus = 'GOODS_ISSUE_STATUS',
  HasBalanceToTransfer = 'HAS_BALANCE_TO_TRANSFER',
  InboundBatchId = 'INBOUND_BATCH_ID',
  InventoryIsFromShipment = 'INVENTORY_IS_FROM_SHIPMENT',
  InventoryStatus = 'INVENTORY_STATUS',
  LocationOwnerPk = 'LOCATION_OWNER_PK',
  MaterialName = 'MATERIAL_NAME',
  OriginalQuantityLb = 'ORIGINAL_QUANTITY_LB',
  OriginalQuantityMt = 'ORIGINAL_QUANTITY_MT',
  OutboundBatchId = 'OUTBOUND_BATCH_ID',
  ProductName = 'PRODUCT_NAME',
  ProductPk = 'PRODUCT_PK',
  ReadyForGoodsIssue = 'READY_FOR_GOODS_ISSUE',
  ReadyForReleaseOn = 'READY_FOR_RELEASE_ON',
  ReadyForSto = 'READY_FOR_STO',
  ReadyQuantityLb = 'READY_QUANTITY_LB',
  ReadyQuantityMt = 'READY_QUANTITY_MT',
  ReceiptDate = 'RECEIPT_DATE',
  ReleaseNumbers = 'RELEASE_NUMBERS',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ShipmentPk = 'SHIPMENT_PK',
  Status = 'STATUS',
  StoDestinationLocationName = 'STO_DESTINATION_LOCATION_NAME',
  StoDestinationLocationPk = 'STO_DESTINATION_LOCATION_PK',
  StoErpErrorMessage = 'STO_ERP_ERROR_MESSAGE',
  StoErpStatus = 'STO_ERP_STATUS',
  StoIsReversed = 'STO_IS_REVERSED',
  StoNumber = 'STO_NUMBER',
  StoPk = 'STO_PK',
  ToPoNumbers = 'TO_PO_NUMBERS',
  TransferCreated = 'TRANSFER_CREATED',
  VehicleInfo = 'VEHICLE_INFO'
}

export enum BrandRegisteredBy {
  Both = 'BOTH',
  Comex = 'COMEX',
  Lme = 'LME',
  Unregistered = 'UNREGISTERED'
}

export enum BusinessDayConvention {
  Actual = 'ACTUAL',
  Following = 'FOLLOWING',
  Lme_3Mo = 'LME_3MO',
  ModifiedFollowing = 'MODIFIED_FOLLOWING',
  ModifiedPreceding = 'MODIFIED_PRECEDING',
  ModifiedPrevious = 'MODIFIED_PREVIOUS',
  Nearest = 'NEAREST',
  Preceding = 'PRECEDING',
  Previous = 'PREVIOUS'
}

export enum ChangeType {
  Amended = 'AMENDED',
  Archived = 'ARCHIVED',
  Created = 'CREATED',
  Declaration = 'DECLARATION',
  Deleted = 'DELETED'
}

export enum ChangelogObjectType {
  Allocation = 'Allocation',
  BolLineItem = 'BOLLineItem',
  BillOfLading = 'BillOfLading',
  BorrowingCost = 'BorrowingCost',
  Category = 'Category',
  CmdtyContract = 'CmdtyContract',
  Commodity = 'Commodity',
  CommodityIndex = 'CommodityIndex',
  Consumption = 'Consumption',
  Country = 'Country',
  CountryGroup = 'CountryGroup',
  Currency = 'Currency',
  CurrencyPair = 'CurrencyPair',
  Declaration = 'Declaration',
  Delivery = 'Delivery',
  DeliveryAppointment = 'DeliveryAppointment',
  DeliveryAppointmentTime = 'DeliveryAppointmentTime',
  DeliverySchedule = 'DeliverySchedule',
  DeliveryScheduleDay = 'DeliveryScheduleDay',
  ExternalTranslation = 'ExternalTranslation',
  FinalizedTradeDetails = 'FinalizedTradeDetails',
  FixingSource = 'FixingSource',
  Holiday = 'Holiday',
  InterestRateFix = 'InterestRateFix',
  InterestRatePoint = 'InterestRatePoint',
  InventoryAccount = 'InventoryAccount',
  InventoryAccountIntegrationMap = 'InventoryAccountIntegrationMap',
  InventoryAction = 'InventoryAction',
  InventoryDocument = 'InventoryDocument',
  Invoice = 'Invoice',
  InvoiceDetail = 'InvoiceDetail',
  InvoiceDocument = 'InvoiceDocument',
  LineItem = 'LineItem',
  Location = 'Location',
  LocationCommodity = 'LocationCommodity',
  LocationConveyance = 'LocationConveyance',
  MaterialSpecification = 'MaterialSpecification',
  MetalsGradePhysicalMarks = 'MetalsGradePhysicalMarks',
  MetalsTrade = 'MetalsTrade',
  MetalsTradePeriod = 'MetalsTradePeriod',
  NegotiationMessage = 'NegotiationMessage',
  Order = 'Order',
  OrgCmdtyContract = 'OrgCmdtyContract',
  OrgRegion = 'OrgRegion',
  OrgRegionMaterial = 'OrgRegionMaterial',
  Organization = 'Organization',
  OrganizationCounterparty = 'OrganizationCounterparty',
  PaymentEntity = 'PaymentEntity',
  PriceProvider = 'PriceProvider',
  PricingFormula = 'PricingFormula',
  ReceivingDocument = 'ReceivingDocument',
  Role = 'Role',
  RolePermissionGrant = 'RolePermissionGrant',
  Schedule = 'Schedule',
  ScheduleLineItem = 'ScheduleLineItem',
  StorageLocation = 'StorageLocation',
  SymbolicPrice = 'SymbolicPrice',
  Ticket = 'Ticket',
  User = 'User',
  UserRoleGrant = 'UserRoleGrant'
}

export enum ConsignmentReleaseExportOrderInput {
  ActionType = 'ACTION_TYPE',
  FromAccountHolderPk = 'FROM_ACCOUNT_HOLDER_PK',
  FromAccountLocationOwnerPk = 'FROM_ACCOUNT_LOCATION_OWNER_PK',
  FromAccountName = 'FROM_ACCOUNT_NAME',
  FromAccountOrderNumbers = 'FROM_ACCOUNT_ORDER_NUMBERS',
  FromAccountPk = 'FROM_ACCOUNT_PK',
  Id = 'ID',
  MaterialName = 'MATERIAL_NAME',
  MaterialPk = 'MATERIAL_PK',
  Quantity = 'QUANTITY',
  QuantityLb = 'QUANTITY_LB',
  QuantityMt = 'QUANTITY_MT',
  QuantityUnit = 'QUANTITY_UNIT',
  ReleaseDate = 'RELEASE_DATE',
  ReleaseNumber = 'RELEASE_NUMBER',
  SubquotaPk = 'SUBQUOTA_PK',
  TradeBuyerName = 'TRADE_BUYER_NAME',
  TradeMaterialSpecName = 'TRADE_MATERIAL_SPEC_NAME',
  TradeOrderLineItemNumber = 'TRADE_ORDER_LINE_ITEM_NUMBER',
  TradeOrderNumber = 'TRADE_ORDER_NUMBER',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradePeriodStartDate = 'TRADE_PERIOD_START_DATE',
  TradePk = 'TRADE_PK',
  TradeQuantityUnit = 'TRADE_QUANTITY_UNIT',
  TradeSellerName = 'TRADE_SELLER_NAME'
}

export enum ConsignmentReleaseOrderingField {
  ActionType = 'ACTION_TYPE',
  FromAccountHolderPk = 'FROM_ACCOUNT_HOLDER_PK',
  FromAccountLocationOwnerPk = 'FROM_ACCOUNT_LOCATION_OWNER_PK',
  FromAccountName = 'FROM_ACCOUNT_NAME',
  FromAccountOrderNumbers = 'FROM_ACCOUNT_ORDER_NUMBERS',
  FromAccountPk = 'FROM_ACCOUNT_PK',
  MaterialName = 'MATERIAL_NAME',
  MaterialPk = 'MATERIAL_PK',
  Pk = 'PK',
  Quantity = 'QUANTITY',
  QuantityLb = 'QUANTITY_LB',
  QuantityMt = 'QUANTITY_MT',
  ReleaseDate = 'RELEASE_DATE',
  ReleaseNumber = 'RELEASE_NUMBER',
  SubquotaPk = 'SUBQUOTA_PK',
  TradeBuyerName = 'TRADE_BUYER_NAME',
  TradeMaterialSpecName = 'TRADE_MATERIAL_SPEC_NAME',
  TradeOrderLineItemNumber = 'TRADE_ORDER_LINE_ITEM_NUMBER',
  TradeOrderNumber = 'TRADE_ORDER_NUMBER',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradePeriodStartDate = 'TRADE_PERIOD_START_DATE',
  TradePk = 'TRADE_PK',
  TradeQuantityUnit = 'TRADE_QUANTITY_UNIT',
  TradeSellerName = 'TRADE_SELLER_NAME'
}

export enum ConsignmentReleasesByTransactionExportOrderInput {
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountLocationOwnerPk = 'ACCOUNT_LOCATION_OWNER_PK',
  AccountPk = 'ACCOUNT_PK',
  ActionPk = 'ACTION_PK',
  BolNumber = 'BOL_NUMBER',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  Conveyance = 'CONVEYANCE',
  Id = 'ID',
  InboundBatchId = 'INBOUND_BATCH_ID',
  MaterialName = 'MATERIAL_NAME',
  MaterialPk = 'MATERIAL_PK',
  OutboundBatchId = 'OUTBOUND_BATCH_ID',
  PoNumber = 'PO_NUMBER',
  ProductName = 'PRODUCT_NAME',
  ProductPk = 'PRODUCT_PK',
  QuantityLb = 'QUANTITY_LB',
  QuantityMt = 'QUANTITY_MT',
  ReceivedAtDate = 'RECEIVED_AT_DATE',
  ReleaseDate = 'RELEASE_DATE',
  ReleaseNumber = 'RELEASE_NUMBER',
  ReleaseToAccountName = 'RELEASE_TO_ACCOUNT_NAME',
  ReleaseToAccountPk = 'RELEASE_TO_ACCOUNT_PK',
  ReleaseToTradePk = 'RELEASE_TO_TRADE_PK',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ShipmentPk = 'SHIPMENT_PK',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradeQuotaMonth = 'TRADE_QUOTA_MONTH',
  VehicleInfo = 'VEHICLE_INFO'
}

export enum ConsignmentReleasesByTransactionOrderingField {
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountLocationOwnerPk = 'ACCOUNT_LOCATION_OWNER_PK',
  AccountPk = 'ACCOUNT_PK',
  ActionPk = 'ACTION_PK',
  BolNumber = 'BOL_NUMBER',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  Conveyance = 'CONVEYANCE',
  InboundBatchId = 'INBOUND_BATCH_ID',
  MaterialName = 'MATERIAL_NAME',
  MaterialPk = 'MATERIAL_PK',
  OutboundBatchId = 'OUTBOUND_BATCH_ID',
  Pk = 'PK',
  PoNumber = 'PO_NUMBER',
  ProductName = 'PRODUCT_NAME',
  ProductPk = 'PRODUCT_PK',
  QuantityLb = 'QUANTITY_LB',
  QuantityMt = 'QUANTITY_MT',
  ReceivedAtDate = 'RECEIVED_AT_DATE',
  ReleaseDate = 'RELEASE_DATE',
  ReleaseNumber = 'RELEASE_NUMBER',
  ReleaseToAccountName = 'RELEASE_TO_ACCOUNT_NAME',
  ReleaseToAccountPk = 'RELEASE_TO_ACCOUNT_PK',
  ReleaseToTradePk = 'RELEASE_TO_TRADE_PK',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ShipmentPk = 'SHIPMENT_PK',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradeQuotaMonth = 'TRADE_QUOTA_MONTH',
  VehicleInfo = 'VEHICLE_INFO'
}

export enum ConveyanceVehicleType {
  OnSite = 'ON_SITE',
  Rail = 'RAIL',
  Truck = 'TRUCK',
  Vessel = 'VESSEL',
  WarehouseTransfer = 'WAREHOUSE_TRANSFER'
}

export enum CounterpartyRelationshipKind {
  From = 'FROM',
  Self = 'SELF',
  ShippingOrg = 'SHIPPING_ORG',
  To = 'TO'
}

export enum CounterpartyType {
  CounterpartyOwned = 'COUNTERPARTY_OWNED',
  Mutual = 'MUTUAL',
  Owned = 'OWNED'
}

export enum DailyPositionReportDayResourceExportOrder {
  ConsignmentStartingQuantity = 'CONSIGNMENT_STARTING_QUANTITY',
  Date = 'DATE',
  InboundTotal = 'INBOUND_TOTAL',
  NetChange = 'NET_CHANGE',
  NetInventory = 'NET_INVENTORY',
  NumDaysInventory = 'NUM_DAYS_INVENTORY',
  OutboundTotal = 'OUTBOUND_TOTAL',
  OwnedStartingQuantity = 'OWNED_STARTING_QUANTITY',
  StartingQuantity = 'STARTING_QUANTITY',
  TotalProductionQuantity = 'TOTAL_PRODUCTION_QUANTITY'
}

export enum DailyPositionReportView {
  Booked = 'BOOKED',
  Forecast = 'FORECAST'
}

export enum DeclarationStatus {
  Declared = 'DECLARED',
  NotNeeded = 'NOT_NEEDED',
  Overridden = 'OVERRIDDEN',
  Pending = 'PENDING'
}

export enum DeliveryAppointmentTimeUpdateType {
  Dec = 'DEC',
  Inc = 'INC'
}

export enum DeliveryDeliveryShipmentCreationMethodChoices {
  Appointment = 'APPOINTMENT',
  Asn = 'ASN',
  AutomatedConsignmentManagement = 'AUTOMATED_CONSIGNMENT_MANAGEMENT',
  BulkShipmentUpload = 'BULK_SHIPMENT_UPLOAD',
  CreatePage = 'CREATE_PAGE',
  Receiving = 'RECEIVING',
  Schedule = 'SCHEDULE'
}

export enum DeliveryExceptionType {
  BrandRegistration = 'BRAND_REGISTRATION',
  EarlyDelivery = 'EARLY_DELIVERY',
  MaterialSpecification = 'MATERIAL_SPECIFICATION',
  MissingBundleCount = 'MISSING_BUNDLE_COUNT',
  MissingIdentifier = 'MISSING_IDENTIFIER',
  MissingProduct = 'MISSING_PRODUCT',
  MissingStorageLocation = 'MISSING_STORAGE_LOCATION',
  MissingTradeConsignmentOrSto = 'MISSING_TRADE_CONSIGNMENT_OR_STO',
  OrderTargetAccountMismatch = 'ORDER_TARGET_ACCOUNT_MISMATCH',
  PoFull = 'PO_FULL',
  PoLocationMismatch = 'PO_LOCATION_MISMATCH',
  PoMaterialMismatch = 'PO_MATERIAL_MISMATCH',
  StoAlreadyComplete = 'STO_ALREADY_COMPLETE',
  StoLastTruck = 'STO_LAST_TRUCK',
  StoMaterialMismatch = 'STO_MATERIAL_MISMATCH',
  StoNotLastTruckButHeavy = 'STO_NOT_LAST_TRUCK_BUT_HEAVY',
  SubquotaMultipleMatch = 'SUBQUOTA_MULTIPLE_MATCH',
  SubquotaNoMatch = 'SUBQUOTA_NO_MATCH',
  Unload = 'UNLOAD',
  WeightDiscrepancy = 'WEIGHT_DISCREPANCY'
}

export enum DeliverySortField {
  ArrivalDate = 'ARRIVAL_DATE'
}

export enum DeliveryStatus {
  Arrived = 'ARRIVED',
  Booked = 'BOOKED',
  Delivered = 'DELIVERED',
  EnRoute = 'EN_ROUTE',
  Unknown = 'UNKNOWN'
}

export enum DeliveryTrackingStatus {
  CouldNotInitiate_2Xx = 'COULD_NOT_INITIATE_2XX',
  CouldNotInitiate_4Xx = 'COULD_NOT_INITIATE_4XX',
  CouldNotInitiate_5Xx = 'COULD_NOT_INITIATE_5XX',
  CouldNotInitiateP44Err_4Xx = 'COULD_NOT_INITIATE_P44_ERR_4XX',
  CouldNotInitiateP44Err_5Xx = 'COULD_NOT_INITIATE_P44_ERR_5XX',
  CouldNotInitiateP44ErrUnknown = 'COULD_NOT_INITIATE_P44_ERR_UNKNOWN',
  CouldNotInitiateP44SubscriptionError = 'COULD_NOT_INITIATE_P44_SUBSCRIPTION_ERROR',
  CouldNotUpdateP44Err = 'COULD_NOT_UPDATE_P44_ERR',
  CouldNotUpdateValidationErr = 'COULD_NOT_UPDATE_VALIDATION_ERR',
  FinishedError = 'FINISHED_ERROR',
  FinishedOk = 'FINISHED_OK',
  FinishedStale = 'FINISHED_STALE',
  LiveOk = 'LIVE_OK',
  LivePendingData = 'LIVE_PENDING_DATA',
  LiveWarning = 'LIVE_WARNING',
  Pending = 'PENDING',
  TrackingError = 'TRACKING_ERROR',
  TrackingServerError = 'TRACKING_SERVER_ERROR',
  TrackingUserError = 'TRACKING_USER_ERROR',
  TrackingValidationError = 'TRACKING_VALIDATION_ERROR'
}

export enum DeliveryWeightType {
  Inbound = 'INBOUND',
  NetScale = 'NET_SCALE',
  Outbound = 'OUTBOUND',
  Packaging = 'PACKAGING'
}

export enum DisplayQuantityUnit {
  Lb = 'LB',
  Mt = 'MT',
  Original = 'ORIGINAL'
}

export enum DocumentType {
  Contract = 'CONTRACT',
  FinalInvoice = 'FINAL_INVOICE',
  MultiDoc = 'MULTI_DOC',
  Other = 'OTHER',
  ProvInvoice = 'PROV_INVOICE',
  TermsCond = 'TERMS_COND'
}

export enum ErpIntegrationStatusCode {
  AwaitingResp = 'AWAITING_RESP',
  Blocked = 'BLOCKED',
  Failed = 'FAILED',
  Ok = 'OK',
  RespTimeout = 'RESP_TIMEOUT'
}

export enum ErpObjectType {
  GoodsIssue = 'GOODS_ISSUE',
  GoodsIssueReversal = 'GOODS_ISSUE_REVERSAL',
  GoodsNegativeAdjustment = 'GOODS_NEGATIVE_ADJUSTMENT',
  GoodsPositiveAdjustment = 'GOODS_POSITIVE_ADJUSTMENT',
  GoodsReceipt = 'GOODS_RECEIPT',
  GoodsReceiptReversal = 'GOODS_RECEIPT_REVERSAL',
  GoodsTransfer = 'GOODS_TRANSFER',
  PurchaseOrder = 'PURCHASE_ORDER',
  PurchaseOrderChange = 'PURCHASE_ORDER_CHANGE',
  Shipment = 'SHIPMENT',
  StatusCheck = 'STATUS_CHECK'
}

export enum ErpPurchaseOrderType {
  InventoryAccount = 'INVENTORY_ACCOUNT',
  ScheduleLineItem = 'SCHEDULE_LINE_ITEM',
  TradePeriod = 'TRADE_PERIOD'
}

export enum EtaErrorCode {
  ActualArrivalTimeInTheFuture = 'ACTUAL_ARRIVAL_TIME_IN_THE_FUTURE',
  NoFeatureVector = 'NO_FEATURE_VECTOR',
  NoRoute = 'NO_ROUTE',
  NoRouteStatistics = 'NO_ROUTE_STATISTICS',
  NoShippingOrigin = 'NO_SHIPPING_ORIGIN',
  NoShippingTimestamp = 'NO_SHIPPING_TIMESTAMP',
  NoThirdPartyEtaButLiveTracking = 'NO_THIRD_PARTY_ETA_BUT_LIVE_TRACKING',
  NoTracking = 'NO_TRACKING',
  NoTrackingStatistics = 'NO_TRACKING_STATISTICS',
  Unknown = 'UNKNOWN'
}

export enum EtaModel {
  Manual = 'MANUAL',
  ThirdParty = 'THIRD_PARTY',
  WbRouteBased = 'WB_ROUTE_BASED',
  WbTrackingBased = 'WB_TRACKING_BASED'
}

export enum EtaStatisticsTag {
  Max = 'max',
  Mean = 'mean',
  P50 = 'p50',
  P75 = 'p75',
  P90 = 'p90'
}

export enum EtaValueStatus {
  InThePast = 'IN_THE_PAST',
  Stale = 'STALE',
  Unknown = 'UNKNOWN',
  Valid = 'VALID'
}

export enum ErpErpActionResponseTypeChoices {
  Async = 'ASYNC',
  None = 'NONE',
  Sync = 'SYNC'
}

export enum ErrorCode {
  AccountDatesInvalidForProductionPlan = 'ACCOUNT_DATES_INVALID_FOR_PRODUCTION_PLAN',
  AllocationMissingTradePeriod = 'ALLOCATION_MISSING_TRADE_PERIOD',
  AllocationNotFound = 'ALLOCATION_NOT_FOUND',
  BillOfLadingNegativeBalance = 'BILL_OF_LADING_NEGATIVE_BALANCE',
  BillOfLadingNotFound = 'BILL_OF_LADING_NOT_FOUND',
  BolLineItemBundleCountMustBeOneIfNoDiscreteUnits = 'BOL_LINE_ITEM_BUNDLE_COUNT_MUST_BE_ONE_IF_NO_DISCRETE_UNITS',
  BolLineItemBundleCountMustBePositive = 'BOL_LINE_ITEM_BUNDLE_COUNT_MUST_BE_POSITIVE',
  BolLineItemCannotGenerateBatchId = 'BOL_LINE_ITEM_CANNOT_GENERATE_BATCH_ID',
  BolLineItemHasNoReceipt = 'BOL_LINE_ITEM_HAS_NO_RECEIPT',
  BolLineItemHasNoToAccount = 'BOL_LINE_ITEM_HAS_NO_TO_ACCOUNT',
  BolLineItemMustBeRejectedToBeDeleted = 'BOL_LINE_ITEM_MUST_BE_REJECTED_TO_BE_DELETED',
  BolLineItemNotFound = 'BOL_LINE_ITEM_NOT_FOUND',
  BolLineItemReceiptIdInvalid = 'BOL_LINE_ITEM_RECEIPT_ID_INVALID',
  BolLineItemRejected = 'BOL_LINE_ITEM_REJECTED',
  ConsignmentAccountNotFound = 'CONSIGNMENT_ACCOUNT_NOT_FOUND',
  ConsignmentReleaseInsufficientQuantity = 'CONSIGNMENT_RELEASE_INSUFFICIENT_QUANTITY',
  ConsignmentReleaseInvalidOrganization = 'CONSIGNMENT_RELEASE_INVALID_ORGANIZATION',
  ConsignmentReleaseLocationMismatch = 'CONSIGNMENT_RELEASE_LOCATION_MISMATCH',
  ConsignmentReleaseMaterialMismatch = 'CONSIGNMENT_RELEASE_MATERIAL_MISMATCH',
  ConsignmentReleaseMultipleMaterials = 'CONSIGNMENT_RELEASE_MULTIPLE_MATERIALS',
  ConsignmentReleaseNoBatches = 'CONSIGNMENT_RELEASE_NO_BATCHES',
  ConsignmentReleaseTargetInvalid = 'CONSIGNMENT_RELEASE_TARGET_INVALID',
  ConsignmentReleaseTargetMismatch = 'CONSIGNMENT_RELEASE_TARGET_MISMATCH',
  ConsignmentReleaseTradeAccountHolderMismatch = 'CONSIGNMENT_RELEASE_TRADE_ACCOUNT_HOLDER_MISMATCH',
  ConsignmentReleaseTradeLocationMismatch = 'CONSIGNMENT_RELEASE_TRADE_LOCATION_MISMATCH',
  ConsumptionActualsCouldNotFindBols = 'CONSUMPTION_ACTUALS_COULD_NOT_FIND_BOLS',
  ConsumptionScheduleInvalidDate = 'CONSUMPTION_SCHEDULE_INVALID_DATE',
  ConsumptionScheduleNotFound = 'CONSUMPTION_SCHEDULE_NOT_FOUND',
  CreateShipmentInputMaxOneVehicleInfoType = 'CREATE_SHIPMENT_INPUT_MAX_ONE_VEHICLE_INFO_TYPE',
  CreateShipmentInputMismatchingConveyance = 'CREATE_SHIPMENT_INPUT_MISMATCHING_CONVEYANCE',
  DeclarationDbValidationError = 'DECLARATION_DB_VALIDATION_ERROR',
  DeclareAlreadySubmitted = 'DECLARE_ALREADY_SUBMITTED',
  DeclareConveyanceInvalid = 'DECLARE_CONVEYANCE_INVALID',
  DeclareConveyanceNotOnLocation = 'DECLARE_CONVEYANCE_NOT_ON_LOCATION',
  DeclareConveyanceNoDeclaredDeliveryTerms = 'DECLARE_CONVEYANCE_NO_DECLARED_DELIVERY_TERMS',
  DeclareGradeExceedsMaxPercent = 'DECLARE_GRADE_EXCEEDS_MAX_PERCENT',
  DeclareGradePercentagesNot_100 = 'DECLARE_GRADE_PERCENTAGES_NOT_100',
  DeclareNotATradeOption = 'DECLARE_NOT_A_TRADE_OPTION',
  DeclareNotNominator = 'DECLARE_NOT_NOMINATOR',
  DeclareNoOptionality = 'DECLARE_NO_OPTIONALITY',
  DeclareQpDateMismatch = 'DECLARE_QP_DATE_MISMATCH',
  DeclareQpDeclaredInvalid = 'DECLARE_QP_DECLARED_INVALID',
  DeclareQpOptionMonthsInvalid = 'DECLARE_QP_OPTION_MONTHS_INVALID',
  DeclareQuantityNegative = 'DECLARE_QUANTITY_NEGATIVE',
  DeclareQuantityTooHigh = 'DECLARE_QUANTITY_TOO_HIGH',
  DeclareQuantityTooLow = 'DECLARE_QUANTITY_TOO_LOW',
  DeclareWrongType = 'DECLARE_WRONG_TYPE',
  DeliveryAlreadyArrived = 'DELIVERY_ALREADY_ARRIVED',
  DeliveryAlreadyBooked = 'DELIVERY_ALREADY_BOOKED',
  DeliveryAlreadySubmitted = 'DELIVERY_ALREADY_SUBMITTED',
  DeliveryAppointmentInvalidLocation = 'DELIVERY_APPOINTMENT_INVALID_LOCATION',
  DeliveryAppointmentInvalidLocationConveyance = 'DELIVERY_APPOINTMENT_INVALID_LOCATION_CONVEYANCE',
  DeliveryAppointmentInvalidTradePeriod = 'DELIVERY_APPOINTMENT_INVALID_TRADE_PERIOD',
  DeliveryAppointmentInvalidTransportationProvider = 'DELIVERY_APPOINTMENT_INVALID_TRANSPORTATION_PROVIDER',
  DeliveryAppointmentMultiLoadTradeUpdatedNotSupported = 'DELIVERY_APPOINTMENT_MULTI_LOAD_TRADE_UPDATED_NOT_SUPPORTED',
  DeliveryAppointmentNotFound = 'DELIVERY_APPOINTMENT_NOT_FOUND',
  DeliveryAppointmentTemplateBadDays = 'DELIVERY_APPOINTMENT_TEMPLATE_BAD_DAYS',
  DeliveryAppointmentTemplateBadIntervals = 'DELIVERY_APPOINTMENT_TEMPLATE_BAD_INTERVALS',
  DeliveryAppointmentTemplateMaxAppointmentsInvalid = 'DELIVERY_APPOINTMENT_TEMPLATE_MAX_APPOINTMENTS_INVALID',
  DeliveryAppointmentTemplateMaxAppointmentsRequired = 'DELIVERY_APPOINTMENT_TEMPLATE_MAX_APPOINTMENTS_REQUIRED',
  DeliveryAppointmentTemplateRequired = 'DELIVERY_APPOINTMENT_TEMPLATE_REQUIRED',
  DeliveryAppointmentTemplateStartTimeBadFormat = 'DELIVERY_APPOINTMENT_TEMPLATE_START_TIME_BAD_FORMAT',
  DeliveryAppointmentTemplateStartTimeRequired = 'DELIVERY_APPOINTMENT_TEMPLATE_START_TIME_REQUIRED',
  DeliveryAppointmentTimeCannotDec = 'DELIVERY_APPOINTMENT_TIME_CANNOT_DEC',
  DeliveryAppointmentTimeDuplicate = 'DELIVERY_APPOINTMENT_TIME_DUPLICATE',
  DeliveryAppointmentTimeFull = 'DELIVERY_APPOINTMENT_TIME_FULL',
  DeliveryAppointmentTimeInThePast = 'DELIVERY_APPOINTMENT_TIME_IN_THE_PAST',
  DeliveryAppointmentTimeMissing = 'DELIVERY_APPOINTMENT_TIME_MISSING',
  DeliveryAppointmentUnsupported = 'DELIVERY_APPOINTMENT_UNSUPPORTED',
  DeliveryArchived = 'DELIVERY_ARCHIVED',
  DeliveryAtLeastSingleLoad = 'DELIVERY_AT_LEAST_SINGLE_LOAD',
  DeliveryBeingProcessedOffline = 'DELIVERY_BEING_PROCESSED_OFFLINE',
  DeliveryBolRequired = 'DELIVERY_BOL_REQUIRED',
  DeliveryBulkUploadConfigDoesNotExist = 'DELIVERY_BULK_UPLOAD_CONFIG_DOES_NOT_EXIST',
  DeliveryCannotCreateInErp = 'DELIVERY_CANNOT_CREATE_IN_ERP',
  DeliveryDuplicateIdentifier = 'DELIVERY_DUPLICATE_IDENTIFIER',
  DeliveryDuplicateRailcarAndIdentifier = 'DELIVERY_DUPLICATE_RAILCAR_AND_IDENTIFIER',
  DeliveryExactlySingleLoad = 'DELIVERY_EXACTLY_SINGLE_LOAD',
  DeliveryHasBlockingExceptions = 'DELIVERY_HAS_BLOCKING_EXCEPTIONS',
  DeliveryHasExceptions = 'DELIVERY_HAS_EXCEPTIONS',
  DeliveryInvalidStatusForDelete = 'DELIVERY_INVALID_STATUS_FOR_DELETE',
  DeliveryMissingIdentifiers = 'DELIVERY_MISSING_IDENTIFIERS',
  DeliveryNotAuthorizedToReceive = 'DELIVERY_NOT_AUTHORIZED_TO_RECEIVE',
  DeliveryNotFound = 'DELIVERY_NOT_FOUND',
  DeliveryNotReceived = 'DELIVERY_NOT_RECEIVED',
  DeliveryNotReversibleNotEnoughMaterialInAccount = 'DELIVERY_NOT_REVERSIBLE_NOT_ENOUGH_MATERIAL_IN_ACCOUNT',
  DeliveryOrderRequired = 'DELIVERY_ORDER_REQUIRED',
  DeliveryReceivedStatusInvalid = 'DELIVERY_RECEIVED_STATUS_INVALID',
  DeliveryRejected = 'DELIVERY_REJECTED',
  DeliveryRequiresLineItem = 'DELIVERY_REQUIRES_LINE_ITEM',
  DeliveryRequiresReceivingStatus = 'DELIVERY_REQUIRES_RECEIVING_STATUS',
  DeliveryReverseInputInvalid = 'DELIVERY_REVERSE_INPUT_INVALID',
  DeliveryShipmentArrivedStatusRequiresDates = 'DELIVERY_SHIPMENT_ARRIVED_STATUS_REQUIRES_DATES',
  DeliveryShipmentReceivedStatusRequiresDates = 'DELIVERY_SHIPMENT_RECEIVED_STATUS_REQUIRES_DATES',
  DeliveryShipmentReceivedTimeMustBeAfterArrivalTime = 'DELIVERY_SHIPMENT_RECEIVED_TIME_MUST_BE_AFTER_ARRIVAL_TIME',
  DeliveryShippingDateMismatch = 'DELIVERY_SHIPPING_DATE_MISMATCH',
  DeliveryTermsRequired = 'DELIVERY_TERMS_REQUIRED',
  DeliveryTermArchiveInvalid = 'DELIVERY_TERM_ARCHIVE_INVALID',
  DeliveryTermCreateForbidden = 'DELIVERY_TERM_CREATE_FORBIDDEN',
  DeliveryTermDbValidationError = 'DELIVERY_TERM_DB_VALIDATION_ERROR',
  DeliveryTermDeleteInvalid = 'DELIVERY_TERM_DELETE_INVALID',
  DeliveryTermDestinationNotFound = 'DELIVERY_TERM_DESTINATION_NOT_FOUND',
  DeliveryTermIncotermInactive = 'DELIVERY_TERM_INCOTERM_INACTIVE',
  DeliveryTermIncotermLocationPairInvalid = 'DELIVERY_TERM_INCOTERM_LOCATION_PAIR_INVALID',
  DeliveryTermIncotermNotFound = 'DELIVERY_TERM_INCOTERM_NOT_FOUND',
  DeliveryTermLocationAndLocationSetupRequestGiven = 'DELIVERY_TERM_LOCATION_AND_LOCATION_SETUP_REQUEST_GIVEN',
  DeliveryTermReferencedByDeclaration = 'DELIVERY_TERM_REFERENCED_BY_DECLARATION',
  DeliveryTermReferencedBySubquota = 'DELIVERY_TERM_REFERENCED_BY_SUBQUOTA',
  DeliveryTermReferencedBySubquotaSchedule = 'DELIVERY_TERM_REFERENCED_BY_SUBQUOTA_SCHEDULE',
  DeliveryTermTradeInvalid = 'DELIVERY_TERM_TRADE_INVALID',
  DeliveryTermTradeOptionMismatch = 'DELIVERY_TERM_TRADE_OPTION_MISMATCH',
  DeliveryTermUnarchiveInvalid = 'DELIVERY_TERM_UNARCHIVE_INVALID',
  DeliveryWeightRequired = 'DELIVERY_WEIGHT_REQUIRED',
  DocumentContentNotValid = 'DOCUMENT_CONTENT_NOT_VALID',
  DocumentTooLarge = 'DOCUMENT_TOO_LARGE',
  DocumentTypeNotValid = 'DOCUMENT_TYPE_NOT_VALID',
  ErpAdapterNotConfigured = 'ERP_ADAPTER_NOT_CONFIGURED',
  ExternalTranslationObjectInvalidType = 'EXTERNAL_TRANSLATION_OBJECT_INVALID_TYPE',
  FixedPremiumAndModifierUnitsMustMatch = 'FIXED_PREMIUM_AND_MODIFIER_UNITS_MUST_MATCH',
  FixedPremiumCurrencyIdRequired = 'FIXED_PREMIUM_CURRENCY_ID_REQUIRED',
  FixedPremiumQuantityUnitRequired = 'FIXED_PREMIUM_QUANTITY_UNIT_REQUIRED',
  FixedPremiumRequired = 'FIXED_PREMIUM_REQUIRED',
  FixedPriceCurrencyIdRequired = 'FIXED_PRICE_CURRENCY_ID_REQUIRED',
  FixedPriceMustBePositive = 'FIXED_PRICE_MUST_BE_POSITIVE',
  FixedPriceQuantityUnitRequired = 'FIXED_PRICE_QUANTITY_UNIT_REQUIRED',
  FixedPriceRequired = 'FIXED_PRICE_REQUIRED',
  FixedPriceWithPremiums = 'FIXED_PRICE_WITH_PREMIUMS',
  GoodsIssueMultipleStoOrders = 'GOODS_ISSUE_MULTIPLE_STO_ORDERS',
  GoodsIssueNoStoOrder = 'GOODS_ISSUE_NO_STO_ORDER',
  GoodsIssueStoNumberRequired = 'GOODS_ISSUE_STO_NUMBER_REQUIRED',
  GoverningWeightInvalidAllowedDiscrepancies = 'GOVERNING_WEIGHT_INVALID_ALLOWED_DISCREPANCIES',
  IncotermRequiresLoadedFlag = 'INCOTERM_REQUIRES_LOADED_FLAG',
  IncotermYearInvalid = 'INCOTERM_YEAR_INVALID',
  IntegrationConfigInvalidIdentifier = 'INTEGRATION_CONFIG_INVALID_IDENTIFIER',
  IntegrationConfigMustMatchRawJob = 'INTEGRATION_CONFIG_MUST_MATCH_RAW_JOB',
  InvalidActionType = 'INVALID_ACTION_TYPE',
  InvalidConveyance = 'INVALID_CONVEYANCE',
  InvalidDateTime = 'INVALID_DATE_TIME',
  InvalidPaymentTerms = 'INVALID_PAYMENT_TERMS',
  InvalidPrice = 'INVALID_PRICE',
  InvalidPricingPaymentCombo = 'INVALID_PRICING_PAYMENT_COMBO',
  InventoryAccountInvalidAccount = 'INVENTORY_ACCOUNT_INVALID_ACCOUNT',
  InventoryAccountInvalidCounterparty = 'INVENTORY_ACCOUNT_INVALID_COUNTERPARTY',
  InventoryAccountInvalidDates = 'INVENTORY_ACCOUNT_INVALID_DATES',
  InventoryAccountInvalidEndDate = 'INVENTORY_ACCOUNT_INVALID_END_DATE',
  InventoryAccountInvalidLocation = 'INVENTORY_ACCOUNT_INVALID_LOCATION',
  InventoryAccountInvalidPk = 'INVENTORY_ACCOUNT_INVALID_PK',
  InventoryAccountInvalidRegion = 'INVENTORY_ACCOUNT_INVALID_REGION',
  InventoryAccountPlantAccountHolderInvalid = 'INVENTORY_ACCOUNT_PLANT_ACCOUNT_HOLDER_INVALID',
  InventoryAccountValidInErp = 'INVENTORY_ACCOUNT_VALID_IN_ERP',
  IsArchived = 'IS_ARCHIVED',
  LimitPercentageInvalid = 'LIMIT_PERCENTAGE_INVALID',
  LineItemBasePriceNegative = 'LINE_ITEM_BASE_PRICE_NEGATIVE',
  LineItemBasePriceRequired = 'LINE_ITEM_BASE_PRICE_REQUIRED',
  LineItemBasePriceUnitMissing = 'LINE_ITEM_BASE_PRICE_UNIT_MISSING',
  LineItemCannotAddUnsyncedFailedPo = 'LINE_ITEM_CANNOT_ADD_UNSYNCED_FAILED_PO',
  LineItemCannotArchiveSingleLineItem = 'LINE_ITEM_CANNOT_ARCHIVE_SINGLE_LINE_ITEM',
  LineItemCannotArchiveWithReceipts = 'LINE_ITEM_CANNOT_ARCHIVE_WITH_RECEIPTS',
  LineItemCannotDecrementSequence = 'LINE_ITEM_CANNOT_DECREMENT_SEQUENCE',
  LineItemCanOnlyArchiveUnsyncedOrManual = 'LINE_ITEM_CAN_ONLY_ARCHIVE_UNSYNCED_OR_MANUAL',
  LineItemCanOnlyUpdateUnsyncedOrManual = 'LINE_ITEM_CAN_ONLY_UPDATE_UNSYNCED_OR_MANUAL',
  LineItemFirstNumberMustBeOne = 'LINE_ITEM_FIRST_NUMBER_MUST_BE_ONE',
  LineItemMustBeManualOrSynced = 'LINE_ITEM_MUST_BE_MANUAL_OR_SYNCED',
  LineItemNumberMustBeIncrementedByOne = 'LINE_ITEM_NUMBER_MUST_BE_INCREMENTED_BY_ONE',
  LineItemNumberShouldNotBeLessThanOne = 'LINE_ITEM_NUMBER_SHOULD_NOT_BE_LESS_THAN_ONE',
  LineItemPremiumPriceUnitMissing = 'LINE_ITEM_PREMIUM_PRICE_UNIT_MISSING',
  LineItemPricingCurrencyMismatch = 'LINE_ITEM_PRICING_CURRENCY_MISMATCH',
  LineItemPricingTypeRequired = 'LINE_ITEM_PRICING_TYPE_REQUIRED',
  LineItemQuantityNegative = 'LINE_ITEM_QUANTITY_NEGATIVE',
  LineItemTargetAccountInvalid = 'LINE_ITEM_TARGET_ACCOUNT_INVALID',
  LineItemTargetAccountNeedsTargetAccountPk = 'LINE_ITEM_TARGET_ACCOUNT_NEEDS_TARGET_ACCOUNT_PK',
  LineItemTargetAccountPkNeedsTargetAccount = 'LINE_ITEM_TARGET_ACCOUNT_PK_NEEDS_TARGET_ACCOUNT',
  LocationConveyanceAppointmentsNotSupported = 'LOCATION_CONVEYANCE_APPOINTMENTS_NOT_SUPPORTED',
  LocationConveyanceNotAuthorized = 'LOCATION_CONVEYANCE_NOT_AUTHORIZED',
  LocationConveyanceNotConfiguredToPrintLabels = 'LOCATION_CONVEYANCE_NOT_CONFIGURED_TO_PRINT_LABELS',
  LocationConveyanceNotConfiguredToPrintUnloadForms = 'LOCATION_CONVEYANCE_NOT_CONFIGURED_TO_PRINT_UNLOAD_FORMS',
  LocationConveyanceNotFound = 'LOCATION_CONVEYANCE_NOT_FOUND',
  LocationConveyancePkInvalid = 'LOCATION_CONVEYANCE_PK_INVALID',
  LocationConveyancePortUnlocodeNotSupported = 'LOCATION_CONVEYANCE_PORT_UNLOCODE_NOT_SUPPORTED',
  LocationHasNoAccounts = 'LOCATION_HAS_NO_ACCOUNTS',
  LocationPkInvalid = 'LOCATION_PK_INVALID',
  LocationSetupRequestNoOrg = 'LOCATION_SETUP_REQUEST_NO_ORG',
  LocationSummaryConfigInvalidLocation = 'LOCATION_SUMMARY_CONFIG_INVALID_LOCATION',
  MaterialAttributeMaterialSpecificationRequiredValues = 'MATERIAL_ATTRIBUTE_MATERIAL_SPECIFICATION_REQUIRED_VALUES',
  MaterialAttributeMultiValueTargetingForbidden = 'MATERIAL_ATTRIBUTE_MULTI_VALUE_TARGETING_FORBIDDEN',
  MaterialAttributeNotFound = 'MATERIAL_ATTRIBUTE_NOT_FOUND',
  MaterialAttributeRequiredUnspecified = 'MATERIAL_ATTRIBUTE_REQUIRED_UNSPECIFIED',
  MaterialDeliveryOptionalityFieldsRequired = 'MATERIAL_DELIVERY_OPTIONALITY_FIELDS_REQUIRED',
  MaterialDeliveryOptionalityForbidden = 'MATERIAL_DELIVERY_OPTIONALITY_FORBIDDEN',
  MaterialDeliveryOptionalityRequired = 'MATERIAL_DELIVERY_OPTIONALITY_REQUIRED',
  MaterialSpecificationArchiveInvalid = 'MATERIAL_SPECIFICATION_ARCHIVE_INVALID',
  MaterialSpecificationArchiveTradeRequired = 'MATERIAL_SPECIFICATION_ARCHIVE_TRADE_REQUIRED',
  MaterialSpecificationAttributeValuesRequired = 'MATERIAL_SPECIFICATION_ATTRIBUTE_VALUES_REQUIRED',
  MaterialSpecificationCommodityUpdateInvalid = 'MATERIAL_SPECIFICATION_COMMODITY_UPDATE_INVALID',
  MaterialSpecificationCreateForbidden = 'MATERIAL_SPECIFICATION_CREATE_FORBIDDEN',
  MaterialSpecificationDbValidationError = 'MATERIAL_SPECIFICATION_DB_VALIDATION_ERROR',
  MaterialSpecificationDeleteTradeRequired = 'MATERIAL_SPECIFICATION_DELETE_TRADE_REQUIRED',
  MaterialSpecificationInvalid = 'MATERIAL_SPECIFICATION_INVALID',
  MaterialSpecificationLimitInvalid = 'MATERIAL_SPECIFICATION_LIMIT_INVALID',
  MaterialSpecificationLimitSumInvalid = 'MATERIAL_SPECIFICATION_LIMIT_SUM_INVALID',
  MaterialSpecificationMultiGradeSpecForbidden = 'MATERIAL_SPECIFICATION_MULTI_GRADE_SPEC_FORBIDDEN',
  MaterialSpecificationMultiSpecsRequireSingleGrade = 'MATERIAL_SPECIFICATION_MULTI_SPECS_REQUIRE_SINGLE_GRADE',
  MaterialSpecificationNameInvalid = 'MATERIAL_SPECIFICATION_NAME_INVALID',
  MaterialSpecificationReferencedByDeclaration = 'MATERIAL_SPECIFICATION_REFERENCED_BY_DECLARATION',
  MaterialSpecificationReferencedBySubquota = 'MATERIAL_SPECIFICATION_REFERENCED_BY_SUBQUOTA',
  MaterialSpecificationRequired = 'MATERIAL_SPECIFICATION_REQUIRED',
  MaterialSpecificationRequiresCommodity = 'MATERIAL_SPECIFICATION_REQUIRES_COMMODITY',
  MaterialSpecificationRequireUniqueGradeAttributes = 'MATERIAL_SPECIFICATION_REQUIRE_UNIQUE_GRADE_ATTRIBUTES',
  MaterialSpecificationTradeCommodityDiffers = 'MATERIAL_SPECIFICATION_TRADE_COMMODITY_DIFFERS',
  MaterialSpecificationTradeInvalid = 'MATERIAL_SPECIFICATION_TRADE_INVALID',
  MaterialSpecificationTypeUpdateInvalid = 'MATERIAL_SPECIFICATION_TYPE_UPDATE_INVALID',
  MetalsTradeBuyerRequired = 'METALS_TRADE_BUYER_REQUIRED',
  MetalsTradeCannotResubmit = 'METALS_TRADE_CANNOT_RESUBMIT',
  MetalsTradeConveyanceOptionalityFieldsNotRequired = 'METALS_TRADE_CONVEYANCE_OPTIONALITY_FIELDS_NOT_REQUIRED',
  MetalsTradeConveyanceOptionalityFieldsRequired = 'METALS_TRADE_CONVEYANCE_OPTIONALITY_FIELDS_REQUIRED',
  MetalsTradeConveyanceRequired = 'METALS_TRADE_CONVEYANCE_REQUIRED',
  MetalsTradeCopyNotOriginator = 'METALS_TRADE_COPY_NOT_ORIGINATOR',
  MetalsTradeCreateNotPrincipal = 'METALS_TRADE_CREATE_NOT_PRINCIPAL',
  MetalsTradeCreateOrganizationMismatch = 'METALS_TRADE_CREATE_ORGANIZATION_MISMATCH',
  MetalsTradeDatesRequired = 'METALS_TRADE_DATES_REQUIRED',
  MetalsTradeDeleteUnsafe = 'METALS_TRADE_DELETE_UNSAFE',
  MetalsTradeDeliveryTermsOptionalityFieldsNotRequired = 'METALS_TRADE_DELIVERY_TERMS_OPTIONALITY_FIELDS_NOT_REQUIRED',
  MetalsTradeDeliveryTermsOptionalityFieldsRequired = 'METALS_TRADE_DELIVERY_TERMS_OPTIONALITY_FIELDS_REQUIRED',
  MetalsTradeDeliveryTermsRequired = 'METALS_TRADE_DELIVERY_TERMS_REQUIRED',
  MetalsTradeDeliveryTermsRequiredForLiveTrade = 'METALS_TRADE_DELIVERY_TERMS_REQUIRED_FOR_LIVE_TRADE',
  MetalsTradeEndDateRequired = 'METALS_TRADE_END_DATE_REQUIRED',
  MetalsTradeFinancingOrgNotNull = 'METALS_TRADE_FINANCING_ORG_NOT_NULL',
  MetalsTradeFinancingOrgRequired = 'METALS_TRADE_FINANCING_ORG_REQUIRED',
  MetalsTradeGradeConstraintIsZero = 'METALS_TRADE_GRADE_CONSTRAINT_IS_ZERO',
  MetalsTradeGradeConstraintSumTooLow = 'METALS_TRADE_GRADE_CONSTRAINT_SUM_TOO_LOW',
  MetalsTradeGradeConstraintTooHigh = 'METALS_TRADE_GRADE_CONSTRAINT_TOO_HIGH',
  MetalsTradeGradeOptionalityFieldsNotRequired = 'METALS_TRADE_GRADE_OPTIONALITY_FIELDS_NOT_REQUIRED',
  MetalsTradeGradeOptionalityFieldsRequired = 'METALS_TRADE_GRADE_OPTIONALITY_FIELDS_REQUIRED',
  MetalsTradeGradeOptionRequired = 'METALS_TRADE_GRADE_OPTION_REQUIRED',
  MetalsTradeGradeRequired = 'METALS_TRADE_GRADE_REQUIRED',
  MetalsTradeIncotermRequired = 'METALS_TRADE_INCOTERM_REQUIRED',
  MetalsTradeInvalidContract = 'METALS_TRADE_INVALID_CONTRACT',
  MetalsTradeInvalidCounterparty = 'METALS_TRADE_INVALID_COUNTERPARTY',
  MetalsTradeInvalidCurrency = 'METALS_TRADE_INVALID_CURRENCY',
  MetalsTradeInvalidDateRange = 'METALS_TRADE_INVALID_DATE_RANGE',
  MetalsTradeInvalidGrade = 'METALS_TRADE_INVALID_GRADE',
  MetalsTradeInvalidIncoterm = 'METALS_TRADE_INVALID_INCOTERM',
  MetalsTradeInvalidIncotermLocation = 'METALS_TRADE_INVALID_INCOTERM_LOCATION',
  MetalsTradeInvalidIndex = 'METALS_TRADE_INVALID_INDEX',
  MetalsTradeInvalidLocation = 'METALS_TRADE_INVALID_LOCATION',
  MetalsTradeInvalidMaterial = 'METALS_TRADE_INVALID_MATERIAL',
  MetalsTradeInvalidPremiumIndex = 'METALS_TRADE_INVALID_PREMIUM_INDEX',
  MetalsTradeInvalidPrice = 'METALS_TRADE_INVALID_PRICE',
  MetalsTradeInvalidRequiredDocuments = 'METALS_TRADE_INVALID_REQUIRED_DOCUMENTS',
  MetalsTradeInvalidShape = 'METALS_TRADE_INVALID_SHAPE',
  MetalsTradeInvalidShippingScheduleDates = 'METALS_TRADE_INVALID_SHIPPING_SCHEDULE_DATES',
  MetalsTradeLocationNotCreated = 'METALS_TRADE_LOCATION_NOT_CREATED',
  MetalsTradeLocationRequired = 'METALS_TRADE_LOCATION_REQUIRED',
  MetalsTradeMaterialRequired = 'METALS_TRADE_MATERIAL_REQUIRED',
  MetalsTradeMaterialSpecificationsRequiredForLiveTrade = 'METALS_TRADE_MATERIAL_SPECIFICATIONS_REQUIRED_FOR_LIVE_TRADE',
  MetalsTradeMismatchCurrency = 'METALS_TRADE_MISMATCH_CURRENCY',
  MetalsTradeMismatchIncotermYear = 'METALS_TRADE_MISMATCH_INCOTERM_YEAR',
  MetalsTradeMissingRegion = 'METALS_TRADE_MISSING_REGION',
  MetalsTradeNameExceededErpMax = 'METALS_TRADE_NAME_EXCEEDED_ERP_MAX',
  MetalsTradeNameRequired = 'METALS_TRADE_NAME_REQUIRED',
  MetalsTradeNotAuthorized = 'METALS_TRADE_NOT_AUTHORIZED',
  MetalsTradeNotBuyer = 'METALS_TRADE_NOT_BUYER',
  MetalsTradeNotDraft = 'METALS_TRADE_NOT_DRAFT',
  MetalsTradeNotEnoughShapes = 'METALS_TRADE_NOT_ENOUGH_SHAPES',
  MetalsTradeNotLive = 'METALS_TRADE_NOT_LIVE',
  MetalsTradeNotOriginator = 'METALS_TRADE_NOT_ORIGINATOR',
  MetalsTradeNotReadyToRumble = 'METALS_TRADE_NOT_READY_TO_RUMBLE',
  MetalsTradeNotSeller = 'METALS_TRADE_NOT_SELLER',
  MetalsTradeNotShippingOrg = 'METALS_TRADE_NOT_SHIPPING_ORG',
  MetalsTradeNoIndex = 'METALS_TRADE_NO_INDEX',
  MetalsTradeNoMaterial = 'METALS_TRADE_NO_MATERIAL',
  MetalsTradeNoOrg = 'METALS_TRADE_NO_ORG',
  MetalsTradeNoTradeType = 'METALS_TRADE_NO_TRADE_TYPE',
  MetalsTradeOriginatorRequired = 'METALS_TRADE_ORIGINATOR_REQUIRED',
  MetalsTradePartialQuanityOptionality = 'METALS_TRADE_PARTIAL_QUANITY_OPTIONALITY',
  MetalsTradePeriodAmendmentInvalid = 'METALS_TRADE_PERIOD_AMENDMENT_INVALID',
  MetalsTradePeriodAmendmentPendingDecl = 'METALS_TRADE_PERIOD_AMENDMENT_PENDING_DECL',
  MetalsTradePeriodAmendmentRangeQuantity = 'METALS_TRADE_PERIOD_AMENDMENT_RANGE_QUANTITY',
  MetalsTradePeriodBookingInvalid = 'METALS_TRADE_PERIOD_BOOKING_INVALID',
  MetalsTradePeriodDatesInvalid = 'METALS_TRADE_PERIOD_DATES_INVALID',
  MetalsTradePeriodDeclarationInvalid = 'METALS_TRADE_PERIOD_DECLARATION_INVALID',
  MetalsTradePeriodDeclarationNotNeeded = 'METALS_TRADE_PERIOD_DECLARATION_NOT_NEEDED',
  MetalsTradePeriodDeclarationQuantityInvalid = 'METALS_TRADE_PERIOD_DECLARATION_QUANTITY_INVALID',
  MetalsTradePeriodDeclarationRangeQuantity = 'METALS_TRADE_PERIOD_DECLARATION_RANGE_QUANTITY',
  MetalsTradePeriodInvalidPk = 'METALS_TRADE_PERIOD_INVALID_PK',
  MetalsTradePeriodLiveTradeDeclarationUpdate = 'METALS_TRADE_PERIOD_LIVE_TRADE_DECLARATION_UPDATE',
  MetalsTradePeriodMissingSubquotas = 'METALS_TRADE_PERIOD_MISSING_SUBQUOTAS',
  MetalsTradePeriodNegativeQuantity = 'METALS_TRADE_PERIOD_NEGATIVE_QUANTITY',
  MetalsTradePeriodOverrideInvalid = 'METALS_TRADE_PERIOD_OVERRIDE_INVALID',
  MetalsTradePeriodQuantityBelowAlreadyReceived = 'METALS_TRADE_PERIOD_QUANTITY_BELOW_ALREADY_RECEIVED',
  MetalsTradePeriodQuantityInvalid = 'METALS_TRADE_PERIOD_QUANTITY_INVALID',
  MetalsTradePeriodRequired = 'METALS_TRADE_PERIOD_REQUIRED',
  MetalsTradePremiumModifierUnitsCurrencyIdRequired = 'METALS_TRADE_PREMIUM_MODIFIER_UNITS_CURRENCY_ID_REQUIRED',
  MetalsTradePremiumModifierUnitsQuantityUnitRequired = 'METALS_TRADE_PREMIUM_MODIFIER_UNITS_QUANTITY_UNIT_REQUIRED',
  MetalsTradePremiumModifierUnitsRequired = 'METALS_TRADE_PREMIUM_MODIFIER_UNITS_REQUIRED',
  MetalsTradePriceRequired = 'METALS_TRADE_PRICE_REQUIRED',
  MetalsTradePricingFormulaRequired = 'METALS_TRADE_PRICING_FORMULA_REQUIRED',
  MetalsTradeProvisionalPricingNotSupported = 'METALS_TRADE_PROVISIONAL_PRICING_NOT_SUPPORTED',
  MetalsTradeQppDatesOverlap = 'METALS_TRADE_QPP_DATES_OVERLAP',
  MetalsTradeQppEndDateAfterTradeEnd = 'METALS_TRADE_QPP_END_DATE_AFTER_TRADE_END',
  MetalsTradeQppMinQuantityGreaterThanMaxQuantity = 'METALS_TRADE_QPP_MIN_QUANTITY_GREATER_THAN_MAX_QUANTITY',
  MetalsTradeQppMustBePositive = 'METALS_TRADE_QPP_MUST_BE_POSITIVE',
  MetalsTradeQppNotSortable = 'METALS_TRADE_QPP_NOT_SORTABLE',
  MetalsTradeQppPeriodMismatch = 'METALS_TRADE_QPP_PERIOD_MISMATCH',
  MetalsTradeQppRequired = 'METALS_TRADE_QPP_REQUIRED',
  MetalsTradeQppStartDateBeforeTradeStart = 'METALS_TRADE_QPP_START_DATE_BEFORE_TRADE_START',
  MetalsTradeQppSumsMustMatchTrade = 'METALS_TRADE_QPP_SUMS_MUST_MATCH_TRADE',
  MetalsTradeQpEventNotSupported = 'METALS_TRADE_QP_EVENT_NOT_SUPPORTED',
  MetalsTradeQuantityAmendmentRangeQuantityInvalid = 'METALS_TRADE_QUANTITY_AMENDMENT_RANGE_QUANTITY_INVALID',
  MetalsTradeQuantityAmendmentUnitChangeInvalid = 'METALS_TRADE_QUANTITY_AMENDMENT_UNIT_CHANGE_INVALID',
  MetalsTradeQuantityBelowAlreadyReceived = 'METALS_TRADE_QUANTITY_BELOW_ALREADY_RECEIVED',
  MetalsTradeQuantityMinQuantityGreaterThanMaxQuantity = 'METALS_TRADE_QUANTITY_MIN_QUANTITY_GREATER_THAN_MAX_QUANTITY',
  MetalsTradeQuantityMustBePositive = 'METALS_TRADE_QUANTITY_MUST_BE_POSITIVE',
  MetalsTradeQuantityNegative = 'METALS_TRADE_QUANTITY_NEGATIVE',
  MetalsTradeQuantityOptionalityFieldsRequired = 'METALS_TRADE_QUANTITY_OPTIONALITY_FIELDS_REQUIRED',
  MetalsTradeQuantityOptionalityOptionNoneInvalid = 'METALS_TRADE_QUANTITY_OPTIONALITY_OPTION_NONE_INVALID',
  MetalsTradeQuantityQuotaRangeInvalid = 'METALS_TRADE_QUANTITY_QUOTA_RANGE_INVALID',
  MetalsTradeQuantityQuotaSumInvalid = 'METALS_TRADE_QUANTITY_QUOTA_SUM_INVALID',
  MetalsTradeQuantityRequired = 'METALS_TRADE_QUANTITY_REQUIRED',
  MetalsTradeReferenceNotUnique = 'METALS_TRADE_REFERENCE_NOT_UNIQUE',
  MetalsTradeReferenceRequiredIfLive = 'METALS_TRADE_REFERENCE_REQUIRED_IF_LIVE',
  MetalsTradeReferenceTooLong = 'METALS_TRADE_REFERENCE_TOO_LONG',
  MetalsTradeRegionNotFound = 'METALS_TRADE_REGION_NOT_FOUND',
  MetalsTradeRegionRequired = 'METALS_TRADE_REGION_REQUIRED',
  MetalsTradeSellerRequired = 'METALS_TRADE_SELLER_REQUIRED',
  MetalsTradeShapeConstraintIsZero = 'METALS_TRADE_SHAPE_CONSTRAINT_IS_ZERO',
  MetalsTradeShapeConstraintSumTooLow = 'METALS_TRADE_SHAPE_CONSTRAINT_SUM_TOO_LOW',
  MetalsTradeShapeConstraintTooHigh = 'METALS_TRADE_SHAPE_CONSTRAINT_TOO_HIGH',
  MetalsTradeShapeOptionRequired = 'METALS_TRADE_SHAPE_OPTION_REQUIRED',
  MetalsTradeShapeRequired = 'METALS_TRADE_SHAPE_REQUIRED',
  MetalsTradeShippingOrgRequired = 'METALS_TRADE_SHIPPING_ORG_REQUIRED',
  MetalsTradeShippingScheduleDatesNotRequired = 'METALS_TRADE_SHIPPING_SCHEDULE_DATES_NOT_REQUIRED',
  MetalsTradeShippingScheduleDatesRequired = 'METALS_TRADE_SHIPPING_SCHEDULE_DATES_REQUIRED',
  MetalsTradeShippingScheduleRequired = 'METALS_TRADE_SHIPPING_SCHEDULE_REQUIRED',
  MetalsTradeShippingToleranceGreaterThanZero = 'METALS_TRADE_SHIPPING_TOLERANCE_GREATER_THAN_ZERO',
  MetalsTradeShippingToleranceLessThan_100 = 'METALS_TRADE_SHIPPING_TOLERANCE_LESS_THAN_100',
  MetalsTradeShippingToleranceRequired = 'METALS_TRADE_SHIPPING_TOLERANCE_REQUIRED',
  MetalsTradeStartDateAfterEndDate = 'METALS_TRADE_START_DATE_AFTER_END_DATE',
  MetalsTradeStartDateRequired = 'METALS_TRADE_START_DATE_REQUIRED',
  MetalsTradeTitleTransferTriggerRequired = 'METALS_TRADE_TITLE_TRANSFER_TRIGGER_REQUIRED',
  MetalsTradeTooManyShapes = 'METALS_TRADE_TOO_MANY_SHAPES',
  MetalsTradeUserNotInOrg = 'METALS_TRADE_USER_NOT_IN_ORG',
  MetalsTradeValidInErp = 'METALS_TRADE_VALID_IN_ERP',
  MultipleInvoicesFound = 'MULTIPLE_INVOICES_FOUND',
  MultipleSubquotasFound = 'MULTIPLE_SUBQUOTAS_FOUND',
  Nan = 'NAN',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotImplemented = 'NOT_IMPLEMENTED',
  NoSubquotasProvided = 'NO_SUBQUOTAS_PROVIDED',
  OnboardingInvalidTranslationType = 'ONBOARDING_INVALID_TRANSLATION_TYPE',
  OptionalityDeclarationDateRuleOffsetRequired = 'OPTIONALITY_DECLARATION_DATE_RULE_OFFSET_REQUIRED',
  OptionalityMismatch = 'OPTIONALITY_MISMATCH',
  OrderCannotBeSyncedAsLineIsPending = 'ORDER_CANNOT_BE_SYNCED_AS_LINE_IS_PENDING',
  OrderCannotUpdateReferenceNumberForErpIntegrated = 'ORDER_CANNOT_UPDATE_REFERENCE_NUMBER_FOR_ERP_INTEGRATED',
  OrderCanOnlyArchiveErpIntegratedUnsynced = 'ORDER_CAN_ONLY_ARCHIVE_ERP_INTEGRATED_UNSYNCED',
  OrderCanOnlySyncUnsyncedOrPartialSyncToErp = 'ORDER_CAN_ONLY_SYNC_UNSYNCED_OR_PARTIAL_SYNC_TO_ERP',
  OrderCanOnlyUpdateErpIntegratedUnsynced = 'ORDER_CAN_ONLY_UPDATE_ERP_INTEGRATED_UNSYNCED',
  OrderErpAdapterRequired = 'ORDER_ERP_ADAPTER_REQUIRED',
  OrderErpNotApplicable = 'ORDER_ERP_NOT_APPLICABLE',
  OrderForSubquotaExists = 'ORDER_FOR_SUBQUOTA_EXISTS',
  OrderInvalidPk = 'ORDER_INVALID_PK',
  OrderManualCannotBeSyncedToErp = 'ORDER_MANUAL_CANNOT_BE_SYNCED_TO_ERP',
  OrderManualShouldNotLogErpRequest = 'ORDER_MANUAL_SHOULD_NOT_LOG_ERP_REQUEST',
  OrderManualShouldNotLogErpResponse = 'ORDER_MANUAL_SHOULD_NOT_LOG_ERP_RESPONSE',
  OrderNumberForTradePeriodExists = 'ORDER_NUMBER_FOR_TRADE_PERIOD_EXISTS',
  OrderPaymentEntityRequired = 'ORDER_PAYMENT_ENTITY_REQUIRED',
  OrderReferenceNumberInvalid = 'ORDER_REFERENCE_NUMBER_INVALID',
  OrderReferenceNumberMissing = 'ORDER_REFERENCE_NUMBER_MISSING',
  OrderTradePeriodPkMismatch = 'ORDER_TRADE_PERIOD_PK_MISMATCH',
  PaymentTermsBdcRequired = 'PAYMENT_TERMS_BDC_REQUIRED',
  PaymentTermsDateRuleRequired = 'PAYMENT_TERMS_DATE_RULE_REQUIRED',
  PaymentTermsEventRequired = 'PAYMENT_TERMS_EVENT_REQUIRED',
  PaymentTermsInvalid = 'PAYMENT_TERMS_INVALID',
  PaymentTermsInvalidDateRule = 'PAYMENT_TERMS_INVALID_DATE_RULE',
  PaymentTermsInvalidEventForIncoterms = 'PAYMENT_TERMS_INVALID_EVENT_FOR_INCOTERMS',
  PaymentTermsInvalidTrigger = 'PAYMENT_TERMS_INVALID_TRIGGER',
  PaymentTermsRequired = 'PAYMENT_TERMS_REQUIRED',
  PremiumModifierUnitsCurrencyIdRequired = 'PREMIUM_MODIFIER_UNITS_CURRENCY_ID_REQUIRED',
  PremiumModifierUnitsQuantityUnitRequired = 'PREMIUM_MODIFIER_UNITS_QUANTITY_UNIT_REQUIRED',
  PremiumModifierUnitsRequired = 'PREMIUM_MODIFIER_UNITS_REQUIRED',
  PremiumModifierUpdateForbidden = 'PREMIUM_MODIFIER_UPDATE_FORBIDDEN',
  PriceRequired = 'PRICE_REQUIRED',
  PricingEndDateNotBusinessDay = 'PRICING_END_DATE_NOT_BUSINESS_DAY',
  PricingEndDateRequired = 'PRICING_END_DATE_REQUIRED',
  PricingExpressionCurrencyNotUniform = 'PRICING_EXPRESSION_CURRENCY_NOT_UNIFORM',
  PricingExpressionDateRulesInvalid = 'PRICING_EXPRESSION_DATE_RULES_INVALID',
  PricingExpressionFixedDateFallsOnHoliday = 'PRICING_EXPRESSION_FIXED_DATE_FALLS_ON_HOLIDAY',
  PricingExpressionInvalidRefValAndQpConf = 'PRICING_EXPRESSION_INVALID_REF_VAL_AND_QP_CONF',
  PricingExpressionMissingQpType = 'PRICING_EXPRESSION_MISSING_QP_TYPE',
  PricingExpressionTargetNotOnCommodity = 'PRICING_EXPRESSION_TARGET_NOT_ON_COMMODITY',
  PricingExpressionTargetNotOnTrade = 'PRICING_EXPRESSION_TARGET_NOT_ON_TRADE',
  PricingExpressionTradeCurrencyMismatch = 'PRICING_EXPRESSION_TRADE_CURRENCY_MISMATCH',
  PricingExpressionTypeFixedInvalid = 'PRICING_EXPRESSION_TYPE_FIXED_INVALID',
  PricingExpressionTypeForwardInvalid = 'PRICING_EXPRESSION_TYPE_FORWARD_INVALID',
  PricingFixedAndFloating = 'PRICING_FIXED_AND_FLOATING',
  PricingFormulaArchiveInvalid = 'PRICING_FORMULA_ARCHIVE_INVALID',
  PricingFormulaCommodityIndexNotFound = 'PRICING_FORMULA_COMMODITY_INDEX_NOT_FOUND',
  PricingFormulaCommodityNotFound = 'PRICING_FORMULA_COMMODITY_NOT_FOUND',
  PricingFormulaCommodityUpdateInvalid = 'PRICING_FORMULA_COMMODITY_UPDATE_INVALID',
  PricingFormulaContractIndexMismatch = 'PRICING_FORMULA_CONTRACT_INDEX_MISMATCH',
  PricingFormulaDbValidationError = 'PRICING_FORMULA_DB_VALIDATION_ERROR',
  PricingFormulaDeleteInvalid = 'PRICING_FORMULA_DELETE_INVALID',
  PricingFormulaIndexIsPremiumOnly = 'PRICING_FORMULA_INDEX_IS_PREMIUM_ONLY',
  PricingFormulaInvalidCommodityPk = 'PRICING_FORMULA_INVALID_COMMODITY_PK',
  PricingFormulaInvalidTradePk = 'PRICING_FORMULA_INVALID_TRADE_PK',
  PricingFormulaNameInvalid = 'PRICING_FORMULA_NAME_INVALID',
  PricingFormulaQpFixedDateRangeInvalid = 'PRICING_FORMULA_QP_FIXED_DATE_RANGE_INVALID',
  PricingFormulaQpTypeDateFixedDatesMismatch = 'PRICING_FORMULA_QP_TYPE_DATE_FIXED_DATES_MISMATCH',
  PricingFormulaQpTypeDateRequiresFixedDates = 'PRICING_FORMULA_QP_TYPE_DATE_REQUIRES_FIXED_DATES',
  PricingFormulaQpTypeEventRequiresDateRules = 'PRICING_FORMULA_QP_TYPE_EVENT_REQUIRES_DATE_RULES',
  PricingFormulaQpTypeEventRequiresQpEvent = 'PRICING_FORMULA_QP_TYPE_EVENT_REQUIRES_QP_EVENT',
  PricingFormulaQpTypeMonthRequiresNoDateFields = 'PRICING_FORMULA_QP_TYPE_MONTH_REQUIRES_NO_DATE_FIELDS',
  PricingFormulaQpTypeThirdWedsDateValues = 'PRICING_FORMULA_QP_TYPE_THIRD_WEDS_DATE_VALUES',
  PricingFormulaRequiresOnePricingTerm = 'PRICING_FORMULA_REQUIRES_ONE_PRICING_TERM',
  PricingFormulaTradeInvalid = 'PRICING_FORMULA_TRADE_INVALID',
  PricingFormulaTradeMaterialMismatch = 'PRICING_FORMULA_TRADE_MATERIAL_MISMATCH',
  PricingFormulaUpdateInvalid = 'PRICING_FORMULA_UPDATE_INVALID',
  PricingStartDateAfterPricingEndDate = 'PRICING_START_DATE_AFTER_PRICING_END_DATE',
  PricingStartDateNotBusinessDay = 'PRICING_START_DATE_NOT_BUSINESS_DAY',
  PricingStartDateRequired = 'PRICING_START_DATE_REQUIRED',
  PricingTermPaymentTermsInvalid = 'PRICING_TERM_PAYMENT_TERMS_INVALID',
  PricingTermRequiresOneBasePricingExpression = 'PRICING_TERM_REQUIRES_ONE_BASE_PRICING_EXPRESSION',
  PricingTermRequiresOnePricingExpression = 'PRICING_TERM_REQUIRES_ONE_PRICING_EXPRESSION',
  PricingTiersRequired = 'PRICING_TIERS_REQUIRED',
  PricingTierFormulaNotFound = 'PRICING_TIER_FORMULA_NOT_FOUND',
  PricingTierIsLastQuantityInvalid = 'PRICING_TIER_IS_LAST_QUANTITY_INVALID',
  PricingTierLiveDeleteInvalid = 'PRICING_TIER_LIVE_DELETE_INVALID',
  PricingTierLiveUpdateInvalid = 'PRICING_TIER_LIVE_UPDATE_INVALID',
  PricingTierOrdinalsInvalid = 'PRICING_TIER_ORDINALS_INVALID',
  PricingUnitMultipleCurrenciesForTotalPrice = 'PRICING_UNIT_MULTIPLE_CURRENCIES_FOR_TOTAL_PRICE',
  PrintLabelsFailedTooManyTotalBundles = 'PRINT_LABELS_FAILED_TOO_MANY_TOTAL_BUNDLES',
  ProductionAccountAlreadyHasSchedules = 'PRODUCTION_ACCOUNT_ALREADY_HAS_SCHEDULES',
  ProductionPlanHasNoInputs = 'PRODUCTION_PLAN_HAS_NO_INPUTS',
  ProductionPlanInvalidDates = 'PRODUCTION_PLAN_INVALID_DATES',
  ProductionPlanInvalidFromAccount = 'PRODUCTION_PLAN_INVALID_FROM_ACCOUNT',
  ProductionPlanInvalidInputDates = 'PRODUCTION_PLAN_INVALID_INPUT_DATES',
  ProductionPlanInvalidMaterialSpec = 'PRODUCTION_PLAN_INVALID_MATERIAL_SPEC',
  ProductPkInvalid = 'PRODUCT_PK_INVALID',
  ProductRequireSingleValuePerAttribute = 'PRODUCT_REQUIRE_SINGLE_VALUE_PER_ATTRIBUTE',
  ProductTypeInvalid = 'PRODUCT_TYPE_INVALID',
  ProvisionalPaymentItemInvalidEventForIncoterms = 'PROVISIONAL_PAYMENT_ITEM_INVALID_EVENT_FOR_INCOTERMS',
  ProvisionalPaymentItemInvalidPricingPaymentCombo = 'PROVISIONAL_PAYMENT_ITEM_INVALID_PRICING_PAYMENT_COMBO',
  ProvisionalPaymentItemPaymentTermsInvalid = 'PROVISIONAL_PAYMENT_ITEM_PAYMENT_TERMS_INVALID',
  ProvisionalPaymentItemPaymentTermsRequired = 'PROVISIONAL_PAYMENT_ITEM_PAYMENT_TERMS_REQUIRED',
  ProvisionalPaymentItemPricingInvalid = 'PROVISIONAL_PAYMENT_ITEM_PRICING_INVALID',
  ProvisionalPaymentItemPricingRequired = 'PROVISIONAL_PAYMENT_ITEM_PRICING_REQUIRED',
  ProvisionalPricingNotSupported = 'PROVISIONAL_PRICING_NOT_SUPPORTED',
  QpEventNotSupported = 'QP_EVENT_NOT_SUPPORTED',
  QpTypeMonthFixedDateRuleMisconfiguration = 'QP_TYPE_MONTH_FIXED_DATE_RULE_MISCONFIGURATION',
  QpTypeMonthFixedPeriodOffsetMonthOffsetMismatch = 'QP_TYPE_MONTH_FIXED_PERIOD_OFFSET_MONTH_OFFSET_MISMATCH',
  QpTypeMonthFixedRequiresOrdinalBdc = 'QP_TYPE_MONTH_FIXED_REQUIRES_ORDINAL_BDC',
  QpTypeMonthFixedRequiresSingleMonthOffset = 'QP_TYPE_MONTH_FIXED_REQUIRES_SINGLE_MONTH_OFFSET',
  QpTypeMonthOrEventFixedRequiresEqualDaterules = 'QP_TYPE_MONTH_OR_EVENT_FIXED_REQUIRES_EQUAL_DATERULES',
  QpTypeNotSupported = 'QP_TYPE_NOT_SUPPORTED',
  QuantityRoundingIncorrect = 'QUANTITY_ROUNDING_INCORRECT',
  ReceivingDocumentAlreadyExists = 'RECEIVING_DOCUMENT_ALREADY_EXISTS',
  ReceivingDocumentCodeNotFound = 'RECEIVING_DOCUMENT_CODE_NOT_FOUND',
  ReceivingHoursBadTimeFormat = 'RECEIVING_HOURS_BAD_TIME_FORMAT',
  ReceivingHoursDuplicateDay = 'RECEIVING_HOURS_DUPLICATE_DAY',
  ReceivingHoursInconsistentTimes = 'RECEIVING_HOURS_INCONSISTENT_TIMES',
  ReceivingHoursInvalidMaxSlots = 'RECEIVING_HOURS_INVALID_MAX_SLOTS',
  ReceivingHoursTimeRequired = 'RECEIVING_HOURS_TIME_REQUIRED',
  RegionMaterialDoesNotExist = 'REGION_MATERIAL_DOES_NOT_EXIST',
  RegionMaterialNotAuthorized = 'REGION_MATERIAL_NOT_AUTHORIZED',
  RoleInvalidLocation = 'ROLE_INVALID_LOCATION',
  RoleInvalidMinimumScope = 'ROLE_INVALID_MINIMUM_SCOPE',
  RoleInvalidOrgRegion = 'ROLE_INVALID_ORG_REGION',
  RoleInvalidPk = 'ROLE_INVALID_PK',
  RoleInvalidRoleOrganizationType = 'ROLE_INVALID_ROLE_ORGANIZATION_TYPE',
  RoleInvalidScope = 'ROLE_INVALID_SCOPE',
  RoleInvalidUserOrganization = 'ROLE_INVALID_USER_ORGANIZATION',
  RoleLocationInvalidPk = 'ROLE_LOCATION_INVALID_PK',
  RoleOrgRegionInvalidPk = 'ROLE_ORG_REGION_INVALID_PK',
  RoleUserInvalidPk = 'ROLE_USER_INVALID_PK',
  ScaleToleranceRequiredForReceiving = 'SCALE_TOLERANCE_REQUIRED_FOR_RECEIVING',
  ScaleWeightErrorBadWeight = 'SCALE_WEIGHT_ERROR_BAD_WEIGHT',
  ScaleWeightErrorNoUnits = 'SCALE_WEIGHT_ERROR_NO_UNITS',
  ScheduleDaysMustSumToTotal = 'SCHEDULE_DAYS_MUST_SUM_TO_TOTAL',
  ScheduleForecastInvalidDay = 'SCHEDULE_FORECAST_INVALID_DAY',
  ScheduleForecastSellerOrderNoBuyerOrder = 'SCHEDULE_FORECAST_SELLER_ORDER_NO_BUYER_ORDER',
  ScheduleHasNoTargetAccount = 'SCHEDULE_HAS_NO_TARGET_ACCOUNT',
  ScheduleInsufficientBolBalance = 'SCHEDULE_INSUFFICIENT_BOL_BALANCE',
  ScheduleInvalidBol = 'SCHEDULE_INVALID_BOL',
  ScheduleInvalidConveyance = 'SCHEDULE_INVALID_CONVEYANCE',
  ScheduleInvalidDates = 'SCHEDULE_INVALID_DATES',
  ScheduleInvalidDays = 'SCHEDULE_INVALID_DAYS',
  ScheduleInvalidErpIntegration = 'SCHEDULE_INVALID_ERP_INTEGRATION',
  ScheduleInvalidFromAccount = 'SCHEDULE_INVALID_FROM_ACCOUNT',
  ScheduleInvalidFromLocation = 'SCHEDULE_INVALID_FROM_LOCATION',
  ScheduleInvalidMaterial = 'SCHEDULE_INVALID_MATERIAL',
  ScheduleInvalidOrganization = 'SCHEDULE_INVALID_ORGANIZATION',
  ScheduleInvalidPrimaryAccount = 'SCHEDULE_INVALID_PRIMARY_ACCOUNT',
  ScheduleInvalidQuantity = 'SCHEDULE_INVALID_QUANTITY',
  ScheduleInvalidQuantityUnit = 'SCHEDULE_INVALID_QUANTITY_UNIT',
  ScheduleInvalidSchedule = 'SCHEDULE_INVALID_SCHEDULE',
  ScheduleInvalidSchedulingPolicy = 'SCHEDULE_INVALID_SCHEDULING_POLICY',
  ScheduleInvalidSpreadMethod = 'SCHEDULE_INVALID_SPREAD_METHOD',
  ScheduleInvalidToAccount = 'SCHEDULE_INVALID_TO_ACCOUNT',
  ScheduleInvalidTransitTimeDays = 'SCHEDULE_INVALID_TRANSIT_TIME_DAYS',
  ScheduleLineItemBundleCountMustBeOneIfNoDiscreteUnits = 'SCHEDULE_LINE_ITEM_BUNDLE_COUNT_MUST_BE_ONE_IF_NO_DISCRETE_UNITS',
  ScheduleLineItemCannotCalculateBundleCount = 'SCHEDULE_LINE_ITEM_CANNOT_CALCULATE_BUNDLE_COUNT',
  ScheduleLineItemDuplicateConveyance = 'SCHEDULE_LINE_ITEM_DUPLICATE_CONVEYANCE',
  ScheduleLineItemHasConfirmedShipments = 'SCHEDULE_LINE_ITEM_HAS_CONFIRMED_SHIPMENTS',
  ScheduleLineItemMissingRoute = 'SCHEDULE_LINE_ITEM_MISSING_ROUTE',
  ScheduleLineItemNotFound = 'SCHEDULE_LINE_ITEM_NOT_FOUND',
  ScheduleMissingToLocation = 'SCHEDULE_MISSING_TO_LOCATION',
  ScheduleNotFound = 'SCHEDULE_NOT_FOUND',
  ScheduleNotSubmitted = 'SCHEDULE_NOT_SUBMITTED',
  SchedulePastDate = 'SCHEDULE_PAST_DATE',
  ScheduleRequiredDays = 'SCHEDULE_REQUIRED_DAYS',
  ScheduleRequiredDayQuantity = 'SCHEDULE_REQUIRED_DAY_QUANTITY',
  ScheduleRequiredDayShipmentCount = 'SCHEDULE_REQUIRED_DAY_SHIPMENT_COUNT',
  ScheduleRequiredPoNumber = 'SCHEDULE_REQUIRED_PO_NUMBER',
  ScheduleRequiredQuantityPerShipment = 'SCHEDULE_REQUIRED_QUANTITY_PER_SHIPMENT',
  ScheduleRequiredTransitTimeDays = 'SCHEDULE_REQUIRED_TRANSIT_TIME_DAYS',
  ScheduleUnauthorizedAction = 'SCHEDULE_UNAUTHORIZED_ACTION',
  ScheduleUnsupportedConveyance = 'SCHEDULE_UNSUPPORTED_CONVEYANCE',
  ScheduleUnsupportedConveyanceDestination = 'SCHEDULE_UNSUPPORTED_CONVEYANCE_DESTINATION',
  ScheduleWrongUpdateMutation = 'SCHEDULE_WRONG_UPDATE_MUTATION',
  ShipmentErrorNoBundleCount = 'SHIPMENT_ERROR_NO_BUNDLE_COUNT',
  ShipmentErrorNoIdentifier = 'SHIPMENT_ERROR_NO_IDENTIFIER',
  ShipmentErrorNoProduct = 'SHIPMENT_ERROR_NO_PRODUCT',
  ShipmentErrorNoStorageLocation = 'SHIPMENT_ERROR_NO_STORAGE_LOCATION',
  ShipmentErrorNoTargetAccount = 'SHIPMENT_ERROR_NO_TARGET_ACCOUNT',
  ShipmentErrorOrderTargetAccountMismatch = 'SHIPMENT_ERROR_ORDER_TARGET_ACCOUNT_MISMATCH',
  ShipmentErrorPoFull = 'SHIPMENT_ERROR_PO_FULL',
  ShipmentErrorPoLocationMismatch = 'SHIPMENT_ERROR_PO_LOCATION_MISMATCH',
  ShipmentErrorPoMaterialMismatch = 'SHIPMENT_ERROR_PO_MATERIAL_MISMATCH',
  ShipmentErrorStoAlreadyComplete = 'SHIPMENT_ERROR_STO_ALREADY_COMPLETE',
  ShipmentErrorStoLastTruck = 'SHIPMENT_ERROR_STO_LAST_TRUCK',
  ShipmentErrorStoMaterialMismatch = 'SHIPMENT_ERROR_STO_MATERIAL_MISMATCH',
  ShipmentErrorStoNotLastTruckButHeavy = 'SHIPMENT_ERROR_STO_NOT_LAST_TRUCK_BUT_HEAVY',
  ShipmentErrorSubquotaMultipleMatches = 'SHIPMENT_ERROR_SUBQUOTA_MULTIPLE_MATCHES',
  ShipmentErrorSubquotaNoMatches = 'SHIPMENT_ERROR_SUBQUOTA_NO_MATCHES',
  ShipmentErrorUnloadException = 'SHIPMENT_ERROR_UNLOAD_EXCEPTION',
  ShipmentErrorWeightDiscrepancy = 'SHIPMENT_ERROR_WEIGHT_DISCREPANCY',
  ShipmentValidInErp = 'SHIPMENT_VALID_IN_ERP',
  StorageLocationPkInvalid = 'STORAGE_LOCATION_PK_INVALID',
  SubquotaAmendmentInvalid = 'SUBQUOTA_AMENDMENT_INVALID',
  SubquotaAmendmentPendingDecl = 'SUBQUOTA_AMENDMENT_PENDING_DECL',
  SubquotaAmendmentPendingPeriodDecl = 'SUBQUOTA_AMENDMENT_PENDING_PERIOD_DECL',
  SubquotaBookingDeclarationNotNominator = 'SUBQUOTA_BOOKING_DECLARATION_NOT_NOMINATOR',
  SubquotaBookingInvalid = 'SUBQUOTA_BOOKING_INVALID',
  SubquotaBulkUpdateDuplicatePks = 'SUBQUOTA_BULK_UPDATE_DUPLICATE_PKS',
  SubquotaDbValidationError = 'SUBQUOTA_DB_VALIDATION_ERROR',
  SubquotaDeclarationDeliveryTermMaxPerPeriodLimitViolated = 'SUBQUOTA_DECLARATION_DELIVERY_TERM_MAX_PER_PERIOD_LIMIT_VIOLATED',
  SubquotaDeclarationInvalid = 'SUBQUOTA_DECLARATION_INVALID',
  SubquotaDeclarationLimitValidationError = 'SUBQUOTA_DECLARATION_LIMIT_VALIDATION_ERROR',
  SubquotaDeclarationMaterialSpecificationMaxPerPeriodLimitViolated = 'SUBQUOTA_DECLARATION_MATERIAL_SPECIFICATION_MAX_PER_PERIOD_LIMIT_VIOLATED',
  SubquotaDeclarationNotNeeded = 'SUBQUOTA_DECLARATION_NOT_NEEDED',
  SubquotaDeclarationNotNominator = 'SUBQUOTA_DECLARATION_NOT_NOMINATOR',
  SubquotaDeclarationPendingPeriodDecl = 'SUBQUOTA_DECLARATION_PENDING_PERIOD_DECL',
  SubquotaDeclarationWithoutOptionality = 'SUBQUOTA_DECLARATION_WITHOUT_OPTIONALITY',
  SubquotaDeliveryTermInvalid = 'SUBQUOTA_DELIVERY_TERM_INVALID',
  SubquotaInvalidQuantity = 'SUBQUOTA_INVALID_QUANTITY',
  SubquotaLiveTradeDeclarationUpdate = 'SUBQUOTA_LIVE_TRADE_DECLARATION_UPDATE',
  SubquotaMultipleMatchingOrdersForPoNumber = 'SUBQUOTA_MULTIPLE_MATCHING_ORDERS_FOR_PO_NUMBER',
  SubquotaNegativeQuantity = 'SUBQUOTA_NEGATIVE_QUANTITY',
  SubquotaNoSubquotaPks = 'SUBQUOTA_NO_SUBQUOTA_PKS',
  SubquotaNoTradePeriodPks = 'SUBQUOTA_NO_TRADE_PERIOD_PKS',
  SubquotaOverlappingQuantities = 'SUBQUOTA_OVERLAPPING_QUANTITIES',
  SubquotaOverrideInvalid = 'SUBQUOTA_OVERRIDE_INVALID',
  SubquotaOverrideInvalidDeliveryTermPks = 'SUBQUOTA_OVERRIDE_INVALID_DELIVERY_TERM_PKS',
  SubquotaOverrideInvalidMaterialSpecificationPks = 'SUBQUOTA_OVERRIDE_INVALID_MATERIAL_SPECIFICATION_PKS',
  SubquotaOverrideOverlappingInput = 'SUBQUOTA_OVERRIDE_OVERLAPPING_INPUT',
  SubquotaPksInvalid = 'SUBQUOTA_PKS_INVALID',
  SubquotaPoNumberGenerationForErpEnabledBuyerError = 'SUBQUOTA_PO_NUMBER_GENERATION_FOR_ERP_ENABLED_BUYER_ERROR',
  SubquotaPoNumberInvalidOrg = 'SUBQUOTA_PO_NUMBER_INVALID_ORG',
  SubquotaQuantityBelowAlreadyReceived = 'SUBQUOTA_QUANTITY_BELOW_ALREADY_RECEIVED',
  SubquotaQuantityPercentageInvalid = 'SUBQUOTA_QUANTITY_PERCENTAGE_INVALID',
  SubquotaQuotaQuantityMistmatch = 'SUBQUOTA_QUOTA_QUANTITY_MISTMATCH',
  SubquotaRequiresTradePeriodPk = 'SUBQUOTA_REQUIRES_TRADE_PERIOD_PK',
  SubquotaTradePeriodInvalid = 'SUBQUOTA_TRADE_PERIOD_INVALID',
  SubquotaTradePeriodPksInvalid = 'SUBQUOTA_TRADE_PERIOD_PKS_INVALID',
  SubquotaTradePksInvalid = 'SUBQUOTA_TRADE_PKS_INVALID',
  TrackingUnknownConveyance = 'TRACKING_UNKNOWN_CONVEYANCE',
  TradeManagerUnknownViewType = 'TRADE_MANAGER_UNKNOWN_VIEW_TYPE',
  TradePeriodDuplicateTradeDetails = 'TRADE_PERIOD_DUPLICATE_TRADE_DETAILS',
  TradePeriodInvalidScheduleNoChangeDetected = 'TRADE_PERIOD_INVALID_SCHEDULE_NO_CHANGE_DETECTED',
  TradePeriodInvalidScheduleStateTransition = 'TRADE_PERIOD_INVALID_SCHEDULE_STATE_TRANSITION',
  TradePeriodMonthlyOrderNumberNotUnique = 'TRADE_PERIOD_MONTHLY_ORDER_NUMBER_NOT_UNIQUE',
  TradePeriodMonthlyOrderNumberRequired = 'TRADE_PERIOD_MONTHLY_ORDER_NUMBER_REQUIRED',
  TradePeriodMonthlyOrderNumberTooLong = 'TRADE_PERIOD_MONTHLY_ORDER_NUMBER_TOO_LONG',
  TradePeriodSubquotaOverrideInvalid = 'TRADE_PERIOD_SUBQUOTA_OVERRIDE_INVALID',
  TradePricingTermRequiresPaymentTerms = 'TRADE_PRICING_TERM_REQUIRES_PAYMENT_TERMS',
  TradeSummaryConfigInvalidTrade = 'TRADE_SUMMARY_CONFIG_INVALID_TRADE',
  TransportationProviderInvalidPk = 'TRANSPORTATION_PROVIDER_INVALID_PK',
  VesselCarrierPkInvalid = 'VESSEL_CARRIER_PK_INVALID',
  WeightMustBePositive = 'WEIGHT_MUST_BE_POSITIVE'
}

export enum FormulaType {
  Template = 'TEMPLATE',
  Trade = 'TRADE'
}

export enum GraphqlConsignmentReleaseActionTypeChoices {
  Adjustment = 'ADJUSTMENT',
  Receive = 'RECEIVE',
  Release = 'RELEASE',
  ScheduleRelease = 'SCHEDULE_RELEASE'
}

export enum GraphqlInventoryBalanceAccountTypeChoices {
  Consignment = 'CONSIGNMENT',
  Plant = 'PLANT',
  Production = 'PRODUCTION'
}

export enum IncotermCode {
  Cfr = 'CFR',
  Cif = 'CIF',
  Cip = 'CIP',
  Cpt = 'CPT',
  Dap = 'DAP',
  Dat = 'DAT',
  Ddp = 'DDP',
  Dpu = 'DPU',
  Exw = 'EXW',
  Fas = 'FAS',
  Fca = 'FCA',
  Fob = 'FOB',
  For = 'FOR',
  Fot = 'FOT'
}

export enum IntegrationPreprocessingKey {
  BulkShipmentUpload = 'BULK_SHIPMENT_UPLOAD'
}

export enum InventoryAccountInegrationMapOwner {
  AccountHolder = 'AccountHolder',
  LocationOwner = 'LocationOwner',
  Self = 'Self'
}

export enum InventoryAccountManagerAccountType {
  Consignment = 'CONSIGNMENT',
  Plant = 'PLANT',
  Production = 'PRODUCTION',
  Warehouse = 'WAREHOUSE'
}

export enum InventoryAccountManagerExportOrderInput {
  AccountArchivedAt = 'ACCOUNT_ARCHIVED_AT',
  AccountHolderName = 'ACCOUNT_HOLDER_NAME',
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountLocationOwnerPk = 'ACCOUNT_LOCATION_OWNER_PK',
  AccountName = 'ACCOUNT_NAME',
  AccountType = 'ACCOUNT_TYPE',
  BalanceQuantityLb = 'BALANCE_QUANTITY_LB',
  BalanceQuantityMt = 'BALANCE_QUANTITY_MT',
  EndDate = 'END_DATE',
  Id = 'ID',
  InTransitAndDeliveredQuantityLb = 'IN_TRANSIT_AND_DELIVERED_QUANTITY_LB',
  InTransitAndDeliveredQuantityMt = 'IN_TRANSIT_AND_DELIVERED_QUANTITY_MT',
  IsArchived = 'IS_ARCHIVED',
  LocationName = 'LOCATION_NAME',
  LocationOwnerName = 'LOCATION_OWNER_NAME',
  LocationPk = 'LOCATION_PK',
  MaterialName = 'MATERIAL_NAME',
  MaterialPk = 'MATERIAL_PK',
  PoNumbers = 'PO_NUMBERS',
  ReadyForRelease = 'READY_FOR_RELEASE',
  ReceivedLb = 'RECEIVED_LB',
  ReceivedMt = 'RECEIVED_MT',
  ReleasedLb = 'RELEASED_LB',
  ReleasedMt = 'RELEASED_MT',
  ScheduleLastUpdatedAt = 'SCHEDULE_LAST_UPDATED_AT',
  StartDate = 'START_DATE'
}

export enum InventoryAccountManagerOrderingField {
  AccountArchivedAt = 'ACCOUNT_ARCHIVED_AT',
  AccountHolderName = 'ACCOUNT_HOLDER_NAME',
  AccountHolderPhoto = 'ACCOUNT_HOLDER_PHOTO',
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountLocationOwnerPk = 'ACCOUNT_LOCATION_OWNER_PK',
  AccountName = 'ACCOUNT_NAME',
  AccountType = 'ACCOUNT_TYPE',
  BalanceQuantityLb = 'BALANCE_QUANTITY_LB',
  BalanceQuantityMt = 'BALANCE_QUANTITY_MT',
  EndDate = 'END_DATE',
  InTransitAndDeliveredQuantityLb = 'IN_TRANSIT_AND_DELIVERED_QUANTITY_LB',
  InTransitAndDeliveredQuantityMt = 'IN_TRANSIT_AND_DELIVERED_QUANTITY_MT',
  IsArchived = 'IS_ARCHIVED',
  LocationName = 'LOCATION_NAME',
  LocationOwnerName = 'LOCATION_OWNER_NAME',
  LocationPk = 'LOCATION_PK',
  MaterialName = 'MATERIAL_NAME',
  MaterialPk = 'MATERIAL_PK',
  Pk = 'PK',
  PoNumbers = 'PO_NUMBERS',
  ReadyForRelease = 'READY_FOR_RELEASE',
  ReceivedLb = 'RECEIVED_LB',
  ReceivedMt = 'RECEIVED_MT',
  ReleasedLb = 'RELEASED_LB',
  ReleasedMt = 'RELEASED_MT',
  ScheduleLastUpdatedAt = 'SCHEDULE_LAST_UPDATED_AT',
  StartDate = 'START_DATE'
}

export enum InventoryAccountReleaseInitiator {
  AccountHolder = 'ACCOUNT_HOLDER',
  Both = 'BOTH',
  LocationOwner = 'LOCATION_OWNER'
}

export enum InventoryAccountReleasesSummaryExportOrderInput {
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountLocationOwnerPk = 'ACCOUNT_LOCATION_OWNER_PK',
  AccountPk = 'ACCOUNT_PK',
  ActionPk = 'ACTION_PK',
  Id = 'ID',
  OrderLineItemNumber = 'ORDER_LINE_ITEM_NUMBER',
  OrderLineItemPk = 'ORDER_LINE_ITEM_PK',
  PoNumber = 'PO_NUMBER',
  QuantityLb = 'QUANTITY_LB',
  QuantityMt = 'QUANTITY_MT',
  ReleaseDate = 'RELEASE_DATE',
  ReleaseNumber = 'RELEASE_NUMBER',
  ReleaseToAccountPk = 'RELEASE_TO_ACCOUNT_PK',
  ReleaseToTradePk = 'RELEASE_TO_TRADE_PK',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradeQuotaMonth = 'TRADE_QUOTA_MONTH'
}

export enum InventoryAccountReleasesSummaryOrderingField {
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountLocationOwnerPk = 'ACCOUNT_LOCATION_OWNER_PK',
  AccountPk = 'ACCOUNT_PK',
  ActionPk = 'ACTION_PK',
  OrderLineItemNumber = 'ORDER_LINE_ITEM_NUMBER',
  OrderLineItemPk = 'ORDER_LINE_ITEM_PK',
  Pk = 'PK',
  PoNumber = 'PO_NUMBER',
  QuantityLb = 'QUANTITY_LB',
  QuantityMt = 'QUANTITY_MT',
  ReleaseDate = 'RELEASE_DATE',
  ReleaseNumber = 'RELEASE_NUMBER',
  ReleaseToAccountPk = 'RELEASE_TO_ACCOUNT_PK',
  ReleaseToTradePk = 'RELEASE_TO_TRADE_PK',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradeQuotaMonth = 'TRADE_QUOTA_MONTH'
}

export enum InventoryAccountType {
  Consignment = 'CONSIGNMENT',
  Plant = 'PLANT',
  Production = 'PRODUCTION'
}

export enum InventoryActionType {
  Adjustment = 'ADJUSTMENT',
  Receive = 'RECEIVE',
  Release = 'RELEASE',
  ScheduleRelease = 'SCHEDULE_RELEASE'
}

export enum InventoryBolLineItemStatus {
  ReadyForRelease = 'READY_FOR_RELEASE',
  Released = 'RELEASED'
}

export enum InventoryBalanceExportOrderInput {
  AccountHolderName = 'ACCOUNT_HOLDER_NAME',
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountName = 'ACCOUNT_NAME',
  AccountPk = 'ACCOUNT_PK',
  AccountType = 'ACCOUNT_TYPE',
  BalanceQuantityLb = 'BALANCE_QUANTITY_LB',
  BalanceQuantityMt = 'BALANCE_QUANTITY_MT',
  BatchId = 'BATCH_ID',
  BolNumber = 'BOL_NUMBER',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  CommodityName = 'COMMODITY_NAME',
  CommodityPk = 'COMMODITY_PK',
  InventoryBalanceId = 'INVENTORY_BALANCE_ID',
  LastTransactionAt = 'LAST_TRANSACTION_AT',
  LocationName = 'LOCATION_NAME',
  LocationOwnerName = 'LOCATION_OWNER_NAME',
  LocationOwnerPk = 'LOCATION_OWNER_PK',
  LocationPk = 'LOCATION_PK',
  ProductName = 'PRODUCT_NAME',
  ProductPk = 'PRODUCT_PK',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ToPoNumbers = 'TO_PO_NUMBERS'
}

export enum InventoryBalanceOrderingField {
  AccountHolderName = 'ACCOUNT_HOLDER_NAME',
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountName = 'ACCOUNT_NAME',
  AccountPk = 'ACCOUNT_PK',
  AccountType = 'ACCOUNT_TYPE',
  BalanceQuantityLb = 'BALANCE_QUANTITY_LB',
  BalanceQuantityMt = 'BALANCE_QUANTITY_MT',
  BatchId = 'BATCH_ID',
  BolNumber = 'BOL_NUMBER',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  CommodityName = 'COMMODITY_NAME',
  CommodityPk = 'COMMODITY_PK',
  InventoryBalanceId = 'INVENTORY_BALANCE_ID',
  LastTransactionAt = 'LAST_TRANSACTION_AT',
  LocationName = 'LOCATION_NAME',
  LocationOwnerName = 'LOCATION_OWNER_NAME',
  LocationOwnerPk = 'LOCATION_OWNER_PK',
  LocationPk = 'LOCATION_PK',
  ProductName = 'PRODUCT_NAME',
  ProductPk = 'PRODUCT_PK',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ToPoNumbers = 'TO_PO_NUMBERS'
}

export enum InventoryBalanceType {
  Any = 'ANY',
  Available = 'AVAILABLE',
  Committed = 'COMMITTED'
}

export enum InventoryInventoryAccountAccountTypeChoices {
  Consignment = 'CONSIGNMENT',
  Plant = 'PLANT',
  Production = 'PRODUCTION'
}

export enum InvoiceType {
  Final = 'FINAL',
  Provisional = 'PROVISIONAL'
}

export enum InvoicesExportOrderInput {
  Balance = 'BALANCE',
  BuyerName = 'BUYER_NAME',
  BuyerPk = 'BUYER_PK',
  CurrencyCode = 'CURRENCY_CODE',
  CurrencySymbol = 'CURRENCY_SYMBOL',
  DocumentId = 'DOCUMENT_ID',
  DocType = 'DOC_TYPE',
  DueDate = 'DUE_DATE',
  Id = 'ID',
  InvoiceValue = 'INVOICE_VALUE',
  IssueDate = 'ISSUE_DATE',
  MaxQuantity = 'MAX_QUANTITY',
  MinQuantity = 'MIN_QUANTITY',
  ParentInvoicePk = 'PARENT_INVOICE_PK',
  PeriodMonth = 'PERIOD_MONTH',
  PoNumbers = 'PO_NUMBERS',
  SellerName = 'SELLER_NAME',
  SellerPk = 'SELLER_PK',
  ShipmentPk = 'SHIPMENT_PK',
  ShipmentRef = 'SHIPMENT_REF',
  ShipperName = 'SHIPPER_NAME',
  ShipperPk = 'SHIPPER_PK',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradePk = 'TRADE_PK'
}

export enum InvoicesOrderingField {
  Balance = 'BALANCE',
  BuyerName = 'BUYER_NAME',
  BuyerPhoto = 'BUYER_PHOTO',
  BuyerPk = 'BUYER_PK',
  ChildInvoices = 'CHILD_INVOICES',
  CurrencyCode = 'CURRENCY_CODE',
  CurrencySymbol = 'CURRENCY_SYMBOL',
  DocumentId = 'DOCUMENT_ID',
  DocType = 'DOC_TYPE',
  DueDate = 'DUE_DATE',
  InvoiceValue = 'INVOICE_VALUE',
  IssueDate = 'ISSUE_DATE',
  MaxQuantity = 'MAX_QUANTITY',
  MinQuantity = 'MIN_QUANTITY',
  ParentInvoicePk = 'PARENT_INVOICE_PK',
  PeriodMonth = 'PERIOD_MONTH',
  Pk = 'PK',
  PoNumbers = 'PO_NUMBERS',
  SellerName = 'SELLER_NAME',
  SellerPhoto = 'SELLER_PHOTO',
  SellerPk = 'SELLER_PK',
  ShipmentPk = 'SHIPMENT_PK',
  ShipmentRef = 'SHIPMENT_REF',
  ShipperName = 'SHIPPER_NAME',
  ShipperPhoto = 'SHIPPER_PHOTO',
  ShipperPk = 'SHIPPER_PK',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradePk = 'TRADE_PK',
  TradeQuantity = 'TRADE_QUANTITY'
}

export enum LegacyType {
  Brand = 'BRAND',
  Grade = 'GRADE',
  Origin = 'ORIGIN',
  Shape = 'SHAPE'
}

export enum LimitScope {
  Period = 'Period',
  Trade = 'Trade'
}

export enum LineItemSyncStatus {
  Failed = 'FAILED',
  Manual = 'MANUAL',
  Pending = 'PENDING',
  PendingRequest = 'PENDING_REQUEST',
  Synced = 'SYNCED',
  Unknown = 'UNKNOWN',
  Unsynced = 'UNSYNCED'
}

export enum LocationType {
  Plant = 'PLANT',
  Warehouse = 'WAREHOUSE'
}

export enum MalwareScanResult {
  Fail = 'FAIL',
  Malware = 'MALWARE',
  Pending = 'PENDING',
  Safe = 'SAFE'
}

export enum MaterialSpecificationType {
  Product = 'PRODUCT',
  Schedule = 'SCHEDULE',
  Template = 'TEMPLATE',
  Trade = 'TRADE'
}

export enum MetalsFinalizedTradeDetailsConveyanceChoices {
  OnSite = 'ON_SITE',
  Rail = 'RAIL',
  Truck = 'TRUCK',
  Vessel = 'VESSEL',
  WarehouseTransfer = 'WAREHOUSE_TRANSFER'
}

export enum MetalsFinalizedTradeDetailsDeclarationStatusChoices {
  Declared = 'DECLARED',
  NotNeeded = 'NOT_NEEDED',
  Overridden = 'OVERRIDDEN',
  Pending = 'PENDING'
}

export enum MetalsFinalizedTradeDetailsQuantityUnitChoices {
  Lb = 'LB',
  Mt = 'MT'
}

export enum MetalsTradePeriodDeclarationStatusChoices {
  Declared = 'DECLARED',
  NotNeeded = 'NOT_NEEDED',
  Overridden = 'OVERRIDDEN',
  Pending = 'PENDING'
}

export enum MetalsTradeResourceExportOrder {
  BuySell = 'BUY_SELL',
  Counterparties = 'COUNTERPARTIES',
  Destination = 'DESTINATION',
  EndDate = 'END_DATE',
  Id = 'ID',
  Material = 'MATERIAL',
  MaxQuantity = 'MAX_QUANTITY',
  MinQuantity = 'MIN_QUANTITY',
  QuantityUnit = 'QUANTITY_UNIT',
  Region = 'REGION',
  Specification = 'SPECIFICATION',
  StartDate = 'START_DATE',
  TradeFulfillmentType = 'TRADE_FULFILLMENT_TYPE',
  TradeStatus = 'TRADE_STATUS'
}

export enum MetalsTradeSortField {
  Counterparty = 'COUNTERPARTY',
  DeliveryPeriod = 'DELIVERY_PERIOD',
  Destination = 'DESTINATION',
  Material = 'MATERIAL',
  Name = 'NAME',
  Pk = 'PK',
  PrimaryContact = 'PRIMARY_CONTACT',
  Quantity = 'QUANTITY',
  Region = 'REGION',
  Specification = 'SPECIFICATION',
  Status = 'STATUS',
  TradeFulfillmentType = 'TRADE_FULFILLMENT_TYPE',
  TradeType = 'TRADE_TYPE'
}

export enum MonthlyPositionReportMonthResourceExportOrder {
  Date = 'DATE',
  InTransit = 'IN_TRANSIT',
  NetChange = 'NET_CHANGE',
  NetChangeIncludingInTransit = 'NET_CHANGE_INCLUDING_IN_TRANSIT',
  NetInventory = 'NET_INVENTORY',
  NetInventoryIncludingInTransit = 'NET_INVENTORY_INCLUDING_IN_TRANSIT',
  Production = 'PRODUCTION',
  Purchases = 'PURCHASES',
  Sales = 'SALES',
  StartingInventory = 'STARTING_INVENTORY',
  TransfersInbound = 'TRANSFERS_INBOUND',
  TransfersOutbound = 'TRANSFERS_OUTBOUND',
  TransfersTotal = 'TRANSFERS_TOTAL'
}

export enum NcmCmdtyContractContractTypeChoices {
  Tc = 'TC'
}

export enum NcmCommodityIndexPriceTypeChoices {
  Exc = 'EXC',
  Ind = 'IND',
  Lme = 'LME'
}

export enum NcmIncotermCodeChoices {
  Cfr = 'CFR',
  Cif = 'CIF',
  Cip = 'CIP',
  Cpt = 'CPT',
  Dap = 'DAP',
  Dat = 'DAT',
  Ddp = 'DDP',
  Dpu = 'DPU',
  Exw = 'EXW',
  Fas = 'FAS',
  Fca = 'FCA',
  Fob = 'FOB',
  For = 'FOR',
  Fot = 'FOT'
}

export enum NcmIncotermTitleTransferTriggerChoices {
  Del = 'DEL',
  Pay = 'PAY'
}

export enum NcmMaterialAttributeAttributeTypeChoices {
  Categorical = 'CATEGORICAL',
  Derived = 'DERIVED'
}

export enum NcmMaterialAttributeLegacyTypeChoices {
  Brand = 'BRAND',
  Grade = 'GRADE',
  Origin = 'ORIGIN',
  Shape = 'SHAPE'
}

export enum NcmScaleWeightUnitChoices {
  Lb = 'LB',
  Mt = 'MT'
}

export enum NotificationsNotificationDeliveryDeliveryStatusChoices {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Quarantine = 'QUARANTINE',
  Success = 'SUCCESS'
}

export enum OffsetUnit {
  BusinessDay = 'BUSINESS_DAY',
  CalendarDay = 'CALENDAR_DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum Optionality {
  Buy = 'BUY',
  Sell = 'SELL'
}

export enum OrderDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum OrderManagerPeriodOrderingField {
  AllocatedLb = 'ALLOCATED_LB',
  AllocatedMt = 'ALLOCATED_MT',
  AllocatedNumShipments = 'ALLOCATED_NUM_SHIPMENTS',
  BookedLb = 'BOOKED_LB',
  BookedMt = 'BOOKED_MT',
  BookedNumShipments = 'BOOKED_NUM_SHIPMENTS',
  BuyerPk = 'BUYER_PK',
  ContractQuantityUnit = 'CONTRACT_QUANTITY_UNIT',
  DeliveredLb = 'DELIVERED_LB',
  DeliveredMt = 'DELIVERED_MT',
  DeliveredNumShipments = 'DELIVERED_NUM_SHIPMENTS',
  InTransitLb = 'IN_TRANSIT_LB',
  InTransitMt = 'IN_TRANSIT_MT',
  InTransitNumShipments = 'IN_TRANSIT_NUM_SHIPMENTS',
  Locations = 'LOCATIONS',
  Pk = 'PK',
  QuotaLb = 'QUOTA_LB',
  QuotaMaxLb = 'QUOTA_MAX_LB',
  QuotaMaxMt = 'QUOTA_MAX_MT',
  QuotaMinLb = 'QUOTA_MIN_LB',
  QuotaMinMt = 'QUOTA_MIN_MT',
  QuotaMt = 'QUOTA_MT',
  ReceivedLb = 'RECEIVED_LB',
  ReceivedMt = 'RECEIVED_MT',
  ReceivedNumShipments = 'RECEIVED_NUM_SHIPMENTS',
  SellerPk = 'SELLER_PK',
  ShipperPk = 'SHIPPER_PK',
  StartDate = 'START_DATE',
  TradeFulfillmentType = 'TRADE_FULFILLMENT_TYPE',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradePk = 'TRADE_PK',
  TradeUnderTolerance = 'TRADE_UNDER_TOLERANCE'
}

export enum OrderSyncStatus {
  Failed = 'FAILED',
  Manual = 'MANUAL',
  PartialSync = 'PARTIAL_SYNC',
  Pending = 'PENDING',
  PendingRequest = 'PENDING_REQUEST',
  Synced = 'SYNCED',
  Unknown = 'UNKNOWN',
  Unsynced = 'UNSYNCED'
}

export enum OrderType {
  Consignment = 'CONSIGNMENT',
  Sto = 'STO',
  Trade = 'TRADE'
}

export enum OrderingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Ordinal {
  First = 'FIRST',
  FirstWeds = 'FIRST_WEDS',
  Last = 'LAST',
  LmeDecember = 'LME_DECEMBER',
  MonBeforeThirdWeds = 'MON_BEFORE_THIRD_WEDS',
  NthDay = 'NTH_DAY',
  ThirdWeds = 'THIRD_WEDS'
}

export enum PasswordErrorCodes {
  AllNumbers = 'ALL_NUMBERS',
  ContainsBannedWord = 'CONTAINS_BANNED_WORD',
  Expired = 'EXPIRED',
  TooCommon = 'TOO_COMMON',
  TooRecent = 'TOO_RECENT',
  TooShort = 'TOO_SHORT',
  TooSimilar = 'TOO_SIMILAR',
  TooSimple = 'TOO_SIMPLE',
  Unknown = 'UNKNOWN'
}

export enum PaymentEventTrigger {
  AfterArrivalPortDest = 'AFTER_ARRIVAL_PORT_DEST',
  AfterDateOfPickup = 'AFTER_DATE_OF_PICKUP',
  AfterDateOfShipment = 'AFTER_DATE_OF_SHIPMENT',
  AfterDel = 'AFTER_DEL',
  AfterDocsPresented = 'AFTER_DOCS_PRESENTED',
  AfterRelease = 'AFTER_RELEASE',
  BeforeArrivalPortDest = 'BEFORE_ARRIVAL_PORT_DEST',
  BeforeDateOfPickup = 'BEFORE_DATE_OF_PICKUP',
  BeforeDateOfShipment = 'BEFORE_DATE_OF_SHIPMENT',
  BeforeDel = 'BEFORE_DEL',
  BeforeEstimatedArrivalDate = 'BEFORE_ESTIMATED_ARRIVAL_DATE',
  BeforeRelease = 'BEFORE_RELEASE',
  Date = 'DATE',
  Del = 'DEL'
}

export enum PaymentType {
  Final = 'FINAL',
  Provisional = 'PROVISIONAL'
}

export enum Period {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR'
}

export enum PermissionCode {
  ErpAction = 'ERP_ACTION',
  IntegrationPipelineJobs = 'INTEGRATION_PIPELINE_JOBS',
  IntegrationPipelineTranslations = 'INTEGRATION_PIPELINE_TRANSLATIONS',
  InventoryAccounts = 'INVENTORY_ACCOUNTS',
  InventoryActivity = 'INVENTORY_ACTIVITY',
  InventoryRelease = 'INVENTORY_RELEASE',
  InventoryTransfer = 'INVENTORY_TRANSFER',
  MaterialSpecification = 'MATERIAL_SPECIFICATION',
  MonthlyPosition = 'MONTHLY_POSITION',
  OrderIntegration = 'ORDER_INTEGRATION',
  OrgAdminChangelog = 'ORG_ADMIN_CHANGELOG',
  OrgAdminCounterparties = 'ORG_ADMIN_COUNTERPARTIES',
  OrgAdminCounterpartyIntegration = 'ORG_ADMIN_COUNTERPARTY_INTEGRATION',
  OrgAdminLocationDefaults = 'ORG_ADMIN_LOCATION_DEFAULTS',
  OrgAdminLocationDelivery = 'ORG_ADMIN_LOCATION_DELIVERY',
  OrgAdminLocationIntegration = 'ORG_ADMIN_LOCATION_INTEGRATION',
  OrgAdminLocationStructure = 'ORG_ADMIN_LOCATION_STRUCTURE',
  OrgAdminMaterialIntegration = 'ORG_ADMIN_MATERIAL_INTEGRATION',
  OrgAdminProfile = 'ORG_ADMIN_PROFILE',
  OrgAdminRegionDefaults = 'ORG_ADMIN_REGION_DEFAULTS',
  OrgAdminUsers = 'ORG_ADMIN_USERS',
  OrgAdminUserIntegration = 'ORG_ADMIN_USER_INTEGRATION',
  PurchaseOrder = 'PURCHASE_ORDER',
  ReceivingAppointment = 'RECEIVING_APPOINTMENT',
  ReceivingDelete = 'RECEIVING_DELETE',
  ReceivingFinalize = 'RECEIVING_FINALIZE',
  ReceivingInitiate = 'RECEIVING_INITIATE',
  StockNFlow = 'STOCK_N_FLOW',
  TradeConfirm = 'TRADE_CONFIRM',
  TradeDeclarations = 'TRADE_DECLARATIONS',
  TradeDelivery = 'TRADE_DELIVERY',
  TradeDraft = 'TRADE_DRAFT',
  TradeInsights = 'TRADE_INSIGHTS',
  TradeInsightsArrivalDateVsQuota = 'TRADE_INSIGHTS_ARRIVAL_DATE_VS_QUOTA',
  TradeInsightsDeliveryCadence = 'TRADE_INSIGHTS_DELIVERY_CADENCE',
  TradeInsightsMaterialBreakdown = 'TRADE_INSIGHTS_MATERIAL_BREAKDOWN',
  TradeInsightsOtif = 'TRADE_INSIGHTS_OTIF',
  TradeInsightsQuotaFulfillment = 'TRADE_INSIGHTS_QUOTA_FULFILLMENT',
  TradeInsightsTransitTime = 'TRADE_INSIGHTS_TRANSIT_TIME',
  TradeIntegration = 'TRADE_INTEGRATION',
  TradePricing = 'TRADE_PRICING',
  TradeSchedule = 'TRADE_SCHEDULE'
}

export enum Phase {
  Closed = 'CLOSED',
  Inactive = 'INACTIVE',
  Inflight = 'INFLIGHT',
  NotStarted = 'NOT_STARTED'
}

export enum PricingExpressionTag {
  Base = 'BASE',
  Premium = 'PREMIUM',
  PremiumModifier = 'PREMIUM_MODIFIER'
}

export enum PricingExpressionType {
  Call = 'CALL',
  Fixed = 'FIXED',
  Forward = 'FORWARD',
  Put = 'PUT'
}

export enum QpEvent {
  Del = 'DEL'
}

export enum QpType {
  Date = 'DATE',
  Declared = 'DECLARED',
  Event = 'EVENT',
  Month = 'MONTH',
  OptionMonths = 'OPTION_MONTHS',
  ThirdWeds = 'THIRD_WEDS'
}

export enum QuantityBound {
  Lower = 'Lower',
  Middle = 'Middle',
  Upper = 'Upper'
}

export enum QuantityUnit {
  Lb = 'LB',
  Mt = 'MT'
}

export enum QuantityUnitExtra {
  Lb = 'LB',
  LocationDefault = 'LocationDefault',
  Mt = 'MT',
  Operating = 'Operating',
  Trading = 'Trading',
  Unchanged = 'Unchanged'
}

export enum ReceivingDocumentType {
  Pack = 'PACK',
  Pcoa = 'PCOA',
  Tblc = 'TBLC',
  Unload = 'UNLOAD'
}

export enum ReceivingFileSizeType {
  B = 'B',
  Gb = 'GB',
  Kb = 'KB',
  Mb = 'MB'
}

export enum ReceivingModality {
  Async = 'ASYNC',
  Sync = 'SYNC'
}

export enum ReceivingStatus {
  Arrived = 'ARRIVED',
  Booked = 'BOOKED',
  Incomplete = 'INCOMPLETE',
  InProcess = 'IN_PROCESS',
  InTransit = 'IN_TRANSIT',
  NotCalculated = 'NOT_CALCULATED',
  PendingIdentifier = 'PENDING_IDENTIFIER',
  Received = 'RECEIVED',
  Rejected = 'REJECTED'
}

export enum ReleaseOrdersOrderingField {
  AccountBalancesLb = 'ACCOUNT_BALANCES_LB',
  AccountBalancesMt = 'ACCOUNT_BALANCES_MT',
  AccountHolderName = 'ACCOUNT_HOLDER_NAME',
  AccountHolderPhoto = 'ACCOUNT_HOLDER_PHOTO',
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountLocationOwnerName = 'ACCOUNT_LOCATION_OWNER_NAME',
  AccountLocationOwnerPhoto = 'ACCOUNT_LOCATION_OWNER_PHOTO',
  AccountLocationOwnerPk = 'ACCOUNT_LOCATION_OWNER_PK',
  AccountName = 'ACCOUNT_NAME',
  AccountPk = 'ACCOUNT_PK',
  FutureMonthSortKey = 'FUTURE_MONTH_SORT_KEY',
  HasStartDate = 'HAS_START_DATE',
  LocationName = 'LOCATION_NAME',
  LocationPk = 'LOCATION_PK',
  MaterialName = 'MATERIAL_NAME',
  MaterialSpecName = 'MATERIAL_SPEC_NAME',
  OrderPk = 'ORDER_PK',
  PastMonthSortKey = 'PAST_MONTH_SORT_KEY',
  Pk = 'PK',
  ReceivedLb = 'RECEIVED_LB',
  ReceivedMt = 'RECEIVED_MT',
  ReferenceNumber = 'REFERENCE_NUMBER',
  StartMonthInPast = 'START_MONTH_IN_PAST',
  SubquotaPk = 'SUBQUOTA_PK',
  TradeBuyerName = 'TRADE_BUYER_NAME',
  TradeBuyerPhoto = 'TRADE_BUYER_PHOTO',
  TradeBuyerPk = 'TRADE_BUYER_PK',
  TradeName = 'TRADE_NAME',
  TradePeriodStartDate = 'TRADE_PERIOD_START_DATE',
  TradePk = 'TRADE_PK',
  TradeQuotaLb = 'TRADE_QUOTA_LB',
  TradeQuotaMaxRemainingLb = 'TRADE_QUOTA_MAX_REMAINING_LB',
  TradeQuotaMaxRemainingMt = 'TRADE_QUOTA_MAX_REMAINING_MT',
  TradeQuotaMt = 'TRADE_QUOTA_MT',
  TradeQuotaRemainingLb = 'TRADE_QUOTA_REMAINING_LB',
  TradeQuotaRemainingMt = 'TRADE_QUOTA_REMAINING_MT',
  TradeSellerName = 'TRADE_SELLER_NAME',
  TradeSellerPhoto = 'TRADE_SELLER_PHOTO',
  TradeSellerPk = 'TRADE_SELLER_PK',
  TradeShipperName = 'TRADE_SHIPPER_NAME',
  TradeShipperPhoto = 'TRADE_SHIPPER_PHOTO',
  TradeShipperPk = 'TRADE_SHIPPER_PK'
}

export enum RoleVisibility {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

export enum ScheduleEvent {
  PostTransit = 'POST_TRANSIT',
  PreTransit = 'PRE_TRANSIT'
}

export enum ScheduleEventCode {
  Arrival = 'ARRIVAL',
  Delivery = 'DELIVERY',
  Loading = 'LOADING',
  Release = 'RELEASE',
  Ship = 'SHIP'
}

export enum ScheduleEventLocation {
  Carrier = 'CARRIER',
  Destination = 'DESTINATION',
  IncotermLocation = 'INCOTERM_LOCATION',
  Origin = 'ORIGIN',
  ScheduleLocation = 'SCHEDULE_LOCATION'
}

export enum ScheduleKind {
  ConsignmentTransfer = 'CONSIGNMENT_TRANSFER',
  Consumption = 'CONSUMPTION',
  PlantTransfer = 'PLANT_TRANSFER',
  Trade = 'TRADE',
  TransferOutFromConsignment = 'TRANSFER_OUT_FROM_CONSIGNMENT'
}

export enum ScheduleLineItemDayKind {
  Forecast = 'FORECAST',
  Sor = 'SOR'
}

export enum SchedulePoNumberOwner {
  Buyer = 'BUYER',
  Seller = 'SELLER',
  Shipper = 'SHIPPER'
}

export enum ScheduleRequiredInputLocation {
  Carrier = 'CARRIER',
  Destination = 'DESTINATION',
  Origin = 'ORIGIN',
  PortOfLoading = 'PORT_OF_LOADING'
}

export enum ScheduleRouteConveyanceChoices {
  OnSite = 'ON_SITE',
  Rail = 'RAIL',
  Truck = 'TRUCK',
  Vessel = 'VESSEL',
  WarehouseTransfer = 'WAREHOUSE_TRANSFER'
}

export enum ScheduleScheduleLineItemSpreadMethodChoices {
  ArriveEvenly = 'ARRIVE_EVENLY',
  Custom = 'CUSTOM'
}

export enum ScheduleSortField {
  Bol = 'BOL',
  Destination = 'DESTINATION',
  Material = 'MATERIAL',
  Origin = 'ORIGIN',
  Period = 'PERIOD',
  Po = 'PO',
  Status = 'STATUS'
}

export enum ScheduledIn {
  Quantity = 'QUANTITY',
  Shipments = 'SHIPMENTS'
}

export enum SchedulingPolicy {
  NotAllowed = 'NOT_ALLOWED',
  Weekly = 'WEEKLY'
}

export enum SchedulingStatus {
  NotStarted = 'NOT_STARTED',
  PendingDeclarations = 'PENDING_DECLARATIONS',
  Submitted = 'SUBMITTED'
}

export enum SchedulingType {
  BuyerSendsSchedule = 'BUYER_SENDS_SCHEDULE',
  ForecastOnly = 'FORECAST_ONLY'
}

export enum SchedulingVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export enum ShipType {
  Barge = 'BARGE',
  BreakBulk = 'BREAK_BULK',
  Container = 'CONTAINER'
}

export enum ShipmentCreationMethod {
  Appointment = 'APPOINTMENT',
  Asn = 'ASN',
  AutomatedConsignmentManagement = 'AUTOMATED_CONSIGNMENT_MANAGEMENT',
  BulkShipmentUpload = 'BULK_SHIPMENT_UPLOAD',
  CreatePage = 'CREATE_PAGE',
  Receiving = 'RECEIVING',
  Schedule = 'SCHEDULE'
}

export enum ShipmentOrdersOrderingField {
  AccountHolderName = 'ACCOUNT_HOLDER_NAME',
  AccountHolderPhoto = 'ACCOUNT_HOLDER_PHOTO',
  AccountHolderPk = 'ACCOUNT_HOLDER_PK',
  AccountLocationName = 'ACCOUNT_LOCATION_NAME',
  AccountLocationOwnerName = 'ACCOUNT_LOCATION_OWNER_NAME',
  AccountLocationOwnerPhoto = 'ACCOUNT_LOCATION_OWNER_PHOTO',
  AccountLocationOwnerPk = 'ACCOUNT_LOCATION_OWNER_PK',
  AccountName = 'ACCOUNT_NAME',
  AccountPk = 'ACCOUNT_PK',
  BuyerName = 'BUYER_NAME',
  BuyerPhoto = 'BUYER_PHOTO',
  BuyerPk = 'BUYER_PK',
  FutureMonthSortKey = 'FUTURE_MONTH_SORT_KEY',
  HasStartDate = 'HAS_START_DATE',
  MaterialName = 'MATERIAL_NAME',
  OrderPk = 'ORDER_PK',
  OrderType = 'ORDER_TYPE',
  PastMonthSortKey = 'PAST_MONTH_SORT_KEY',
  Pk = 'PK',
  ProductName = 'PRODUCT_NAME',
  ReferenceNumber = 'REFERENCE_NUMBER',
  ScheduleFromLocationOwnerName = 'SCHEDULE_FROM_LOCATION_OWNER_NAME',
  ScheduleFromLocationOwnerPhoto = 'SCHEDULE_FROM_LOCATION_OWNER_PHOTO',
  ScheduleFromLocationOwnerPk = 'SCHEDULE_FROM_LOCATION_OWNER_PK',
  ScheduleLineItemPk = 'SCHEDULE_LINE_ITEM_PK',
  ScheduleToLocationOwnerName = 'SCHEDULE_TO_LOCATION_OWNER_NAME',
  ScheduleToLocationOwnerPhoto = 'SCHEDULE_TO_LOCATION_OWNER_PHOTO',
  ScheduleToLocationOwnerPk = 'SCHEDULE_TO_LOCATION_OWNER_PK',
  SellerName = 'SELLER_NAME',
  SellerPhoto = 'SELLER_PHOTO',
  SellerPk = 'SELLER_PK',
  ShipperName = 'SHIPPER_NAME',
  ShipperPhoto = 'SHIPPER_PHOTO',
  ShipperPk = 'SHIPPER_PK',
  StartDate = 'START_DATE',
  StartMonthInPast = 'START_MONTH_IN_PAST',
  StoBolNumber = 'STO_BOL_NUMBER',
  StoVesselName = 'STO_VESSEL_NAME',
  TradePeriodPk = 'TRADE_PERIOD_PK',
  TradePk = 'TRADE_PK'
}

export enum ShipmentUpdateMethod {
  Manual = 'MANUAL'
}

export enum ShipmentUploadColumn {
  ArrivalDate = 'arrival_date',
  BillOfLading = 'bill_of_lading',
  BundleCount = 'bundle_count',
  BuyerReferenceId = 'buyer_reference_id',
  ConveyanceType = 'conveyance_type',
  GrossWeight = 'gross_weight',
  InboundBatchId = 'inbound_batch_id',
  Incoterm = 'incoterm',
  NetScaleWeight = 'net_scale_weight',
  NetWeight = 'net_weight',
  OrderType = 'order_type',
  OutboundBatchId = 'outbound_batch_id',
  ProductName = 'product_name',
  PurchaseOrder = 'purchase_order',
  RailCarNumber = 'rail_car_number',
  RailCarrierOrg = 'rail_carrier_org',
  RailWaybillNumber = 'rail_waybill_number',
  ReceiptDate = 'receipt_date',
  SalesOrder = 'sales_order',
  SealNumbers = 'seal_numbers',
  SellerReferenceId = 'seller_reference_id',
  ShipDate = 'ship_date',
  ShipFromLocation = 'ship_from_location',
  ShipFromOrg = 'ship_from_org',
  ShipFromOrgId = 'ship_from_org_id',
  ShipToLocation = 'ship_to_location',
  ShipToOrg = 'ship_to_org',
  ShipToOrgId = 'ship_to_org_id',
  ShipmentStatus = 'shipment_status',
  SoldToOrg = 'sold_to_org',
  TradePeriod = 'trade_period',
  TruckCarrierOrg = 'truck_carrier_org',
  TruckTractorNumber = 'truck_tractor_number',
  TruckTrailerNumber = 'truck_trailer_number',
  TruckTransportationProviderOrg = 'truck_transportation_provider_org',
  VesselCarrierOrg = 'vessel_carrier_org',
  VesselName = 'vessel_name',
  VesselNumberOfContainers = 'vessel_number_of_containers',
  VesselType = 'vessel_type',
  VesselVoyageNumber = 'vessel_voyage_number',
  WaybridgeBolLineItemId = 'waybridge_bol_line_item_id',
  WaybridgeShipmentId = 'waybridge_shipment_id',
  WaybridgeTradeId = 'waybridge_trade_id',
  WeightUom = 'weight_uom'
}

export enum ShipmentsListExportOrderInput {
  AccountHolderPks = 'ACCOUNT_HOLDER_PKS',
  AppointmentNumber = 'APPOINTMENT_NUMBER',
  ArrivalDate = 'ARRIVAL_DATE',
  ArrivalDatetime = 'ARRIVAL_DATETIME',
  ArrivalDateSource = 'ARRIVAL_DATE_SOURCE',
  AsnCarrierEta = 'ASN_CARRIER_ETA',
  AsnCarrierEtaUpdatedAt = 'ASN_CARRIER_ETA_UPDATED_AT',
  BolNumber = 'BOL_NUMBER',
  BolWeight = 'BOL_WEIGHT',
  BolWeightDiff = 'BOL_WEIGHT_DIFF',
  BolWeightDiffLb = 'BOL_WEIGHT_DIFF_LB',
  BolWeightDiffMt = 'BOL_WEIGHT_DIFF_MT',
  BolWeightDiffPercent = 'BOL_WEIGHT_DIFF_PERCENT',
  BolWeightDiffUnit = 'BOL_WEIGHT_DIFF_UNIT',
  BolWeightLb = 'BOL_WEIGHT_LB',
  BolWeightMt = 'BOL_WEIGHT_MT',
  BolWeightUnit = 'BOL_WEIGHT_UNIT',
  Bundles = 'BUNDLES',
  Buyer = 'BUYER',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  Carrier = 'CARRIER',
  CfCurrentLocation = 'CF_CURRENT_LOCATION',
  CfEta = 'CF_ETA',
  CfTrackingEta = 'CF_TRACKING_ETA',
  Co2EMt = 'CO2E_MT',
  Conveyance = 'CONVEYANCE',
  CoOrg = 'CO_ORG',
  CreatedAt = 'CREATED_AT',
  CurrentEta = 'CURRENT_ETA',
  DeliveryStatus = 'DELIVERY_STATUS',
  Destination = 'DESTINATION',
  DestinationOrgPk = 'DESTINATION_ORG_PK',
  DestinationPk = 'DESTINATION_PK',
  Etd = 'ETD',
  ExportDataBatchBundleCount = 'EXPORT_DATA_BATCH_BUNDLE_COUNT',
  ExportDataBatchCo2EMt = 'EXPORT_DATA_BATCH_CO2E_MT',
  ExportDataBatchNetScaleWeight = 'EXPORT_DATA_BATCH_NET_SCALE_WEIGHT',
  ExportDataBatchNetScaleWeightLb = 'EXPORT_DATA_BATCH_NET_SCALE_WEIGHT_LB',
  ExportDataBatchNetScaleWeightMt = 'EXPORT_DATA_BATCH_NET_SCALE_WEIGHT_MT',
  ExportDataBatchNetScaleWeightUnit = 'EXPORT_DATA_BATCH_NET_SCALE_WEIGHT_UNIT',
  ExportDataBatchProductName = 'EXPORT_DATA_BATCH_PRODUCT_NAME',
  ExportDataBatchProductValueNames = 'EXPORT_DATA_BATCH_PRODUCT_VALUE_NAMES',
  ExportDataBatchReleaseDate = 'EXPORT_DATA_BATCH_RELEASE_DATE',
  ExportDataBatchReleaseNumbers = 'EXPORT_DATA_BATCH_RELEASE_NUMBERS',
  ExportDataBatchWeight = 'EXPORT_DATA_BATCH_WEIGHT',
  ExportDataBatchWeightDiff = 'EXPORT_DATA_BATCH_WEIGHT_DIFF',
  ExportDataBatchWeightDiffLb = 'EXPORT_DATA_BATCH_WEIGHT_DIFF_LB',
  ExportDataBatchWeightDiffMt = 'EXPORT_DATA_BATCH_WEIGHT_DIFF_MT',
  ExportDataBatchWeightDiffPercent = 'EXPORT_DATA_BATCH_WEIGHT_DIFF_PERCENT',
  ExportDataBatchWeightDiffUnit = 'EXPORT_DATA_BATCH_WEIGHT_DIFF_UNIT',
  ExportDataBatchWeightLb = 'EXPORT_DATA_BATCH_WEIGHT_LB',
  ExportDataBatchWeightMt = 'EXPORT_DATA_BATCH_WEIGHT_MT',
  ExportDataBatchWeightUnit = 'EXPORT_DATA_BATCH_WEIGHT_UNIT',
  ExportDataBolLineItemId = 'EXPORT_DATA_BOL_LINE_ITEM_ID',
  ExportDataBrands = 'EXPORT_DATA_BRANDS',
  ExportDataInboundBatchId = 'EXPORT_DATA_INBOUND_BATCH_ID',
  ExportDataIncoterm = 'EXPORT_DATA_INCOTERM',
  ExportDataMaterialName = 'EXPORT_DATA_MATERIAL_NAME',
  ExportDataOrderNumber = 'EXPORT_DATA_ORDER_NUMBER',
  ExportDataOrderType = 'EXPORT_DATA_ORDER_TYPE',
  ExportDataOutboundBatchId = 'EXPORT_DATA_OUTBOUND_BATCH_ID',
  ExportDataQuotaMonthStartDate = 'EXPORT_DATA_QUOTA_MONTH_START_DATE',
  ExportDataSellerName = 'EXPORT_DATA_SELLER_NAME',
  ExportDataShipperName = 'EXPORT_DATA_SHIPPER_NAME',
  ExportDataStorageLocation = 'EXPORT_DATA_STORAGE_LOCATION',
  ExportDataToConsigneeName = 'EXPORT_DATA_TO_CONSIGNEE_NAME',
  ExportDataTradeId = 'EXPORT_DATA_TRADE_ID',
  FirstInboundBatchId = 'FIRST_INBOUND_BATCH_ID',
  FirstIncoterm = 'FIRST_INCOTERM',
  FirstMaterialName = 'FIRST_MATERIAL_NAME',
  FirstOutboundBatchId = 'FIRST_OUTBOUND_BATCH_ID',
  FirstProductName = 'FIRST_PRODUCT_NAME',
  FirstSellerName = 'FIRST_SELLER_NAME',
  FirstShipperName = 'FIRST_SHIPPER_NAME',
  FirstStorageLocation = 'FIRST_STORAGE_LOCATION',
  FirstToConsigneeName = 'FIRST_TO_CONSIGNEE_NAME',
  HasExceptions = 'HAS_EXCEPTIONS',
  Id = 'ID',
  InboundBatchIds = 'INBOUND_BATCH_IDS',
  Incoterms = 'INCOTERMS',
  InternalProductReferences = 'INTERNAL_PRODUCT_REFERENCES',
  IsArrived = 'IS_ARRIVED',
  IsTracked = 'IS_TRACKED',
  LocationTimezone = 'LOCATION_TIMEZONE',
  ManualEta = 'MANUAL_ETA',
  ManualEtaUpdatedAt = 'MANUAL_ETA_UPDATED_AT',
  MaterialNames = 'MATERIAL_NAMES',
  MaterialPks = 'MATERIAL_PKS',
  MaterialSpecNames = 'MATERIAL_SPEC_NAMES',
  NetScaleWeight = 'NET_SCALE_WEIGHT',
  NetScaleWeightLb = 'NET_SCALE_WEIGHT_LB',
  NetScaleWeightMt = 'NET_SCALE_WEIGHT_MT',
  OrderNumbers = 'ORDER_NUMBERS',
  OrderTypes = 'ORDER_TYPES',
  OriginLocationOrgPk = 'ORIGIN_LOCATION_ORG_PK',
  OriginLocationPk = 'ORIGIN_LOCATION_PK',
  OutboundBatchIds = 'OUTBOUND_BATCH_IDS',
  PredictiveEta = 'PREDICTIVE_ETA',
  ProductMavPks = 'PRODUCT_MAV_PKS',
  ProductNames = 'PRODUCT_NAMES',
  ProductPks = 'PRODUCT_PKS',
  QuotaMonths = 'QUOTA_MONTHS',
  QuotaMonthStartDates = 'QUOTA_MONTH_START_DATES',
  ReceivedAtDate = 'RECEIVED_AT_DATE',
  ReceivedAtDatetime = 'RECEIVED_AT_DATETIME',
  ReceivingDocumentCount = 'RECEIVING_DOCUMENT_COUNT',
  ReceivingDocumentFilenames = 'RECEIVING_DOCUMENT_FILENAMES',
  ReceivingStatus = 'RECEIVING_STATUS',
  ReceivingStatusSortKey = 'RECEIVING_STATUS_SORT_KEY',
  ReleaseDate = 'RELEASE_DATE',
  ReleaseNumbers = 'RELEASE_NUMBERS',
  ScaleWeightUnit = 'SCALE_WEIGHT_UNIT',
  SealNumber = 'SEAL_NUMBER',
  SellerNames = 'SELLER_NAMES',
  SellerPks = 'SELLER_PKS',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ShipmentCreatorOrgPk = 'SHIPMENT_CREATOR_ORG_PK',
  ShipperNames = 'SHIPPER_NAMES',
  ShipperPks = 'SHIPPER_PKS',
  ShippingOriginLocationName = 'SHIPPING_ORIGIN_LOCATION_NAME',
  ShipDate = 'SHIP_DATE',
  ShipDateOrEtd = 'SHIP_DATE_OR_ETD',
  StorageLocations = 'STORAGE_LOCATIONS',
  SubmittedAtDate = 'SUBMITTED_AT_DATE',
  SubmittedAtDatetime = 'SUBMITTED_AT_DATETIME',
  SubquotaPks = 'SUBQUOTA_PKS',
  ToConsigneeNames = 'TO_CONSIGNEE_NAMES',
  ToConsigneePks = 'TO_CONSIGNEE_PKS',
  TrackingStatus = 'TRACKING_STATUS',
  TradeIds = 'TRADE_IDS',
  TradePeriodPks = 'TRADE_PERIOD_PKS',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtDate = 'UPDATED_AT_DATE',
  UploadedDocumentCodes = 'UPLOADED_DOCUMENT_CODES',
  VehicleInfo = 'VEHICLE_INFO'
}

export enum ShipmentsListOrderingField {
  AccountHolderPks = 'ACCOUNT_HOLDER_PKS',
  AppointmentNumber = 'APPOINTMENT_NUMBER',
  ArrivalDate = 'ARRIVAL_DATE',
  ArrivalDatetime = 'ARRIVAL_DATETIME',
  ArrivalDateSource = 'ARRIVAL_DATE_SOURCE',
  AsnCarrierEta = 'ASN_CARRIER_ETA',
  AsnCarrierEtaUpdatedAt = 'ASN_CARRIER_ETA_UPDATED_AT',
  BolNumber = 'BOL_NUMBER',
  BolWeight = 'BOL_WEIGHT',
  BolWeightDiff = 'BOL_WEIGHT_DIFF',
  BolWeightDiffLb = 'BOL_WEIGHT_DIFF_LB',
  BolWeightDiffMt = 'BOL_WEIGHT_DIFF_MT',
  BolWeightDiffPercent = 'BOL_WEIGHT_DIFF_PERCENT',
  BolWeightLb = 'BOL_WEIGHT_LB',
  BolWeightMt = 'BOL_WEIGHT_MT',
  BolWeightUnit = 'BOL_WEIGHT_UNIT',
  Bundles = 'BUNDLES',
  Buyer = 'BUYER',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  Carrier = 'CARRIER',
  CfCurrentLocation = 'CF_CURRENT_LOCATION',
  CfEta = 'CF_ETA',
  CfTrackingEta = 'CF_TRACKING_ETA',
  Co2EMt = 'CO2E_MT',
  Conveyance = 'CONVEYANCE',
  CoOrg = 'CO_ORG',
  CreatedAt = 'CREATED_AT',
  CurrentEta = 'CURRENT_ETA',
  DeliveryStatus = 'DELIVERY_STATUS',
  Destination = 'DESTINATION',
  DestinationOrgPk = 'DESTINATION_ORG_PK',
  DestinationPk = 'DESTINATION_PK',
  Eta = 'ETA',
  Etd = 'ETD',
  FirstInboundBatchId = 'FIRST_INBOUND_BATCH_ID',
  FirstIncoterm = 'FIRST_INCOTERM',
  FirstMaterialName = 'FIRST_MATERIAL_NAME',
  FirstOutboundBatchId = 'FIRST_OUTBOUND_BATCH_ID',
  FirstProductName = 'FIRST_PRODUCT_NAME',
  FirstSellerName = 'FIRST_SELLER_NAME',
  FirstShipperName = 'FIRST_SHIPPER_NAME',
  FirstStorageLocation = 'FIRST_STORAGE_LOCATION',
  FirstToConsigneeName = 'FIRST_TO_CONSIGNEE_NAME',
  HasExceptions = 'HAS_EXCEPTIONS',
  InboundBatchIds = 'INBOUND_BATCH_IDS',
  Incoterms = 'INCOTERMS',
  InternalProductReferences = 'INTERNAL_PRODUCT_REFERENCES',
  IsArrived = 'IS_ARRIVED',
  IsTracked = 'IS_TRACKED',
  LocationTimezone = 'LOCATION_TIMEZONE',
  ManualEta = 'MANUAL_ETA',
  ManualEtaUpdatedAt = 'MANUAL_ETA_UPDATED_AT',
  Materials = 'MATERIALS',
  MaterialNames = 'MATERIAL_NAMES',
  MaterialPks = 'MATERIAL_PKS',
  MaterialSpecNames = 'MATERIAL_SPEC_NAMES',
  NetScaleWeight = 'NET_SCALE_WEIGHT',
  NetScaleWeightLb = 'NET_SCALE_WEIGHT_LB',
  NetScaleWeightMt = 'NET_SCALE_WEIGHT_MT',
  OrderNumbers = 'ORDER_NUMBERS',
  OrderTypes = 'ORDER_TYPES',
  OriginLocationOrgPk = 'ORIGIN_LOCATION_ORG_PK',
  OriginLocationPk = 'ORIGIN_LOCATION_PK',
  OutboundBatchIds = 'OUTBOUND_BATCH_IDS',
  Pk = 'PK',
  PredictiveEta = 'PREDICTIVE_ETA',
  Products = 'PRODUCTS',
  ProductMavPks = 'PRODUCT_MAV_PKS',
  ProductNames = 'PRODUCT_NAMES',
  ProductPks = 'PRODUCT_PKS',
  QuotaMonthStartDates = 'QUOTA_MONTH_START_DATES',
  ReceivedAtDate = 'RECEIVED_AT_DATE',
  ReceivedAtDatetime = 'RECEIVED_AT_DATETIME',
  ReceivingDocumentCount = 'RECEIVING_DOCUMENT_COUNT',
  ReceivingDocumentFilenames = 'RECEIVING_DOCUMENT_FILENAMES',
  ReceivingStatus = 'RECEIVING_STATUS',
  ReceivingStatusSortKey = 'RECEIVING_STATUS_SORT_KEY',
  ReleaseDate = 'RELEASE_DATE',
  ReleaseNumbers = 'RELEASE_NUMBERS',
  ScaleWeightUnit = 'SCALE_WEIGHT_UNIT',
  SealNumber = 'SEAL_NUMBER',
  Sellers = 'SELLERS',
  SellerNames = 'SELLER_NAMES',
  SellerPks = 'SELLER_PKS',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ShipmentCreatorOrgPk = 'SHIPMENT_CREATOR_ORG_PK',
  Shippers = 'SHIPPERS',
  ShipperNames = 'SHIPPER_NAMES',
  ShipperPks = 'SHIPPER_PKS',
  ShippingOriginLocationName = 'SHIPPING_ORIGIN_LOCATION_NAME',
  ShipDate = 'SHIP_DATE',
  ShipDateOrEtd = 'SHIP_DATE_OR_ETD',
  StorageLocations = 'STORAGE_LOCATIONS',
  SubmittedAtDate = 'SUBMITTED_AT_DATE',
  SubmittedAtDatetime = 'SUBMITTED_AT_DATETIME',
  SubquotaPks = 'SUBQUOTA_PKS',
  ToConsignees = 'TO_CONSIGNEES',
  ToConsigneeNames = 'TO_CONSIGNEE_NAMES',
  ToConsigneePks = 'TO_CONSIGNEE_PKS',
  TrackingStatus = 'TRACKING_STATUS',
  TradeIds = 'TRADE_IDS',
  TradePeriodPks = 'TRADE_PERIOD_PKS',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtDate = 'UPDATED_AT_DATE',
  UploadedDocumentCodes = 'UPLOADED_DOCUMENT_CODES',
  VehicleInfo = 'VEHICLE_INFO'
}

export enum SpreadMethod {
  ArriveEvenly = 'ARRIVE_EVENLY',
  Custom = 'CUSTOM'
}

export enum TargetAccountType {
  Consignment = 'CONSIGNMENT',
  Sto = 'STO',
  Trade = 'TRADE'
}

export enum TimelineEvent {
  InGate = 'IN_GATE',
  LoadedOnVessel = 'LOADED_ON_VESSEL',
  OutGate = 'OUT_GATE',
  P44VesselAtPod = 'P44_VESSEL_AT_POD',
  P44VesselPlaceholder = 'P44_VESSEL_PLACEHOLDER',
  P44VesselPolArrived = 'P44_VESSEL_POL_ARRIVED',
  P44VesselPolDeparted = 'P44_VESSEL_POL_DEPARTED',
  P44VesselPolLoaded = 'P44_VESSEL_POL_LOADED',
  P44VesselTransshipmentPort = 'P44_VESSEL_TRANSSHIPMENT_PORT',
  P44VesselTspArrived = 'P44_VESSEL_TSP_ARRIVED',
  P44VesselTspDeparted = 'P44_VESSEL_TSP_DEPARTED',
  RailArrived = 'RAIL_ARRIVED',
  RailAtStop = 'RAIL_AT_STOP',
  RailCompleted = 'RAIL_COMPLETED',
  RailDepartedStop = 'RAIL_DEPARTED_STOP',
  RailDeramped = 'RAIL_DERAMPED',
  RailDispatched = 'RAIL_DISPATCHED',
  RailInGate = 'RAIL_IN_GATE',
  RailInMotion = 'RAIL_IN_MOTION',
  RailLoad = 'RAIL_LOAD',
  RailUnload = 'RAIL_UNLOAD',
  SpireAtAnchor = 'SPIRE_AT_ANCHOR',
  SpireMoored = 'SPIRE_MOORED',
  SpireUnderWay = 'SPIRE_UNDER_WAY',
  TruckAtStop = 'TRUCK_AT_STOP',
  TruckCompleted = 'TRUCK_COMPLETED',
  TruckDispatched = 'TRUCK_DISPATCHED',
  TruckInfo = 'TRUCK_INFO',
  TruckInMotion = 'TRUCK_IN_MOTION',
  TruckInTransit = 'TRUCK_IN_TRANSIT',
  TruckScheduled = 'TRUCK_SCHEDULED',
  UnloadedFromVessel = 'UNLOADED_FROM_VESSEL',
  VesselArrival = 'VESSEL_ARRIVAL',
  VesselDeparture = 'VESSEL_DEPARTURE',
  VesselInTransit = 'VESSEL_IN_TRANSIT'
}

export enum TitleTransferTrigger {
  Del = 'DEL',
  Pay = 'PAY'
}

export enum TrackedShipmentIntegrationType {
  CargoGoVessel = 'CARGO_GO_VESSEL',
  MtVessel = 'MT_VESSEL',
  P44Rail = 'P44_RAIL',
  P44Truck = 'P44_TRUCK',
  P44Vessel = 'P44_VESSEL',
  SpireVessel = 'SPIRE_VESSEL'
}

export enum TrackingBolField {
  BolNumber = 'BOL_NUMBER',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  SellerReferenceId = 'SELLER_REFERENCE_ID'
}

export enum TrackingDeliveryTimelineModelEventStatusChoices {
  ArrivedAtInterchange = 'ARRIVED_AT_INTERCHANGE',
  ArrivedAtPort = 'ARRIVED_AT_PORT',
  ArrivedAtStop = 'ARRIVED_AT_STOP',
  AtPort = 'AT_PORT',
  AtTransshipmentPort = 'AT_TRANSSHIPMENT_PORT',
  ContainerDischargedAtTsp_1 = 'CONTAINER_DISCHARGED_AT_TSP_1',
  ContainerDischargedAtTsp_2 = 'CONTAINER_DISCHARGED_AT_TSP_2',
  ContainerDischargedAtTsp_3 = 'CONTAINER_DISCHARGED_AT_TSP_3',
  ContainerDischargedAtTsp_4 = 'CONTAINER_DISCHARGED_AT_TSP_4',
  ContainerLoadedAtPortOfLoading = 'CONTAINER_LOADED_AT_PORT_OF_LOADING',
  ContainerLoadedAtTsp_1 = 'CONTAINER_LOADED_AT_TSP_1',
  ContainerLoadedAtTsp_2 = 'CONTAINER_LOADED_AT_TSP_2',
  ContainerLoadedAtTsp_3 = 'CONTAINER_LOADED_AT_TSP_3',
  ContainerLoadedAtTsp_4 = 'CONTAINER_LOADED_AT_TSP_4',
  DepartedFromPort = 'DEPARTED_FROM_PORT',
  DepartedFromStop = 'DEPARTED_FROM_STOP',
  DepartureFromPortOfLoading = 'DEPARTURE_FROM_PORT_OF_LOADING',
  Destination = 'DESTINATION',
  InTransit = 'IN_TRANSIT',
  Origin = 'ORIGIN',
  PortOfDischarge = 'PORT_OF_DISCHARGE',
  PortOfLoading = 'PORT_OF_LOADING',
  WaitingToBeDischarged = 'WAITING_TO_BE_DISCHARGED',
  WaitingToBeLoaded = 'WAITING_TO_BE_LOADED'
}

export enum TrackingProject44IntegrationTpIdentifierTypeChoices {
  DotNumber = 'DOT_NUMBER',
  McNumber = 'MC_NUMBER',
  P44Eu = 'P44_EU',
  P44Global = 'P44_GLOBAL',
  Scac = 'SCAC',
  System = 'SYSTEM'
}

export enum TrackingProject44IntegrationTpIntegrationTypeChoices {
  Api = 'API',
  Eld = 'ELD'
}

export enum TradeCommentType {
  DeliveryTerms = 'DELIVERY_TERMS',
  ExtraNote = 'EXTRA_NOTE',
  Pricing = 'PRICING',
  ProvisionalPayment = 'PROVISIONAL_PAYMENT'
}

export enum TradeFulfillmentType {
  Consignment = 'CONSIGNMENT',
  Standard = 'STANDARD'
}

export enum TradeInvoiceType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Invoice = 'INVOICE',
  Payment = 'PAYMENT'
}

export enum TradeManagerV2ExportOrderInput {
  AllSubquotaIncotermCodes = 'ALL_SUBQUOTA_INCOTERM_CODES',
  AllSubquotaLocationNames = 'ALL_SUBQUOTA_LOCATION_NAMES',
  AllSubquotaLocationPks = 'ALL_SUBQUOTA_LOCATION_PKS',
  AllSubquotaMaterialSpecNames = 'ALL_SUBQUOTA_MATERIAL_SPEC_NAMES',
  AllSubquotaMaterialSpecNamesString = 'ALL_SUBQUOTA_MATERIAL_SPEC_NAMES_STRING',
  AllSubquotaMaterialSpecPks = 'ALL_SUBQUOTA_MATERIAL_SPEC_PKS',
  BuyerDeclarationDue = 'BUYER_DECLARATION_DUE',
  BuyerHasErp = 'BUYER_HAS_ERP',
  BuyerName = 'BUYER_NAME',
  BuyerPk = 'BUYER_PK',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  CommodityName = 'COMMODITY_NAME',
  CommodityPk = 'COMMODITY_PK',
  ConsignmentAccountPk = 'CONSIGNMENT_ACCOUNT_PK',
  EndDate = 'END_DATE',
  ExportDataAllocatedQuantityLb = 'EXPORT_DATA_ALLOCATED_QUANTITY_LB',
  ExportDataAllocatedQuantityMt = 'EXPORT_DATA_ALLOCATED_QUANTITY_MT',
  ExportDataBookedQuantityLb = 'EXPORT_DATA_BOOKED_QUANTITY_LB',
  ExportDataBookedQuantityMt = 'EXPORT_DATA_BOOKED_QUANTITY_MT',
  ExportDataBuyerReferenceId = 'EXPORT_DATA_BUYER_REFERENCE_ID',
  ExportDataDeclaredQuantity = 'EXPORT_DATA_DECLARED_QUANTITY',
  ExportDataDeclaredQuantityLb = 'EXPORT_DATA_DECLARED_QUANTITY_LB',
  ExportDataDeclaredQuantityMt = 'EXPORT_DATA_DECLARED_QUANTITY_MT',
  ExportDataDeclaredQuantityUnit = 'EXPORT_DATA_DECLARED_QUANTITY_UNIT',
  ExportDataDeliveredQuantityLb = 'EXPORT_DATA_DELIVERED_QUANTITY_LB',
  ExportDataDeliveredQuantityMt = 'EXPORT_DATA_DELIVERED_QUANTITY_MT',
  ExportDataIncotermCode = 'EXPORT_DATA_INCOTERM_CODE',
  ExportDataInTransitQuantityLb = 'EXPORT_DATA_IN_TRANSIT_QUANTITY_LB',
  ExportDataInTransitQuantityMt = 'EXPORT_DATA_IN_TRANSIT_QUANTITY_MT',
  ExportDataLocationName = 'EXPORT_DATA_LOCATION_NAME',
  ExportDataLocationPk = 'EXPORT_DATA_LOCATION_PK',
  ExportDataMaterialName = 'EXPORT_DATA_MATERIAL_NAME',
  ExportDataMaterialPk = 'EXPORT_DATA_MATERIAL_PK',
  ExportDataMonthlyOrderNumbers = 'EXPORT_DATA_MONTHLY_ORDER_NUMBERS',
  ExportDataMonthlyOrderSoNumbers = 'EXPORT_DATA_MONTHLY_ORDER_SO_NUMBERS',
  ExportDataNumAllocatedShipments = 'EXPORT_DATA_NUM_ALLOCATED_SHIPMENTS',
  ExportDataNumBookedShipments = 'EXPORT_DATA_NUM_BOOKED_SHIPMENTS',
  ExportDataNumDeliveredShipments = 'EXPORT_DATA_NUM_DELIVERED_SHIPMENTS',
  ExportDataNumInTransitShipments = 'EXPORT_DATA_NUM_IN_TRANSIT_SHIPMENTS',
  ExportDataNumReceivedShipments = 'EXPORT_DATA_NUM_RECEIVED_SHIPMENTS',
  ExportDataReceivedQuantityLb = 'EXPORT_DATA_RECEIVED_QUANTITY_LB',
  ExportDataReceivedQuantityMt = 'EXPORT_DATA_RECEIVED_QUANTITY_MT',
  ExportDataRegionName = 'EXPORT_DATA_REGION_NAME',
  ExportDataRemainingQuantityLb = 'EXPORT_DATA_REMAINING_QUANTITY_LB',
  ExportDataRemainingQuantityMt = 'EXPORT_DATA_REMAINING_QUANTITY_MT',
  ExportDataSchedulePk = 'EXPORT_DATA_SCHEDULE_PK',
  ExportDataScheduleStatus = 'EXPORT_DATA_SCHEDULE_STATUS',
  ExportDataScheduleUpdatedAt = 'EXPORT_DATA_SCHEDULE_UPDATED_AT',
  ExportDataSellerReferenceId = 'EXPORT_DATA_SELLER_REFERENCE_ID',
  ExportDataSubquotaDeclarationStatus = 'EXPORT_DATA_SUBQUOTA_DECLARATION_STATUS',
  Id = 'ID',
  InternalContractNumber = 'INTERNAL_CONTRACT_NUMBER',
  MonthlyOrderNumbers = 'MONTHLY_ORDER_NUMBERS',
  MonthlyOrderSoNumbers = 'MONTHLY_ORDER_SO_NUMBERS',
  NumBuyerSchedulesDue = 'NUM_BUYER_SCHEDULES_DUE',
  NumMissingOrderNumbers = 'NUM_MISSING_ORDER_NUMBERS',
  NumOpenOrders = 'NUM_OPEN_ORDERS',
  NumSellerSchedulesDue = 'NUM_SELLER_SCHEDULES_DUE',
  PendingSubquotas = 'PENDING_SUBQUOTAS',
  PeriodDeclarationStatus = 'PERIOD_DECLARATION_STATUS',
  QuantityUnit = 'QUANTITY_UNIT',
  RegionName = 'REGION_NAME',
  SellerDeclarationDue = 'SELLER_DECLARATION_DUE',
  SellerName = 'SELLER_NAME',
  SellerPk = 'SELLER_PK',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ShipperName = 'SHIPPER_NAME',
  ShipperPk = 'SHIPPER_PK',
  StartDate = 'START_DATE',
  TradeFulfillmentType = 'TRADE_FULFILLMENT_TYPE',
  TradeName = 'TRADE_NAME',
  TradePeriod = 'TRADE_PERIOD',
  TradePk = 'TRADE_PK',
  TradeStatus = 'TRADE_STATUS',
  TradeStatusNew = 'TRADE_STATUS_NEW'
}

export enum TradeManagerV2OrderingField {
  AllSubquotaIncotermCodes = 'ALL_SUBQUOTA_INCOTERM_CODES',
  AllSubquotaLocationNames = 'ALL_SUBQUOTA_LOCATION_NAMES',
  AllSubquotaLocationPks = 'ALL_SUBQUOTA_LOCATION_PKS',
  AllSubquotaMaterialSpecNames = 'ALL_SUBQUOTA_MATERIAL_SPEC_NAMES',
  AllSubquotaMaterialSpecNamesString = 'ALL_SUBQUOTA_MATERIAL_SPEC_NAMES_STRING',
  AllSubquotaMaterialSpecPks = 'ALL_SUBQUOTA_MATERIAL_SPEC_PKS',
  BuyerDeclarationDue = 'BUYER_DECLARATION_DUE',
  BuyerHasErp = 'BUYER_HAS_ERP',
  BuyerName = 'BUYER_NAME',
  BuyerPhoto = 'BUYER_PHOTO',
  BuyerPk = 'BUYER_PK',
  BuyerReferenceId = 'BUYER_REFERENCE_ID',
  CommodityName = 'COMMODITY_NAME',
  CommodityPk = 'COMMODITY_PK',
  ConsignmentAccountPk = 'CONSIGNMENT_ACCOUNT_PK',
  DeliveryTerms = 'DELIVERY_TERMS',
  Destinations = 'DESTINATIONS',
  EndDate = 'END_DATE',
  MaterialSpecs = 'MATERIAL_SPECS',
  MonthlyOrderNumbers = 'MONTHLY_ORDER_NUMBERS',
  MonthlyOrderSoNumbers = 'MONTHLY_ORDER_SO_NUMBERS',
  NumBuyerSchedulesDue = 'NUM_BUYER_SCHEDULES_DUE',
  NumMissingOrderNumbers = 'NUM_MISSING_ORDER_NUMBERS',
  NumOpenOrders = 'NUM_OPEN_ORDERS',
  NumSellerSchedulesDue = 'NUM_SELLER_SCHEDULES_DUE',
  PendingSubquotas = 'PENDING_SUBQUOTAS',
  PeriodDeclarationStatus = 'PERIOD_DECLARATION_STATUS',
  Pk = 'PK',
  QuantityUnit = 'QUANTITY_UNIT',
  RegionName = 'REGION_NAME',
  SellerDeclarationDue = 'SELLER_DECLARATION_DUE',
  SellerName = 'SELLER_NAME',
  SellerPhoto = 'SELLER_PHOTO',
  SellerPk = 'SELLER_PK',
  SellerReferenceId = 'SELLER_REFERENCE_ID',
  ShipperName = 'SHIPPER_NAME',
  ShipperPhoto = 'SHIPPER_PHOTO',
  ShipperPk = 'SHIPPER_PK',
  StartDate = 'START_DATE',
  SubquotaInfo = 'SUBQUOTA_INFO',
  TradeFulfillmentType = 'TRADE_FULFILLMENT_TYPE',
  TradeName = 'TRADE_NAME',
  TradePeriod = 'TRADE_PERIOD',
  TradePk = 'TRADE_PK',
  TradeStatus = 'TRADE_STATUS',
  TradeStatusNew = 'TRADE_STATUS_NEW'
}

export enum TradeStatus {
  C = 'C',
  Dr = 'DR',
  L = 'L'
}

export enum TradeStatusNew {
  Closed = 'Closed',
  Confirmed = 'Confirmed',
  Draft = 'Draft',
  Live = 'Live'
}

export enum TradeType {
  Buy = 'BUY',
  Finance = 'FINANCE',
  Sell = 'SELL'
}

export enum TradingCalendar {
  Cmes = 'CMES',
  EventPricingCalendar = 'EVENT_PRICING_CALENDAR',
  Fastmarketslc = 'FASTMARKETSLC',
  Lme = 'LME',
  Lse = 'LSE',
  Nyfed = 'NYFED',
  Nyse = 'NYSE',
  PaymentCalendar = 'PAYMENT_CALENDAR',
  Xcbf = 'XCBF',
  Xlon = 'XLON'
}

export enum TransferStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Late = 'LATE',
  Open = 'OPEN'
}

export enum UserRoleScopeType {
  Location = 'LOCATION',
  Organization = 'ORGANIZATION',
  Region = 'REGION'
}

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum WeightGovernedBy {
  Buyer = 'BUYER',
  Seller = 'SELLER'
}
