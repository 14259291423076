import {createContext, useContext, useEffect, useMemo, useState} from 'react'
import {PreloadedQuery, useFragment, usePreloadedQuery, useQueryLoader} from 'react-relay/hooks'

import {AuthAnalytics} from '@/analytics/auth'
import {navFragment} from '@/components/Nav/Nav.queries'
import type {NavData, NavDataKey} from '@/components/Nav/types'

import {
    PermissionAndNavProvider_organizationAdmin$data,
    PermissionAndNavProvider_organizationAdmin$key,
} from '@/__generated__/PermissionAndNavProvider_organizationAdmin.graphql'
import {PermissionAndNavProviderQuery} from '@/__generated__/PermissionAndNavProviderQuery.graphql'
import {PermissionCode} from '@/__generated__/types'

import {useAuth} from '../AuthProvider'
import {orgAdminFragment, permissionQuery} from './PermissionAndNavProvider.queries'
import {
    LocationPermission,
    Organization,
    OrganizationLocations,
    Permission,
    PermissionLevel,
    RegionPermission,
    Roles,
    UserPermission,
} from './types'

export enum PermissionRoute {
    Trades = 'trades',
    BookATrade = 'book-a-trade',
    Consignment = 'consignment',
    ConsignmentAddAccount = 'consignment-add-account',
    Scheduling = 'scheduling',
    Appointments = 'appointments',
    Shipments = 'shipments',
    Admin = 'admin',
    Integrations = 'integrations',
    PositionReports = 'position-reports',
    TradeInsights = 'trade-insights',
}

export const PermissionMap = new Map<PermissionRoute, PermissionCode[]>([
    [
        PermissionRoute.Trades,
        [
            PermissionCode.TradeDraft,
            PermissionCode.TradeConfirm,
            PermissionCode.TradeIntegration,
            PermissionCode.TradeDelivery,
            PermissionCode.TradeDeclarations,
            PermissionCode.TradeSchedule,
            PermissionCode.TradePricing,
        ],
    ],
    [PermissionRoute.BookATrade, [PermissionCode.TradeDraft]],
    [
        PermissionRoute.Consignment,
        [
            PermissionCode.InventoryActivity,
            PermissionCode.InventoryAccounts,
            PermissionCode.InventoryRelease,
            PermissionCode.InventoryTransfer,
        ],
    ],
    [PermissionRoute.ConsignmentAddAccount, [PermissionCode.InventoryAccounts]],
    [PermissionRoute.Scheduling, [PermissionCode.TradeSchedule]],
    [PermissionRoute.Appointments, [PermissionCode.ReceivingAppointment]],
    // We should ensure that everyone with PermissionCode.ReceivingFinalize also has PermissionCode.ReceivingInitiate,
    // so we only need to check for PermissionCode.ReceivingInitiate here since having ReceivingFinalize implies having ReceivingInitiate
    [PermissionRoute.Shipments, [PermissionCode.ReceivingInitiate]],
    [
        PermissionRoute.Admin,
        [
            PermissionCode.OrgAdminUsers,
            PermissionCode.OrgAdminUserIntegration,
            PermissionCode.OrgAdminRegionDefaults,
            PermissionCode.OrgAdminLocationStructure,
            PermissionCode.OrgAdminLocationDelivery,
            PermissionCode.OrgAdminLocationDefaults,
            PermissionCode.OrgAdminLocationIntegration,
            PermissionCode.OrgAdminMaterialIntegration,
            PermissionCode.OrgAdminCounterpartyIntegration,
        ],
    ],
    [PermissionRoute.PositionReports, [PermissionCode.StockNFlow, PermissionCode.MonthlyPosition]],
    [PermissionRoute.TradeInsights, [PermissionCode.TradeInsights]],
])

export interface BasicPermissionState {
    org?: Organization
    isPrincipal: boolean
    roleCodes: Set<string> // Codes that the user is assigned to
    locationPermissions: Map<number, Map<PermissionCode, UserPermission>>
    regionPermissions: Map<number, Map<PermissionCode, UserPermission>>
    orgPermissions: Map<PermissionCode, UserPermission>
    accessCache: Map<PermissionRoute, boolean>
    navData: NavData | null
    launchDarklyFlagsLoaded?: boolean
}

export type CanFn = (
    code: PermissionCode,
    locationPk?: number | null,
    regionPk?: number | null,
    log?: boolean,
) => boolean

export interface PermissionState {
    orgPk: number | undefined
    orgName: string | null | undefined
    erpName: string | null | undefined
    isPrincipal: boolean
    roleCodes: Set<string> // Codes that the user is assigned to
    locationPermissions: Map<number, Map<PermissionCode, UserPermission>>
    organizationLocations: OrganizationLocations
    regionPermissions: Map<number, Map<PermissionCode, UserPermission>>
    orgPermissions: Map<PermissionCode, UserPermission>
    accessCache: Map<PermissionRoute, boolean>
    canRead: CanFn
    canWrite: CanFn
    hasAccess: (route: PermissionRoute, log?: boolean) => boolean
    updatePermissions: (roles: Roles) => void
    navData: NavData | null

    launchDarklyFlagsLoaded: boolean
    setLaunchDarklyFlagsLoaded: (loaded?: boolean) => void
}

const PermissionContext = createContext<PermissionState | null>(null)

export type PermissionProps = BasicPermissionState & {
    counterparties?: PermissionAndNavProvider_organizationAdmin$data['counterpartyRelationships']
    permissionsEnabled: boolean
    children: React.ReactNode
}

export const InnerPermissionAndNavProvider = (props: PermissionProps) => {
    // eslint-disable-next-line react/destructuring-assignment -- FIXME
    const [locationPermissions, setLocationPermissions] = useState(props.locationPermissions)
    // eslint-disable-next-line react/destructuring-assignment -- FIXME
    const [regionPermissions, setRegionPermissions] = useState(props.regionPermissions)
    // eslint-disable-next-line react/destructuring-assignment -- FIXME
    const [orgPermissions, setOrgPermissions] = useState(props.orgPermissions)
    // eslint-disable-next-line react/destructuring-assignment -- FIXME
    const [launchDarklyFlagsLoaded, _setLaunchDarklyFlagsLoaded] = useState(props.launchDarklyFlagsLoaded ?? false)

    const {navData, org, counterparties, roleCodes} = props
    useEffect(() => {
        if (!navData?.user?.pk) {
            return
        }
        AuthAnalytics.login({
            pk: navData.user.pk,
            fullName: navData.user.fullName ?? '',
            email: navData.user.email ?? '',
            organizationName: org?.name ?? '',
            orgId: org?.pk ? `${org?.pk}` : '',
            counterparties: (counterparties || []).map(({counterparty}) => counterparty?.name ?? ''),
            roles: Array.from(roleCodes || []),
        })
    }, [navData, org, counterparties, roleCodes])
    function canRead(
        code: PermissionCode,
        locationPk?: number | null,
        regionPk?: number | null,
        log?: boolean,
    ): boolean {
        let result: boolean = false
        if (locationPk) {
            const level = locationPermissions.get(locationPk)?.get(code)?.level
            result = level ? level >= PermissionLevel.Read : false
            if (!result && log)
                // eslint-disable-next-line curly -- FIXME
                console.warn(`PERMISSION FAILURE: You do not have ${code} read permission for location ${locationPk}`)
        } else if (regionPk) {
            const level = regionPermissions.get(regionPk)?.get(code)?.level
            result = level ? level >= PermissionLevel.Read : false
            if (!result && log)
                // eslint-disable-next-line curly -- FIXME
                console.warn(`PERMISSION FAILURE: You do not have ${code} read permission for region ${regionPk}`)
        } else {
            const level = orgPermissions.get(code)?.level
            result = level ? level >= PermissionLevel.Read : false
            if (!result && log)
                // eslint-disable-next-line curly -- FIXME
                console.warn(`PERMISSION FAILURE: You do not have ${code} read permission for your organization`)
        }
        return result
    }

    function canWrite(
        code: PermissionCode,
        locationPk?: number | null,
        regionPk?: number | null,
        log?: boolean,
    ): boolean {
        let result: boolean = false
        if (locationPk) {
            // eslint-disable-next-line eqeqeq -- FIXME
            result = locationPermissions.get(locationPk)?.get(code)?.level == PermissionLevel.Write
            if (!result && log)
                // eslint-disable-next-line curly -- FIXME
                console.warn(`PERMISSION FAILURE: You do not have ${code} write permission for location ${locationPk}`)
        } else if (regionPk) {
            // eslint-disable-next-line eqeqeq -- FIXME
            result = regionPermissions.get(regionPk)?.get(code)?.level == PermissionLevel.Write
            if (!result && log)
                // eslint-disable-next-line curly -- FIXME
                console.warn(`PERMISSION FAILURE: You do not have ${code} write permission for region ${regionPk}`)
        } else {
            // eslint-disable-next-line eqeqeq -- FIXME
            result = orgPermissions.get(code)?.level == PermissionLevel.Write
            if (!result && log)
                // eslint-disable-next-line curly -- FIXME
                console.warn(`PERMISSION FAILURE: You do not have ${code} write permission for your organization`)
        }
        return result
    }

    function hasAccess(route: PermissionRoute, log?: boolean): boolean {
        // if the accessibility of the route is already cached, use the cached value
        // eslint-disable-next-line eqeqeq -- FIXME
        const write = route == PermissionRoute.ConsignmentAddAccount || route == PermissionRoute.BookATrade
        // eslint-disable-next-line eqeqeq -- FIXME
        const onlyCheckRegions = route == PermissionRoute.BookATrade

        // eslint-disable-next-line react/destructuring-assignment -- FIXME
        if (props.accessCache.has(route)) {
            // eslint-disable-next-line react/destructuring-assignment -- FIXME
            return props.accessCache.get(route) || false
        } else if (PermissionMap.has(route)) {
            // if the route has permissions attached to it, check those permissions for all locations in user's organization
            // eslint-disable-next-line react/destructuring-assignment -- FIXME
            const locationPks = props.org?.locations?.map((loc) => loc?.pk) || []
            // eslint-disable-next-line react/destructuring-assignment -- FIXME
            const regionPks = props.org?.regions?.map((reg) => reg?.pk) || []
            let forbidden = true
            const perms = PermissionMap.get(route)
            // eslint-disable-next-line eqeqeq -- FIXME
            if ((locationPks.length == 0 || onlyCheckRegions) && perms) {
                // eslint-disable-next-line eqeqeq -- FIXME
                if (regionPks.length == 0)
                    // if no locations or regions check org
                    // eslint-disable-next-line curly -- FIXME
                    forbidden = write ? perms?.every((perm) => !canWrite(perm)) : perms?.every((perm) => !canRead(perm))
                else {
                    // if no location check regions
                    for (let i = 0; i < regionPks.length; ++i) {
                        forbidden = write
                            ? perms?.every((perm) => !canWrite(perm, null, regionPks[i]))
                            : perms?.every((perm) => !canRead(perm, null, regionPks[i]))
                        // If the user doesn't have any of the permissions in perms, they are forbidden from viewing the content.
                        // eslint-disable-next-line curly -- FIXME
                        if (!forbidden) break
                    }
                }
            } else if (perms) {
                // check locations
                for (let i = 0; i < locationPks.length; ++i) {
                    forbidden = write
                        ? perms?.every((perm) => !canWrite(perm, locationPks[i], null))
                        : perms?.every((perm) => !canRead(perm, locationPks[i], null))
                    // If the user doesn't have any of the permissions in perms, they are forbidden from viewing the content.
                    // eslint-disable-next-line curly -- FIXME
                    if (!forbidden) break
                }
            }
            // eslint-disable-next-line react/destructuring-assignment -- FIXME
            props.accessCache.set(route, !forbidden)
            if (log && forbidden)
                // eslint-disable-next-line curly -- FIXME
                console.warn(
                    `PERMISSION FAILURE: You cannot access route ${route} because you do not have any of the following permissions at any region/location: ${
                        perms?.join(', ') ?? 'n/a'
                    }`,
                )
            return !forbidden
            // eslint-disable-next-line no-else-return -- FIXME
        } else {
            // if the route has no attached permissions, it should be set as accessible
            // eslint-disable-next-line react/destructuring-assignment -- FIXME
            props.accessCache.set(route, true)
            return true
        }
    }

    function updatePermissions(roles: Roles) {
        setLocationPermissions(new Map<number, Map<PermissionCode, UserPermission>>())
        setRegionPermissions(new Map<number, Map<PermissionCode, UserPermission>>())
        setOrgPermissions(new Map<PermissionCode, UserPermission>())

        const rolesLocationPermissions = roles?.locationPermissions ?? []
        const rolesRegionPermissions = roles?.regionPermissions ?? []
        const rolesOrgPermissions = roles?.orgPermissions ?? []

        rolesLocationPermissions.forEach((loc: Record<string, any>) => {
            const subMap = new Map<PermissionCode, UserPermission>()
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- FIXME
            loc.permissions.forEach((currentPerm: Record<string, any>) => {
                subMap.set(currentPerm.code as PermissionCode, currentPerm as UserPermission)
            })
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME
            setLocationPermissions(locationPermissions.set(loc.location.pk, subMap))
        })

        rolesRegionPermissions.forEach((reg: Record<string, any>) => {
            const subMap = new Map<PermissionCode, UserPermission>()
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- FIXME
            reg.permissions.forEach((currentPerm: Record<string, any>) => {
                subMap.set(currentPerm.code as PermissionCode, currentPerm as UserPermission)
            })
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME
            setRegionPermissions(regionPermissions.set(reg.region.pk, subMap))
        })

        rolesOrgPermissions.forEach((currentPerm: Record<string, any>) => {
            setOrgPermissions(orgPermissions.set(currentPerm.code as PermissionCode, currentPerm as UserPermission))
        })

        // eslint-disable-next-line react/destructuring-assignment -- FIXME
        props.accessCache.clear()
    }

    const setLaunchDarklyFlagsLoaded = (loaded: boolean = true) => {
        _setLaunchDarklyFlagsLoaded(loaded)
    }

    return (
        <PermissionContext.Provider
            value={{
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                orgPk: props.org?.pk,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                erpName: props.org?.erpName,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                orgName: props.org?.name,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                isPrincipal: props.isPrincipal,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                roleCodes: props.roleCodes,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                organizationLocations: props.org?.locations ?? [],
                locationPermissions,
                regionPermissions,
                orgPermissions,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                accessCache: props.accessCache,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                canRead: props.permissionsEnabled ? canRead : () => true,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                canWrite: props.permissionsEnabled ? canWrite : () => true,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                hasAccess: props.permissionsEnabled ? hasAccess : () => true,
                updatePermissions,
                // eslint-disable-next-line react/destructuring-assignment -- FIXME
                navData: props.navData,
                setLaunchDarklyFlagsLoaded,
                launchDarklyFlagsLoaded,
            }}>
            {/* eslint-disable-next-line react/destructuring-assignment -- FIXME */}
            {props.children}
        </PermissionContext.Provider>
    )
}

export function usePermissions(): PermissionState {
    const permissions: Optional<PermissionState> = useContext(PermissionContext) as PermissionState
    if (permissions === undefined) {
        throw new Error('usePermissions must be used within a PermissionAndNavProvider')
    }
    return permissions
}

const createRegionPermissions = (roles: Roles) => {
    const regionPermissions = new Map<number, Map<PermissionCode, UserPermission>>()

    roles?.regionPermissions?.forEach((reg: RegionPermission) => {
        if (reg?.region?.pk) {
            const subMap = new Map<PermissionCode, UserPermission>()
            reg.permissions?.forEach((currentPerm: Permission) => {
                subMap.set(currentPerm.code as PermissionCode, currentPerm as UserPermission)
            })
            regionPermissions.set(reg.region.pk, subMap)
        }
    })
    return regionPermissions
}

const createLocationPermissions = (roles: Roles) => {
    const locationPermissions = new Map<number, Map<PermissionCode, UserPermission>>()

    roles?.locationPermissions?.forEach((loc: LocationPermission) => {
        if (loc.location?.pk) {
            const subMap = new Map<PermissionCode, UserPermission>()
            loc.permissions?.forEach((currentPerm: Permission) => {
                subMap.set(currentPerm.code as PermissionCode, currentPerm as UserPermission)
            })
            locationPermissions.set(loc.location.pk, subMap)
        }
    })

    return locationPermissions
}

const PermissionAndNavProviderLoader = ({
    queryRef,
    children,
}: {
    queryRef: PreloadedQuery<PermissionAndNavProviderQuery>
    children: React.ReactNode
}) => {
    const auth = useAuth()

    const accessCache = useMemo(() => new Map<PermissionRoute, boolean>(), [])
    const data = usePreloadedQuery<PermissionAndNavProviderQuery>(permissionQuery, queryRef)
    const orgData = useFragment<PermissionAndNavProvider_organizationAdmin$key>(orgAdminFragment, data.myOrganization)
    const effectiveOrgData = auth.effectiveOrganization ? data.organizationByPk : orgData?.organization
    const roles = data?.meAdmin?.roles

    const navData = useFragment<NavDataKey>(navFragment, data.meAdmin)

    const locationPermissions = createLocationPermissions(roles as unknown as Roles)
    const regionPermissions = createRegionPermissions(roles as unknown as Roles)

    const orgMap = new Map<PermissionCode, UserPermission>()
    roles?.orgPermissions?.forEach((currentPerm) => {
        orgMap.set(currentPerm.code as PermissionCode, currentPerm as UserPermission)
    })

    // Enables us to make some basic decisions about routes/nav logic depending on the user's roles.
    const roleCodes: Set<string> = new Set()
    roles?.assignedRoles?.forEach((obj) => {
        roleCodes.add(obj.role.code)
    })

    return (
        <InnerPermissionAndNavProvider
            accessCache={accessCache}
            counterparties={orgData?.counterpartyRelationships || []}
            isPrincipal={orgData?.isPrincipal || false}
            locationPermissions={locationPermissions}
            navData={navData}
            org={effectiveOrgData}
            orgPermissions={orgMap}
            permissionsEnabled={true} // Test provider disables this by setting to false
            regionPermissions={regionPermissions}
            roleCodes={roleCodes}>
            {children}
        </InnerPermissionAndNavProvider>
    )
}

const PermissionAndNavProvider = ({children}: {children?: React.ReactNode}) => {
    const [permQuery, loadPermQuery] = useQueryLoader<PermissionAndNavProviderQuery>(permissionQuery)
    const {effectiveOrganization} = useAuth()

    useEffect(() => {
        loadPermQuery({pk: effectiveOrganization ? effectiveOrganization : null})
    }, [loadPermQuery, effectiveOrganization])

    return (
        (permQuery && (
            <PermissionAndNavProviderLoader queryRef={permQuery}>{children}</PermissionAndNavProviderLoader>
            // eslint-disable-next-line react/jsx-no-useless-fragment -- FIXME
        )) || <></>
    )
}

export default PermissionAndNavProvider
