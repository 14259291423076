import {Suspense} from 'react'

import {useTheme} from '@waybridge/wui'

import type {NavData} from '@/components/Nav/types'
import {useLaunchDarkly, useLogrocket, useSentry} from '@/components/Nav/utils'
import Responsive from '@/components/Responsive'
import {usePermissions} from '@/providers/PermissionAndNavProvider'

import DesktopNav from './Desktop'
import MobileNav from './Mobile'

import './Nav.less'

type NavWrapperProps = {
    data: NavData
}

const NavWrapper = ({data}: NavWrapperProps) => {
    useSentry(data)
    useLaunchDarkly(data)
    useLogrocket(data)

    // eslint-disable-next-line @typescript-eslint/no-use-before-define -- FIXME
    return <ResponsiveNav data={data} />
}

type ResponsiveNavProps = {
    data?: NavData | null
}

export const ResponsiveNav = ({data = null}: ResponsiveNavProps) => {
    const breakpoint = useTheme().breakpoints.values.lg

    return (
        <Responsive
            breakpoint={breakpoint}
            renderDesktop={() => <DesktopNav data={data} />}
            renderMobile={() => <MobileNav data={data} />}
        />
    )
}

const Nav = () => {
    const {navData} = usePermissions()
    if (!navData) {
        return <ResponsiveNav />
    }

    return <NavWrapper data={navData} />
}

const NavSuspense = () => (
    <Suspense fallback={<ResponsiveNav />}>
        <Nav />
    </Suspense>
)

export default NavSuspense
