import {ChangeEventHandler, HTMLAttributes, useEffect, useMemo, useRef, useState} from 'react'
import {useAtom} from 'jotai'
// eslint-disable-next-line waybridge/use-flags
import {useFlags as useLdFlags} from 'launchdarkly-react-client-sdk'
import {FormattedMessage} from 'react-intl'

import Box from '@waybridge/wui/Box'
import Button from '@waybridge/wui/Button'
import Chip from '@waybridge/wui/Chip'
import IconButton from '@waybridge/wui/IconButton'
import {CloseIcon, CopyIcon, RefreshIcon} from '@waybridge/wui/Icons'
import SearchField from '@waybridge/wui/SearchField'
import Stack from '@waybridge/wui/Stack'
import Switch from '@waybridge/wui/Switch'
import TextField from '@waybridge/wui/TextField'
import Typography from '@waybridge/wui/Typography'

import {FLAGS_SCOPE} from '@/hooks/useFlags'
import configValue from '@/utils/config'

import {flagLdStatistics, flagsAtom, usedFlagsAtom} from './atoms'

export const useUserFlagsSync = () => {
    const ldflags = useLdFlags()
    const [flags, setFlags] = useAtom(flagsAtom, FLAGS_SCOPE)

    useEffect(() => {
        if (!flags) {
            return
        }

        let shouldUpdate = false
        const nextState = Object.keys(flags).reduce((acc, name) => {
            if (flags[name] === ldflags[name]) {
                shouldUpdate = true
                return acc
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
            return {...acc, [name]: flags[name]}
        }, {})
        if (shouldUpdate) {
            setFlags(nextState)
        }
    }, [ldflags, flags, setFlags])
}

type UserFlagsPanelProps = {
    onClose: () => void
} & HTMLAttributes<HTMLDivElement>

const UserFlagsPanel = ({onClose, ...props}: UserFlagsPanelProps) => {
    const ldflags = useLdFlags()
    const [_flags, updateFlags] = useAtom(flagsAtom, FLAGS_SCOPE)
    const overwrittenFlags = useMemo(() => _flags ?? {}, [_flags])
    const computedFlags = useMemo(() => ({...ldflags, ...overwrittenFlags}), [ldflags, overwrittenFlags])
    const [usedFlags] = useAtom(usedFlagsAtom, FLAGS_SCOPE)
    const [flagStats, setLdFlagStatistics] = useAtom(flagLdStatistics)
    const inputRef = useRef<HTMLTextAreaElement>()

    useEffect(() => {
        const apiToken = configValue('LD_API_TOKEN')
        if (apiToken && Object.keys(flagStats).length === 0) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME
            ;(async () => {
                try {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
                    const resp = await fetch(`https://app.launchdarkly.com/api/v2/code-refs/statistics/waybridge`, {
                        headers: {authorization: apiToken},
                    }).then((r) => r.json())
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    if (typeof resp === 'object' && 'code' in resp && resp.code === 'unauthorized') {
                        return
                    }
                    setLdFlagStatistics(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME
                        Object.keys(resp.flags ?? {}).reduce((acc: Record<string, string[]>, key: string) => {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- FIXME
                            acc[key.toLowerCase()] = resp.flags[key].map((repo: {name: string}) => repo.name)
                            return acc
                        }, {}),
                    )
                    // eslint-disable-next-line no-empty -- FIXME
                } catch (e) {}
            })()
        }
    }, [flagStats, setLdFlagStatistics])

    const clearInput = () => {
        if (inputRef.current) {
            inputRef.current.value = ''
        }
    }
    const submitChanges = () => {
        if (inputRef.current) {
            const flagJson = inputRef.current.value
            updateFlags(JSON.parse(flagJson))
            clearInput()
        }
    }

    const copySettings = () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME
        navigator.clipboard.writeText(JSON.stringify(computedFlags, null, 2))
    }

    const resetSettings = () => {
        updateFlags({})
    }

    const [searchText, setSearchText] = useState('')

    const onSearch: ChangeEventHandler<HTMLInputElement> = (evt) => {
        const text = evt.target.value
        setSearchText(text)
    }

    const usedLookup = useMemo(
        () =>
            usedFlags.reduce((acc, name) => {
                acc[name] = true
                return acc
            }, {} as Record<string, boolean>),
        [usedFlags],
    )

    const filteredFlags = useMemo(() => {
        const flagList =
            searchText !== ''
                ? Object.keys(computedFlags).filter((name) => name.toLowerCase().includes(searchText.toLowerCase()))
                : Object.keys(computedFlags)

        return flagList.sort((a, b) => {
            const containsA = usedLookup[a]
            const containsB = usedLookup[b]
            if (containsB && !containsA) {
                return 1
            }
            if (containsA && !containsB) {
                return -1
            }
            return a.localeCompare(b)
        })
    }, [searchText, computedFlags, usedLookup])

    return (
        <Stack
            data-testid="user-flags-panel"
            direction="column"
            {...props}
            sx={{
                background: (theme) => theme.palette.white,
                boxShadow: (theme) => theme.shadows[10],
                zIndex: (theme) => theme.zIndex.drawer,
                overflow: 'auto',
            }}>
            <Stack direction="column" gap={1} p={2}>
                <div>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <SearchField fullWidth onChange={onSearch} placeholder="Search flags" variant="outlined" />
            </Stack>
            <Stack direction="column" flex={1} gap={1} overflow="auto" pb={3} px={3}>
                {filteredFlags.map((name) => (
                    <Stack alignItems="center" direction="row" gap={1} key={name}>
                        <Box
                            sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                background: (theme) => (name in overwrittenFlags ? theme.palette.yellow30 : 'none'),
                                px: 1,
                            }}
                            title={name}>
                            <Typography color={usedLookup[name] ? 'black' : 'grey70'}>{name}</Typography>
                        </Box>
                        <Stack direction="row" flex="1" gap={1}>
                            {(flagStats[name.toLowerCase()] ?? []).map((repoName) => (
                                <Chip key={repoName} label={repoName} />
                            ))}
                        </Stack>
                        {typeof computedFlags[name] !== 'boolean' ? (
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- FIXME
                            computedFlags[name].toString()
                        ) : (
                            <Switch
                                // eslint-disable-next-line no-implicit-coercion -- FIXME
                                checked={!!computedFlags[name]}
                                onChange={() => updateFlags((prev) => ({...prev, [name]: !computedFlags[name]}))}
                            />
                        )}
                    </Stack>
                ))}
            </Stack>
            <Stack
                direction="column"
                gap={1}
                p={3}
                sx={{
                    boxShadow: (theme) => theme.shadows[11],
                }}>
                <Stack direction="column" gap={1}>
                    <Button onClick={copySettings} startIcon={<CopyIcon />}>
                        <FormattedMessage defaultMessage="Copy settings" />
                    </Button>
                    <Button onClick={resetSettings} startIcon={<RefreshIcon />}>
                        <FormattedMessage defaultMessage="Clear overrides" />
                    </Button>
                </Stack>
                <TextField inputRef={inputRef} multiline placeholder="Paste JSON settings" rows={8} />
                <Stack direction="row" justifyContent="flex-end">
                    <Button onClick={clearInput} variant="text">
                        <FormattedMessage defaultMessage="Clear" />
                    </Button>
                    <Button onClick={submitChanges} variant="contained">
                        <FormattedMessage defaultMessage="Submit" />
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default UserFlagsPanel
