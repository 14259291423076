import {graphql} from 'react-relay/hooks'

export const permissionQuery = graphql`
    query PermissionAndNavProviderQuery($pk: Int) @relay_test_operation {
        meAdmin {
            ...Nav_data
            roles {
                assignedRoles {
                    role {
                        code
                    }
                }
                regionPermissions {
                    region {
                        pk
                    }
                    permissions {
                        code
                        level
                    }
                }
                locationPermissions {
                    location {
                        pk
                    }
                    permissions {
                        code
                        level
                    }
                }
                orgPermissions {
                    code
                    level
                }
            }
        }
        myOrganization {
            ...PermissionAndNavProvider_organizationAdmin
        }
        organizationByPk(pk: $pk) {
            pk
            name
            erpName
            locations {
                id
                pk
                name
            }
            regions {
                pk
            }
        }
    }
`

export const orgAdminFragment = graphql`
    fragment PermissionAndNavProvider_organizationAdmin on OrganizationAdmin {
        isPrincipal
        organization {
            pk
            name
            erpName
            locations {
                id
                pk
                name
            }
            regions {
                pk
            }
        }
        counterpartyRelationships(counterpartyType: [OWNED, MUTUAL]) {
            counterparty {
                id
                pk
                name
            }
        }
    }
`
