import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

const optionSharedStyles = ({theme}: {theme: Theme}) => ({
    minHeight: '40px',
    color: theme.palette.grey90,
    padding: theme.spacing(1.25, 1.5, 1.25, 1.5),
    '&.Mui-focused': {
        backgroundColor: theme.palette.grey30,
    },
})

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
    defaultProps: {
        disableClearable: true,
    },
    styleOverrides: {
        root: ({theme}) => ({
            '.MuiInputBase-adornedStart': {
                paddingLeft: '12px',
            },

            '& .MuiAutocomplete-endAdornment': {
                top: 'calc(50% - 14px)',

                svg: {
                    width: '24px',
                    height: '24px',
                },
            },

            '.MuiInputLabel-root.Mui-error': {
                color: theme.palette.red50,
                '.MuiFormLabel-asterisk': {
                    color: theme.palette.red50,
                },
            },

            '.MuiInputLabel-root': {
                color: theme.palette.grey70,

                '.MuiFormLabel-asterisk': {
                    color: theme.palette.red50,
                },

                '&.MuiInputLabel-shrink': {
                    letterSpacing: '0.05rem',
                    '&:not(.Mui-disabled)': {
                        color: theme.palette.grey70,
                    },

                    '.MuiFormLabel-asterisk': {
                        color: theme.palette.red70,
                    },
                },
            },

            '&.MultiSelect': {
                '& .MuiChip-deleteIcon': {
                    width: '20px',
                    height: '20px',
                    color: theme.palette.grey70,
                },
            },

            '&:hover, &.Mui-expanded': {
                '.MuiInputLabel-root:not(.Mui-disabled)': {
                    color: theme.palette.grey70,
                },
                '& .MuiOutlinedInput-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.grey90,
                },
            },

            '.MuiFormHelperText-root': {
                color: theme.palette.grey70,
                marginInline: theme.spacing(1.5),
                marginTop: theme.spacing(0.25),
                ...theme.typography.body2,

                '&.Mui-error': {
                    color: theme.palette.red70,
                },
            },
        }),
        inputRoot: ({theme}) => ({
            padding: 0,
            gap: theme.spacing(1),

            '&.MuiInputBase-sizeSmall': {
                padding: 0,
            },

            '.MuiOutlinedInput-notchedOutline': {
                padding: '0 8px 0 6px',
                letterSpacing: '.05rem',
                borderColor: theme.palette.grey40,
            },

            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.red50,
            },

            '&.Mui-disabled + .MuiFormHelperText-root': {
                color: theme.palette.grey50,
            },

            '.MuiChip-root': {
                color: theme.palette.grey90,

                '.MuiChip-deleteIcon': {
                    fill: theme.palette.grey70,
                },
            },
        }),
        input: ({theme}) => ({
            color: theme.palette.grey90,

            padding: '0 8px 0 7px',
            '&:first-of-type': {
                padding: '10px 8px 10px 12px',
            },

            '&.MuiInputBase-inputSizeSmall:first-of-type': {
                padding: '7px 12px',
            },

            '&.Mui-disabled': {
                color: theme.palette.grey50,
                WebkitTextFillColor: 'unset',
            },
        }),
        clearIndicator: () => ({
            padding: '2px',

            svg: {
                width: '21px !important',
                height: '21px !important',
            },
        }),
        popupIndicator: ({theme}) => ({
            color: theme.palette.grey70,

            '&.Mui-disabled': {
                color: theme.palette.grey50,
            },
        }),
        listbox: ({theme}) => ({
            padding: 'unset',
            maxHeight: '280px',
            '.MuiAutocomplete-option': {
                ...optionSharedStyles({theme}),
            },
        }),
        groupLabel: ({theme}) => ({
            color: theme.palette.grey90,
            padding: theme.spacing(1.25, 1.5, 1.25, 1.5),
            ...theme.typography.body1,
            fontWeight: 'bold',
            fontStyle: 'normal',
        }),
        groupUl: (theme) => ({
            padding: 0,
            '.MuiAutocomplete-option': {
                ...optionSharedStyles(theme),
                paddingInlineStart: theme.theme.spacing(3),
            },
        }),
        paper: ({theme}) => ({
            marginTop: theme.spacing(0.5),
            boxShadow: theme.shadows[12],
        }),
    } as ComponentsOverrides<Theme>['MuiAutocomplete'],
}

export default MuiAutocomplete
