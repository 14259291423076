/**
 * @generated SignedSource<<cd035cdc47ccf5ff78b46eade64e8346>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermissionCode = "ERP_ACTION" | "INTEGRATION_PIPELINE_JOBS" | "INTEGRATION_PIPELINE_TRANSLATIONS" | "INVENTORY_ACCOUNTS" | "INVENTORY_ACTIVITY" | "INVENTORY_RELEASE" | "INVENTORY_TRANSFER" | "MATERIAL_SPECIFICATION" | "MONTHLY_POSITION" | "ORDER_INTEGRATION" | "ORG_ADMIN_CHANGELOG" | "ORG_ADMIN_COUNTERPARTIES" | "ORG_ADMIN_COUNTERPARTY_INTEGRATION" | "ORG_ADMIN_LOCATION_DEFAULTS" | "ORG_ADMIN_LOCATION_DELIVERY" | "ORG_ADMIN_LOCATION_INTEGRATION" | "ORG_ADMIN_LOCATION_STRUCTURE" | "ORG_ADMIN_MATERIAL_INTEGRATION" | "ORG_ADMIN_PROFILE" | "ORG_ADMIN_REGION_DEFAULTS" | "ORG_ADMIN_USERS" | "ORG_ADMIN_USER_INTEGRATION" | "PURCHASE_ORDER" | "RECEIVING_APPOINTMENT" | "RECEIVING_DELETE" | "RECEIVING_FINALIZE" | "RECEIVING_INITIATE" | "STOCK_N_FLOW" | "TRADE_CONFIRM" | "TRADE_DECLARATIONS" | "TRADE_DELIVERY" | "TRADE_DRAFT" | "TRADE_INSIGHTS" | "TRADE_INSIGHTS_ARRIVAL_DATE_VS_QUOTA" | "TRADE_INSIGHTS_DELIVERY_CADENCE" | "TRADE_INSIGHTS_MATERIAL_BREAKDOWN" | "TRADE_INSIGHTS_OTIF" | "TRADE_INSIGHTS_QUOTA_FULFILLMENT" | "TRADE_INSIGHTS_TRANSIT_TIME" | "TRADE_INTEGRATION" | "TRADE_PRICING" | "TRADE_SCHEDULE";
export type PermissionAndNavProviderQuery$variables = {
  pk?: number | null;
};
export type PermissionAndNavProviderQuery$data = {
  readonly meAdmin: {
    readonly roles: {
      readonly assignedRoles: ReadonlyArray<{
        readonly role: {
          readonly code: string;
        };
      }> | null;
      readonly locationPermissions: ReadonlyArray<{
        readonly location: {
          readonly pk: number;
        } | null;
        readonly permissions: ReadonlyArray<{
          readonly code: PermissionCode | null;
          readonly level: number | null;
        }> | null;
      }> | null;
      readonly orgPermissions: ReadonlyArray<{
        readonly code: PermissionCode | null;
        readonly level: number | null;
      }> | null;
      readonly regionPermissions: ReadonlyArray<{
        readonly permissions: ReadonlyArray<{
          readonly code: PermissionCode | null;
          readonly level: number | null;
        }> | null;
        readonly region: {
          readonly pk: number;
        } | null;
      }> | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"Nav_data">;
  } | null;
  readonly myOrganization: {
    readonly " $fragmentSpreads": FragmentRefs<"PermissionAndNavProvider_organizationAdmin">;
  } | null;
  readonly organizationByPk: {
    readonly erpName: string | null;
    readonly locations: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly pk: number;
    }>;
    readonly name: string;
    readonly pk: number;
    readonly regions: ReadonlyArray<{
      readonly pk: number;
    }>;
  } | null;
};
export type PermissionAndNavProviderQuery = {
  response: PermissionAndNavProviderQuery$data;
  variables: PermissionAndNavProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pk"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "level",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPermission",
  "kind": "LinkedField",
  "name": "permissions",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPermission",
  "kind": "LinkedField",
  "name": "orgPermissions",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "pk"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "erpName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v10/*: any*/),
  (v2/*: any*/),
  (v8/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "locations",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = [
  (v2/*: any*/),
  (v10/*: any*/)
],
v14 = [
  (v2/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v12/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Region",
    "kind": "LinkedField",
    "name": "regions",
    "plural": true,
    "selections": (v13/*: any*/),
    "storageKey": null
  },
  (v10/*: any*/)
],
v15 = {
  "enumValues": null,
  "nullable": false,
  "plural": false,
  "type": "ID"
},
v16 = {
  "enumValues": null,
  "nullable": false,
  "plural": false,
  "type": "Boolean"
},
v17 = {
  "enumValues": null,
  "nullable": false,
  "plural": false,
  "type": "String"
},
v18 = {
  "enumValues": null,
  "nullable": false,
  "plural": false,
  "type": "Int"
},
v19 = {
  "enumValues": null,
  "nullable": true,
  "plural": true,
  "type": "UserPermission"
},
v20 = {
  "enumValues": [
    "ORG_ADMIN_USERS",
    "ORG_ADMIN_PROFILE",
    "ORG_ADMIN_REGION_DEFAULTS",
    "ORG_ADMIN_LOCATION_STRUCTURE",
    "ORG_ADMIN_LOCATION_DELIVERY",
    "ORG_ADMIN_LOCATION_DEFAULTS",
    "ORG_ADMIN_COUNTERPARTIES",
    "ORG_ADMIN_CHANGELOG",
    "TRADE_DRAFT",
    "TRADE_CONFIRM",
    "TRADE_DELIVERY",
    "TRADE_DECLARATIONS",
    "TRADE_SCHEDULE",
    "TRADE_PRICING",
    "MATERIAL_SPECIFICATION",
    "INVENTORY_ACTIVITY",
    "INVENTORY_ACCOUNTS",
    "INVENTORY_RELEASE",
    "INVENTORY_TRANSFER",
    "ERP_ACTION",
    "RECEIVING_INITIATE",
    "RECEIVING_DELETE",
    "RECEIVING_FINALIZE",
    "RECEIVING_APPOINTMENT",
    "ORG_ADMIN_USER_INTEGRATION",
    "ORG_ADMIN_LOCATION_INTEGRATION",
    "ORG_ADMIN_MATERIAL_INTEGRATION",
    "ORG_ADMIN_COUNTERPARTY_INTEGRATION",
    "ORDER_INTEGRATION",
    "TRADE_INTEGRATION",
    "INTEGRATION_PIPELINE_JOBS",
    "INTEGRATION_PIPELINE_TRANSLATIONS",
    "STOCK_N_FLOW",
    "MONTHLY_POSITION",
    "PURCHASE_ORDER",
    "TRADE_INSIGHTS",
    "TRADE_INSIGHTS_QUOTA_FULFILLMENT",
    "TRADE_INSIGHTS_ARRIVAL_DATE_VS_QUOTA",
    "TRADE_INSIGHTS_TRANSIT_TIME",
    "TRADE_INSIGHTS_MATERIAL_BREAKDOWN",
    "TRADE_INSIGHTS_DELIVERY_CADENCE",
    "TRADE_INSIGHTS_OTIF"
  ],
  "nullable": true,
  "plural": false,
  "type": "PermissionCode"
},
v21 = {
  "enumValues": null,
  "nullable": true,
  "plural": false,
  "type": "Int"
},
v22 = {
  "enumValues": null,
  "nullable": false,
  "plural": false,
  "type": "Organization"
},
v23 = {
  "enumValues": null,
  "nullable": true,
  "plural": false,
  "type": "String"
},
v24 = {
  "enumValues": null,
  "nullable": true,
  "plural": false,
  "type": "Organization"
},
v25 = {
  "enumValues": null,
  "nullable": false,
  "plural": true,
  "type": "Location"
},
v26 = {
  "enumValues": null,
  "nullable": false,
  "plural": true,
  "type": "Region"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PermissionAndNavProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAdmin",
        "kind": "LinkedField",
        "name": "meAdmin",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Nav_data"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRoles",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserRoleItem",
                "kind": "LinkedField",
                "name": "assignedRoles",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserRegionPermissions",
                "kind": "LinkedField",
                "name": "regionPermissions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "region",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserLocationPermissions",
                "kind": "LinkedField",
                "name": "locationPermissions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "location",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationAdmin",
        "kind": "LinkedField",
        "name": "myOrganization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionAndNavProvider_organizationAdmin"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByPk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "regions",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PermissionAndNavProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAdmin",
        "kind": "LinkedField",
        "name": "meAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperuser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isStaff",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "photo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v2/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isInternal",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRoles",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserRoleItem",
                "kind": "LinkedField",
                "name": "assignedRoles",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserRegionPermissions",
                "kind": "LinkedField",
                "name": "regionPermissions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "region",
                    "plural": false,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserLocationPermissions",
                "kind": "LinkedField",
                "name": "locationPermissions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "location",
                    "plural": false,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationAdmin",
        "kind": "LinkedField",
        "name": "myOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPrincipal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "counterpartyType",
                "value": [
                  "OWNED",
                  "MUTUAL"
                ]
              }
            ],
            "concreteType": "CounterpartyRelationship",
            "kind": "LinkedField",
            "name": "counterpartyRelationships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "counterparty",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": "counterpartyRelationships(counterpartyType:[\"OWNED\",\"MUTUAL\"])"
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByPk",
        "plural": false,
        "selections": (v14/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab7a61735ecc931b07dcdaa77c4cf760",
    "id": null,
    "metadata": {
      "relayTestingSelectionTypeInfo": {
        "meAdmin": {
          "enumValues": null,
          "nullable": true,
          "plural": false,
          "type": "UserAdmin"
        },
        "meAdmin.id": (v15/*: any*/),
        "meAdmin.isStaff": (v16/*: any*/),
        "meAdmin.isSuperuser": (v16/*: any*/),
        "meAdmin.roles": {
          "enumValues": null,
          "nullable": false,
          "plural": false,
          "type": "UserRoles"
        },
        "meAdmin.roles.assignedRoles": {
          "enumValues": null,
          "nullable": true,
          "plural": true,
          "type": "UserRoleItem"
        },
        "meAdmin.roles.assignedRoles.role": {
          "enumValues": null,
          "nullable": false,
          "plural": false,
          "type": "Role"
        },
        "meAdmin.roles.assignedRoles.role.code": (v17/*: any*/),
        "meAdmin.roles.assignedRoles.role.id": (v15/*: any*/),
        "meAdmin.roles.locationPermissions": {
          "enumValues": null,
          "nullable": true,
          "plural": true,
          "type": "UserLocationPermissions"
        },
        "meAdmin.roles.locationPermissions.location": {
          "enumValues": null,
          "nullable": true,
          "plural": false,
          "type": "Location"
        },
        "meAdmin.roles.locationPermissions.location.id": (v15/*: any*/),
        "meAdmin.roles.locationPermissions.location.pk": (v18/*: any*/),
        "meAdmin.roles.locationPermissions.permissions": (v19/*: any*/),
        "meAdmin.roles.locationPermissions.permissions.code": (v20/*: any*/),
        "meAdmin.roles.locationPermissions.permissions.level": (v21/*: any*/),
        "meAdmin.roles.orgPermissions": (v19/*: any*/),
        "meAdmin.roles.orgPermissions.code": (v20/*: any*/),
        "meAdmin.roles.orgPermissions.level": (v21/*: any*/),
        "meAdmin.roles.regionPermissions": {
          "enumValues": null,
          "nullable": true,
          "plural": true,
          "type": "UserRegionPermissions"
        },
        "meAdmin.roles.regionPermissions.permissions": (v19/*: any*/),
        "meAdmin.roles.regionPermissions.permissions.code": (v20/*: any*/),
        "meAdmin.roles.regionPermissions.permissions.level": (v21/*: any*/),
        "meAdmin.roles.regionPermissions.region": {
          "enumValues": null,
          "nullable": true,
          "plural": false,
          "type": "Region"
        },
        "meAdmin.roles.regionPermissions.region.id": (v15/*: any*/),
        "meAdmin.roles.regionPermissions.region.pk": (v18/*: any*/),
        "meAdmin.user": {
          "enumValues": null,
          "nullable": false,
          "plural": false,
          "type": "User"
        },
        "meAdmin.user.email": (v17/*: any*/),
        "meAdmin.user.firstName": (v17/*: any*/),
        "meAdmin.user.fullName": (v17/*: any*/),
        "meAdmin.user.id": (v15/*: any*/),
        "meAdmin.user.lastName": (v17/*: any*/),
        "meAdmin.user.organization": (v22/*: any*/),
        "meAdmin.user.organization.id": (v15/*: any*/),
        "meAdmin.user.organization.isInternal": (v16/*: any*/),
        "meAdmin.user.organization.name": (v17/*: any*/),
        "meAdmin.user.organization.pk": (v18/*: any*/),
        "meAdmin.user.photo": (v23/*: any*/),
        "meAdmin.user.pk": (v18/*: any*/),
        "myOrganization": {
          "enumValues": null,
          "nullable": true,
          "plural": false,
          "type": "OrganizationAdmin"
        },
        "myOrganization.counterpartyRelationships": {
          "enumValues": null,
          "nullable": false,
          "plural": true,
          "type": "CounterpartyRelationship"
        },
        "myOrganization.counterpartyRelationships.counterparty": (v24/*: any*/),
        "myOrganization.counterpartyRelationships.counterparty.id": (v15/*: any*/),
        "myOrganization.counterpartyRelationships.counterparty.name": (v17/*: any*/),
        "myOrganization.counterpartyRelationships.counterparty.pk": (v18/*: any*/),
        "myOrganization.id": (v15/*: any*/),
        "myOrganization.isPrincipal": (v16/*: any*/),
        "myOrganization.organization": (v22/*: any*/),
        "myOrganization.organization.erpName": (v23/*: any*/),
        "myOrganization.organization.id": (v15/*: any*/),
        "myOrganization.organization.locations": (v25/*: any*/),
        "myOrganization.organization.locations.id": (v15/*: any*/),
        "myOrganization.organization.locations.name": (v17/*: any*/),
        "myOrganization.organization.locations.pk": (v18/*: any*/),
        "myOrganization.organization.name": (v17/*: any*/),
        "myOrganization.organization.pk": (v18/*: any*/),
        "myOrganization.organization.regions": (v26/*: any*/),
        "myOrganization.organization.regions.id": (v15/*: any*/),
        "myOrganization.organization.regions.pk": (v18/*: any*/),
        "organizationByPk": (v24/*: any*/),
        "organizationByPk.erpName": (v23/*: any*/),
        "organizationByPk.id": (v15/*: any*/),
        "organizationByPk.locations": (v25/*: any*/),
        "organizationByPk.locations.id": (v15/*: any*/),
        "organizationByPk.locations.name": (v17/*: any*/),
        "organizationByPk.locations.pk": (v18/*: any*/),
        "organizationByPk.name": (v17/*: any*/),
        "organizationByPk.pk": (v18/*: any*/),
        "organizationByPk.regions": (v26/*: any*/),
        "organizationByPk.regions.id": (v15/*: any*/),
        "organizationByPk.regions.pk": (v18/*: any*/)
      }
    },
    "name": "PermissionAndNavProviderQuery",
    "operationKind": "query",
    "text": "query PermissionAndNavProviderQuery(\n  $pk: Int\n) {\n  meAdmin {\n    ...Nav_data\n    roles {\n      assignedRoles {\n        role {\n          code\n          id\n        }\n      }\n      regionPermissions {\n        region {\n          pk\n          id\n        }\n        permissions {\n          code\n          level\n        }\n      }\n      locationPermissions {\n        location {\n          pk\n          id\n        }\n        permissions {\n          code\n          level\n        }\n      }\n      orgPermissions {\n        code\n        level\n      }\n    }\n    id\n  }\n  myOrganization {\n    ...PermissionAndNavProvider_organizationAdmin\n    id\n  }\n  organizationByPk(pk: $pk) {\n    pk\n    name\n    erpName\n    locations {\n      id\n      pk\n      name\n    }\n    regions {\n      pk\n      id\n    }\n    id\n  }\n}\n\nfragment Nav_data on UserAdmin {\n  isSuperuser\n  isStaff\n  user {\n    id\n    pk\n    firstName\n    lastName\n    fullName\n    email\n    photo\n    ...UserAvatar_user\n    organization {\n      id\n      pk\n      name\n      isInternal\n    }\n  }\n}\n\nfragment PermissionAndNavProvider_organizationAdmin on OrganizationAdmin {\n  isPrincipal\n  organization {\n    pk\n    name\n    erpName\n    locations {\n      id\n      pk\n      name\n    }\n    regions {\n      pk\n      id\n    }\n    id\n  }\n  counterpartyRelationships(counterpartyType: [OWNED, MUTUAL]) {\n    counterparty {\n      id\n      pk\n      name\n    }\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  firstName\n  lastName\n  photo\n}\n"
  }
};
})();

(node as any).hash = "e3c526a9d9c0c8d284248e28030d7a99";

export default node;
