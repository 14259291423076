/**
 * @generated SignedSource<<19d379c427a33d712c5ffb8d3315e79e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermissionAndNavProvider_organizationAdmin$data = {
  readonly counterpartyRelationships: ReadonlyArray<{
    readonly counterparty: {
      readonly id: string;
      readonly name: string;
      readonly pk: number;
    } | null;
  }>;
  readonly isPrincipal: boolean;
  readonly organization: {
    readonly erpName: string | null;
    readonly locations: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly pk: number;
    }>;
    readonly name: string;
    readonly pk: number;
    readonly regions: ReadonlyArray<{
      readonly pk: number;
    }>;
  };
  readonly " $fragmentType": "PermissionAndNavProvider_organizationAdmin";
};
export type PermissionAndNavProvider_organizationAdmin$key = {
  readonly " $data"?: PermissionAndNavProvider_organizationAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"PermissionAndNavProvider_organizationAdmin">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PermissionAndNavProvider_organizationAdmin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPrincipal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "erpName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "locations",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "regions",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "counterpartyType",
          "value": [
            "OWNED",
            "MUTUAL"
          ]
        }
      ],
      "concreteType": "CounterpartyRelationship",
      "kind": "LinkedField",
      "name": "counterpartyRelationships",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "counterparty",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "counterpartyRelationships(counterpartyType:[\"OWNED\",\"MUTUAL\"])"
    }
  ],
  "type": "OrganizationAdmin",
  "abstractKey": null
};
})();

(node as any).hash = "07f133e2fcc619b626d276b270db1709";

export default node;
