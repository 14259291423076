import {useEffect, useMemo} from 'react'
import {isNil} from 'lodash-es'
import {Helmet} from 'react-helmet'

import {useTrackPageView} from '@/analytics/main'

/**
 * Adds a - Minehub to the end of a head title for consistency across screens
 */
export const getHeadTitle = (platformName: string, title?: string): string =>
    isNil(title) || title === '' ? platformName : `${title} - ${platformName}`

/**
 * Sets the current document / tab title
 *
 * Wrapper for `react-helmet`.
 */
const Title = ({
    title,
    onTitleUpdated,
}: {
    /** sets the website head title */
    title?: string
    onTitleUpdated?: (title: string) => void
}) => {
    useTrackPageView(Boolean(title))
    const memoTitle = useMemo(() => getHeadTitle('MineHub', title), [title])
    useEffect(() => onTitleUpdated?.(memoTitle))
    return (
        <Helmet>
            <title>{memoTitle}</title>
        </Helmet>
    )
}
export default Title
