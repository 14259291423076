import {useEffect, useRef} from 'react'
import {startCase} from 'lodash-es'
import mixpanel from 'mixpanel-browser'
import posthog from 'posthog-js'

import configValue from '@/utils/config'

const mixpanelToken = configValue('MIXPANEL_TOKEN')

const environment = configValue('ENVIRONMENT')

const posthogApiKey = configValue('PH_API_KEY')
export function initializeEventAnalytics() {
    if (!mixpanelToken) {
        console.warn('Analitics token not found')
        return
    }

    mixpanel.init(mixpanelToken, {
        debug: Boolean(environment) && ['dev', 'local'].includes(environment as string),
        persistence: 'localStorage',
        autocapture: false,
        record_sessions_percent: 100,
        record_mask_text_selector: '',
    } as any)
}

const simplifiedPageTitle = (pathname: string, platformName: string) =>
    // eslint-disable-next-line require-unicode-regexp, prefer-named-capture-group -- FIXME
    startCase(pathname.replace(/[0-9]/g, '').replace(/(\/+)/g, '/').replace(/\//g, ' ').trim()) || platformName

export function useTrackPageView(active: boolean) {
    const firstRender = useRef(true)
    useEffect(() => {
        if (!active || !firstRender.current) {
            return
        }
        firstRender.current = false
        if (mixpanelToken) {
            mixpanel.track_pageview()
        }
        if (posthogApiKey) {
            posthog.capture('$pageview', {
                $set_once: {
                    pagePath: location.pathname + location.search,
                    pageLocation: window.location.href,
                    pageTitle: simplifiedPageTitle(location.pathname, 'MineHub'),
                },
            })
        }
    }, [active])
}

function recursiveObjectKeysToStartCase<T extends Record<string, any>>(obj: T): T {
    return Object.keys(obj).reduce(
        (prev, key) => ({
            ...prev,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            [startCase(key)]:
                !obj[key] || Array.isArray(obj[key]) || typeof obj[key] !== 'object'
                    ? obj[key]
                    : recursiveObjectKeysToStartCase(obj[key]),
        }),
        {} as T,
    )
}
/**
 * This method should not be used directly outside the code in the src/analytics dir,
 * instead a object should be created on src/analytics and
 * that object should be used instead on components
 */
export function analyticsTriggerEvent(event: string, properties?: Record<string, any>) {
    if (mixpanelToken) {
        mixpanel.track(event, properties ? recursiveObjectKeysToStartCase(properties) : undefined)
    }

    if (posthogApiKey) {
        posthog.capture(event, properties)
    }
}

export function resetAnalytics() {
    if (mixpanelToken) {
        mixpanel.reset()
    }
    if (posthogApiKey) {
        posthog.reset()
    }
}

export function applyFlags(flags: {[index: string]: boolean}) {
    if (mixpanelToken) {
        mixpanel.register({
            flags,
        })
    }
    if (posthogApiKey) {
        posthog.register({
            flags,
        })
    }
}

export function assignActiveUser(user: {
    pk: number
    fullName: string
    email: string
    organizationName: string
    orgId: string
    counterparties: string[]
    roles: string[]
}) {
    if (mixpanelToken) {
        mixpanel.identify(`${user.pk}`)
        mixpanel.people.set({
            $name: user.fullName,
            $email: user.email,
            pk: user.pk,
            $organizationName: user.organizationName,
            $organizationId: user.orgId,
            $counterparties: user.counterparties || [],
            $roles: user.roles || [],
        })
    }
    if (posthogApiKey) {
        posthog.identify(`${user.pk}`, {
            name: user.fullName,
            email: user.email,
            pk: user.pk,
            orgName: user.organizationName,
            organization: user.orgId,
        })
    }
}
