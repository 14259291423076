import * as paths from './paths'

/**
 * Quick + basic way to customise the index path based on user roles, to avoid issues
 * where the user gets directed to a page they don't have access to. Should be restructured when
 * we implement contextual nav.
 */
export const getTopPagePath = (roleCodes: Set<string>, isPrincipal: boolean) => {
    const defaultPath = paths.getTradeManagerPath()
    const adminPath = paths.getOrgSettingsPath()

    if (isPrincipal) {
        if (roleCodes.has('APPROVER') || roleCodes.has('BUYER')) {
            return defaultPath
        }

        if (roleCodes.has('PLANT_MANAGER') || roleCodes.has('PLANT_WORKER')) {
            return paths.shipmentsPath
        }
    }

    if (roleCodes.has('WAYBRIDGE_ADMIN')) {
        return adminPath
    }

    // trades is always a good page to navigate to
    return defaultPath
}
